import { config } from '../config/firebase';
import axios from 'axios';
import firebase from 'firebase';
import { isUndefined } from 'lodash';

import quidolSocket from '../utils/socketio';

import handleQuestionAnswerTV from '../utils/handleQuestionAnswerTV';

export const BROADCAST = 'BROADCAST';
export const CURRENT_QUESTION = 'CURRENT_QUESTION';
export const CURRENT_QUESTION_ID = 'CURRENT_QUESTION_ID';
export const CURRENT_QUESTION_PROCESSED = 'CURRENT_QUESTION_PROCESSED';
export const CURRENT_QUIZ = 'CURRENT_QUIZ';
export const DISPLAY_CHUNKTIME = 'DISPLAY_CHUNKTIME';
export const DISPLAY_URL = 'DISPLAY_CHUNKTIME';
export const DISPLAY_CURRENT_QUESTION = 'DISPLAY_CURRENT_QUESTION';
export const LOGS_CHUNKTIME = 'LOGS_CHUNKTIME';
export const NB_PLAYERS = 'NB_PLAYERS';
export const NB_VIEWERS = 'NB_VIEWERS';
export const RANDOM_VIEWER = 'RANDOM_USER';
export const PENDING = 'PENDING';
export const QUESTION_ENDED = 'QUESTION_ENDED';
export const QUESTION_FETCHING = 'QUESTION_FETCHING';
export const QUESTION_STARTED = 'QUESTION_STARTED';
export const UPDATE_TIME = 'UPDATE_TIME';
export const GET_RANDOM_VIEWER = 'GET_RANDOM_VIEWER';

quidolSocket.connect();

const updateTime = time => ({
  type: UPDATE_TIME,
  payload: time,
});

export const pending = bool => ({
  type: PENDING,
  payload: bool,
});

export const broadcasting = bool => {
  return {
    type: BROADCAST,
    payload: bool,
  };
};

export const nbViewers = int => ({
  type: NB_VIEWERS,
  payload: int,
});

export const nbPlayers = int => ({
  type: NB_PLAYERS,
  payload: int,
});

export const questionStarted = bool => ({
  type: QUESTION_STARTED,
  payload: bool,
});

export const questionEnded = bool => ({
  type: QUESTION_ENDED,
  payload: bool,
});

export const questionFetching = bool => ({
  type: QUESTION_FETCHING,
  payload: bool,
});

export const currentQuestion = nbQuestion => ({
  type: CURRENT_QUESTION,
  payload: nbQuestion,
});

export const currentQuestionId = question => ({
  type: CURRENT_QUESTION_ID,
  payload: question,
});

export const displayCurrentQuestion = question => ({
  type: DISPLAY_CURRENT_QUESTION,
  payload: question,
});

export const questionWithData = question => ({
  type: CURRENT_QUESTION_PROCESSED,
  payload: question,
});

export const currentQuiz = quiz => ({
  type: CURRENT_QUIZ,
  payload: quiz,
});

export const currentChunkTime = chunkTime => ({
  type: DISPLAY_CHUNKTIME,
  payload: chunkTime,
});

export const logsChunkTime = chunkTimeObject => ({
  type: LOGS_CHUNKTIME,
  payload: chunkTimeObject,
});

export const fetchRandomViewer = array => ({
  type: GET_RANDOM_VIEWER,
  payload: array,
});

export const startLive = () => async (dispatch, getState) => {
  try {
    const { versionType } = getState().controls.currentQuiz;
    let collectionType = 'live';
    if (versionType === 'liveV2')
      collectionType = 'liveV2'
    await quidolSocket.startLive(collectionType);
    dispatch(broadcasting(true));
  } catch (e) {
    console.error(e);
  }
  dispatch(pending(false));
};

export const stopLive = () => async dispatch => {
  dispatch(pending(true));
  try {
    dispatch(broadcasting(false));
    await quidolSocket.stopLive();
  } catch (e) {
    console.error(e);
  }
  dispatch(pending(false));
};

const launchQuizUrlId = async (quizId) => {
  try {
    const listQuizNoEnded = await quidolSocket.getNextQuizzes();
    const myQuizArray = listQuizNoEnded.data.filter(quiz => quiz.id === quizId);
    const [myQuiz] = myQuizArray;
    let quiz;
    if (!isUndefined(myQuiz)) {
      quiz = { ...myQuiz, isBroadcasting: false, id: myQuiz.id };
      return quiz;
    } else {
      console.log("Error:\tQuiz Not Found");
    }
  } catch (e) {
    console.error(e);
  }
};

export const watchQuiz = (quizId) => async (dispatch, getState) => {
  try {
    let currentQuizzes;
    let nextQuizzes;
    let currentQuidolLive;

    if (!quizId) {
      currentQuizzes = await quidolSocket.getCurrentQuizzes();
      nextQuizzes = await quidolSocket.getNextQuizzes();
      currentQuidolLive = currentQuizzes.data.filter((quiz) => (!quiz.versionType && quiz.type !== 'battle'))[0];
    }
    let quiz;
    console.log('[WatchQuiz] quizId: ', quizId)
    if (!isUndefined(quizId)) {
      quiz = await launchQuizUrlId(quizId);
    } else if (currentQuidolLive)
      quiz = { ...currentQuidolLive, isBroadcasting: true, id: currentQuidolLive.id };
    else if (nextQuizzes && nextQuizzes.data && nextQuizzes.data.length)
      quiz = { ...nextQuizzes.data[0], id: nextQuizzes.data[0].id, isBroadcasting: false };
    if (quiz) {
      quidolSocket.joinQuiz(quiz.id).catch((err) => { console.error(`ERROR: Crash JoinQuiz, ${err.stack}`) });
      await dispatch(currentQuiz(quiz));
      console.log('QUIZ', quiz);
      await dispatch(playQuiz(quiz));
    }

    quidolSocket.onLiveStarted(async ({ code, data }) => {
      if (code === 200 && (data.id === quizId || isUndefined(quizId))) {
        if (!data.versionType) {
          quidolSocket.joinQuiz(data.id).catch((err) => { console.error(`ERROR: Crash JoinQuiz, ${err.stack}`) });
          await dispatch(currentQuiz({ ...data, isBroadcasting: true, id: data.id }));
          await dispatch(playQuiz({ ...data, isBroadcasting: true, id: data.id }));
        }
      }
    });
  } catch (e) {
    console.error(e);
  }
};

export const playQuiz = quiz => async (dispatch, getState) => {
  try {
    console.log('[PlayQuiz] quiz: ', quiz.id);
    if (quiz.isBroadcasting) dispatch(broadcasting(quiz.isBroadcasting));
    quidolSocket.quizId = quiz.id;
    quidolSocket.onLiveEnded(({ code, data }) => {
      console.log('onLiveEnded data:', data);
      if (code === 200) {
        console.log('ENDDD');
        //remove listeners
      }
    });
    const nbPlayersViewers = await quidolSocket.getPlayersViewers();
    dispatch(nbViewers(nbPlayersViewers.nbViewers || '0'));
    dispatch(nbPlayers(nbPlayersViewers.nbPlayers || '0'));
    if (quiz.isBroadcasting)
      quidolSocket.onPlayersViewers(({ code, data }) => {
        if (code === 200) {
          dispatch(nbViewers(data.nbViewers || '0'));
          dispatch(nbPlayers(data.nbPlayers || '0'));
        }
      });

    await dispatch(getCurrentQuestion());
    if (quiz.isBroadcasting)
      quidolSocket.onShowQuestionExecuted(({ code, data }) => {
        console.log('onShowQuestionExecuted data:', code, data);
        if (code === 200) {
          dispatch(displayCurrentQuestion(data));
          dispatch(questionStarted(true));
          setTimeout(() => {
            dispatch(questionEnded(true));
            dispatch(questionStarted(false));
            dispatch(questionFetching(false));
            dispatch(displayCurrentQuestion({ ...getState().controls.currentQuestion, hidden: true }));
            dispatch(questionProcessingEnd(data.id));
          }, data.duration + 3000);
        }
      });
    quidolSocket.onShowAnswerExecuted(({ code, data }) => {
      console.log('onShowAnswerExecuted data:', code, data);
    });
  } catch (e) {
    console.error(e);
  }
};

export const endLive = () => async dispatch => {
  dispatch(pending(true));
  dispatch(broadcasting(false));
  try {
    await quidolSocket.endLive();
    dispatch(pending(false));
    dispatch(questionEnded(false));
    dispatch(questionStarted(false));
    dispatch(questionFetching(false));
  } catch (err) {
    console.log(err);
    dispatch(questionEnded(false));
    dispatch(questionStarted(false));
    dispatch(questionFetching(false));
    dispatch(pending(false));
  }
};

export const getRandomViewer = () => async dispatch => {
  dispatch(pending(true));
  try {
    const { data } = await quidolSocket.getRandomViewer();
    dispatch(randomViewer(data));
  } catch (e) {
    console.error(e);
  }
  dispatch(pending(false));
};

export const randomViewer = (user) => ({
  type: RANDOM_VIEWER,
  payload: user,
});

export const resetQuiz = () => async dispatch => {
  const r = !config.dev ? window.confirm('Voulez vous vraiment reset le quiz ?') : true;
  if (r === true) {
    dispatch(pending(true));
    // TODO: a degager
    dispatch(broadcasting(false));
    try {
      const { code } = await quidolSocket.resetQuiz(quidolSocket.quizId);
      if (code === 200) {
        dispatch(pending(false));
        dispatch(questionEnded(false));
        dispatch(questionStarted(false));
        dispatch(questionFetching(false));
        document.location.reload(true);
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export const displayQuestion = (questionId, videoChunkTime, duration) => async dispatch => {
  dispatch(pending(true));
  try {
    await quidolSocket.showQuestion({
      questionId,
      chunkTime: videoChunkTime.chunkTime,
      chunkSeenAt: videoChunkTime.chunkSeenAt,
    });
    dispatch(questionStarted(true));
  } catch (e) {
    console.error(e);
  }
  dispatch(pending(false));
};

let _questionInterval;
export const getCurrentQuestion = () => async (dispatch, getState) => {
  let nextQuestion;
  console.log('[GetCurrentQuestion]');
  try {
    const response = await quidolSocket.getCurrentQuestion({ skipIfAnswerIsDisplayed: true });
    if (response.code === 200 && response.data) {
      nextQuestion = response.data;
    } else if (response.code === 200 && !response.data) {
      if (getState().controls.currentQuiz.questions)
        nextQuestion = getState().controls.currentQuiz.questions[0];
      else {
        const res = await axios({
          method: 'POST',
          url: `${config.cloudHttps}/httpsAdminControlSocket`,
          data: {
            action: 'getNextQuestion',
            quizId: quidolSocket.quizId,
          },
        });
        if (res.data) {
          nextQuestion = res.data;
        }
      }
    }
    const { currentQuestion } = getState().controls;
    const jsonDiff = require('jsondiffpatch').create();
    const delta = await jsonDiff.diff(nextQuestion, currentQuestion);
    if (!delta || !nextQuestion) return;
    dispatch(displayCurrentQuestion(nextQuestion));
    if (_questionInterval) clearInterval(_questionInterval);
    _questionInterval = setInterval(() => dispatch(getCurrentQuestion()), 2000);
  } catch (e) {
    console.error(e);
  }
  dispatch(pending(false));
};

export const showAnswer = (questionId, answerHideDelay) => async dispatch => {
  dispatch(pending(true));
  try {
    await quidolSocket.showAnswer({
      questionId,
    });
    dispatch(questionEnded());
    dispatch(getCurrentQuestion());
  } catch (e) {
    console.error(e);
  }
  dispatch(pending(false));
};

export const retrievePlayers = (questionId) => async dispatch => {
  const r = !config.dev ? window.confirm('Voulez-vous vraiment repêcher les joueurs qui ont perdus à la question précédente ?') : true;
  if (r === true) {
    dispatch(pending(true));
    try {
      await quidolSocket.retrievePlayers({
        questionId,
      });
    } catch (e) {
      console.error(e);
    }
    dispatch(pending(false));
  }
};

export const rewardPlayers = (questionId) => async dispatch => {
  const r = !config.dev ? window.confirm('Voulez-vous vraiment rewarder les players qui ont gagnés à la question précédente ?') : true;
  if (r === true) {
    dispatch(pending(true));
    console.log('REWARDP');
    try {
      await quidolSocket.rewardPlayers({
        questionId,
      });
    } catch (e) {
      console.error(e);
    }
    dispatch(pending(false));
  }
};

export const questionProcessingEnd = questionId => dispatch => {
  firebase
    .database()
    .ref(`questions/${questionId}`)
    .off('value');
};

export const getTime = () => dispatch => {
  dispatch(pending(true));
  const fetchStart = Date.now();
  axios({
    method: 'GET',
    url: `${config.cloudHttps}/httpsGetTime`,
  })
    .then(res => {
      dispatch(pending(false));
      const result = `${res.data}`;
      const fetchTime = Date.now() - fetchStart;
      const newServerTime = new Date(result).getTime() + fetchTime;

      dispatch(
        updateTime({
          device: new Date(fetchStart).toISOString(),
          server: new Date(newServerTime).toISOString(),
          delta: newServerTime - fetchStart,
        })
      );
      return;
    })
    .catch(err => {
      console.log(err);

      dispatch(pending(false));
      return err;
    });
};

export const showAnswerTV = (args) => async () => {
  await handleQuestionAnswerTV.showAnswer(args);
}

export const showQuestionTV = (args) => async () => {
  await handleQuestionAnswerTV.showQuestion(args);
}
