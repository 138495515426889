import React from 'react';
import { connect } from 'react-redux';

import SurveyCreateModal from './surveyCreateModal';

import { MODAL_TYPE_SURVEY_CREATE } from '../../constants/ModalTypes';

const MODAL_COMPONENTS = {
  [MODAL_TYPE_SURVEY_CREATE]: SurveyCreateModal
};

const ModalRoot = ({ type, props }) => {
  if (!type) {
    return null;
  }

  const ModalComponent = MODAL_COMPONENTS[type];
  return <ModalComponent {...props} />;
};

export default connect(state => state.modal)(ModalRoot);
