import { Route, Switch } from 'react-router';
import React, { Component } from 'react';

import AnswerBurger from '../../components/answerBurger'

class Answer extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/home/answer" component={AnswerBurger} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default Answer;
