import HlsLib from 'hls.js';
import React from 'react';
import ReactPlayer from 'react-player';

import Utf8ArrayToStr from '../../utils/utf8ArrayToStr';

export default class VideoPlayer extends React.PureComponent {
  ref = player => {
    this.player = player;
  };

  playerStarted = () => {
    const { urlStream } = this.props;
    var hls = new HlsLib();
    var video = document.getElementsByTagName('video')[0];
    hls.loadSource(urlStream);
    hls.attachMedia(video);
    hls.on(HlsLib.Events.MANIFEST_PARSED, function() {
      video.play();
    });

    hls.on(HlsLib.Events.ERROR, function(ERROR) {
      console.log('ERROR', ERROR);
      console.log('ERROR', ERROR);
      console.log('ERROR', ERROR);
      console.log('ERROR', ERROR);
      console.log('ERROR', ERROR);
      console.log('ERROR', ERROR);
      console.log('ERROR', ERROR);
      //video.play();
    });

    hls.on(HlsLib.Events.BUFFER_RESET, function() {
      console.log('BUFFER_RESET');
      console.log('BUFFER_RESET');
      console.log('BUFFER_RESET');
      console.log('BUFFER_RESET');
      console.log('BUFFER_RESET');
      console.log('BUFFER_RESET');
      console.log('BUFFER_RESET');
      console.log('BUFFER_RESET');
      //video.play();
    });

    hls.on(HlsLib.Events.BUFFER_FLUSHED, function() {
      console.log('BUFFER_FLUSHED');
      console.log('BUFFER_FLUSHED');
      console.log('BUFFER_FLUSHED');
      console.log('BUFFER_FLUSHED');
      console.log('BUFFER_FLUSHED');
      console.log('BUFFER_FLUSHED');
      console.log('BUFFER_FLUSHED');
      console.log('BUFFER_FLUSHED');
      //video.play();
    });

    hls.on(HlsLib.Events.FRAG_PARSING_METADATA, (event, data) => {
      const id3 = data.samples && data.samples.length ? data.samples[0] : null;
      const id3Decoded = Utf8ArrayToStr(id3.data);
      const chunkTime = parseInt(id3Decoded.includes('CHUNK-TIME') ? id3Decoded.split(' ')[1] : null, 10);
      if (chunkTime) {
        this.props.actions.controls.currentChunkTime(chunkTime);
        this.props.actions.controls.logsChunkTime({
          date: new Date().toISOString(),
          type: 'get',
          chunkTime,
        });
      }
    });
  };

  render() {
    const { urlStream } = this.props;
    return (
      <React.Fragment>
        <ReactPlayer height="360px" id={'videoContainer'} onStart={() => this.playerStarted()} playing url={urlStream} width="640px" />
      </React.Fragment>
    );
  }
}
