import React from 'react';
import styled from 'styled-components';

import { Title } from '../texts';
import InfoCard from '../infoCard';
import LoadingScreen from '../loadingScreen';
import { round } from 'lodash';

const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 100%;
`;

const InfoCardPresenter = styled(InfoCard)`
  background-color: ${props => props.theme.color.infoCardPresenter};
  display: flex;
  height: 70px;
  justify-content: center;
`;

const PresenterCard = styled.div`
  background-color: ${props => (props.isRight ? props.theme.color.questionRight : props.theme.color.questionWrong)};
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 10px;
`;

const HeaderPresenterCard = styled.div`
  justify-content: center;
`;

const TextPresenterCard = styled(Title)`
  margin: 15px;
  text-align: center;
`;

const NumberAnswerContainer = styled.div`
  align-items: center;
  background-color: ${props => props.theme.color.PresenterNbAnswers};
  display: flex;
  heigth: 90px;
  justify-content: center;
  margin: 10px 0px;
  width: 100%;
`;

const AnswerImage = styled.img`
  max-height: 50px;
`;

const JokersContainer = styled.div``;

class PresenterContent extends React.PureComponent {
  playersDisplay = players => {
    return players.map(player => (
      <TextPresenterCard key={player} color="white">
        {player}
      </TextPresenterCard>
    ));
  };

  displayPercentage = (nbAnswers, totalAnswers) => {
    let percentage = 0;
    if (totalAnswers > 0) {
      percentage = round((nbAnswers * 100) / totalAnswers, 2);
    }
    return percentage ? `${percentage} %` : '0 %';
  };

  displayAnswers = (data, totalAnswers) => {
    const { type } = data;
    if (!data.answers) {
      return <LoadingScreen content="EN ATTENTE DE RÉPONSES" viewHeight={50} />;
    }
    return Object.keys(data.answers).map(answer => {
      const { content, id, isRight, nbAnswers } = data.answers[answer];
      const samplePlayersFr = data.samplePlayers && data.samplePlayers.answers ? data.samplePlayers.answers[id] || data.samplePlayers.answers[content.fr.toLowerCase()] || [] : [];
      return (
        <PresenterCard key={id} isRight={isRight}>
          <InfoCardPresenter title={id.toUpperCase()} titleFontSize={40} />
          <HeaderPresenterCard>
            <TextPresenterCard color="white">{(type === 'image') ? <AnswerImage src={content.fr} alt={content.fr}/> : content.fr}</TextPresenterCard>
            <TextPresenterCard color="white">{(type === 'image') ? <AnswerImage src={content.dz} alt={content.dz}/> : content.dz}</TextPresenterCard>
            <NumberAnswerContainer>
              <TextPresenterCard color="white">{nbAnswers}</TextPresenterCard>
            </NumberAnswerContainer>
            <NumberAnswerContainer>
              <TextPresenterCard color="white">{this.displayPercentage(nbAnswers, totalAnswers)}</TextPresenterCard>
            </NumberAnswerContainer>
          </HeaderPresenterCard>
          {samplePlayersFr ? this.playersDisplay(samplePlayersFr) : null}
        </PresenterCard>
      );
    });
  };

  render() {
    const { data, quiz } = this.props;

    let totalAnswers = Number(quiz.timeoutAnswersCount) || 0;
    for (let answer in data.answers) {
      totalAnswers += data.answers[answer].nbAnswers;
    }
    return (
      <Content>
        {/* Display all the answers and related data */}
        {this.displayAnswers(data, totalAnswers)}
        {/*Display Jokers Data*/}
        {data.display && (quiz.maxJokersNext || quiz.maxJokersCopycat) ? (
          <JokersContainer>
            {/*JOKERS NEXT*/}
            {quiz.maxJokersNext ? (
              <PresenterCard isRight>
                <HeaderPresenterCard>
                  <InfoCardPresenter title="Nombre de jokers utilisés" titleFontSize={40} />
                </HeaderPresenterCard>
                <NumberAnswerContainer>
                  <TextPresenterCard color="white">{data.jokersNextCount}</TextPresenterCard>
                </NumberAnswerContainer>
                {data.samplePlayers
                  ? data.samplePlayers.jokers.next.map(user => (
                      <TextPresenterCard key={user} color="white">
                        {user}
                      </TextPresenterCard>
                    ))
                  : null}
              </PresenterCard>
            ) : null}
          </JokersContainer>
        ) : null}
        {/* MESSAGE DISPLAYER */}
      </Content>
    );
  }
}

export default PresenterContent;
