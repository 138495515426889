import React from 'react';
import styled from 'styled-components';
import { Button, Table, TextField } from '@material-ui/core';

import { TableContent, TableHeader } from '../musicTable';
import FileUpload from '../../screens/fileUpload';
import { storageServer } from '../../config';

const uploadMusic = {
  uploadPath: 'backOfficeUploads/goliveMusic',
  multiple: false,
  width: 800,
  height: 100,
};

const ScrollMusicContainer = styled.div`
  border-radius: 60px;
  overflow-y: scroll;
  justify-content: center;
  flex-direction: row;
  background: #2C3E50;
  background: -webkit-linear-gradient(to right, #2C3E50, #1db0dc);
  background: linear-gradient(to right, #2C3E50, #1db0dc);
  border-radius: 3px;
  border: 0;
  height: 400px;
  color: white;
  padding: 0 30px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

const UploadContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
`;

const FileDrop = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const MusiqueDiv = styled.div`
  display: flex;
  flex: 7;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const UrlAndRefresh = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
`;

const MusicDivHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledButtonMusique = styled(Button)`
  border-image: linear-gradient(to right, #1db0dc, #23d0d3);
  background: linear-gradient(to right, #1db0dc, #23d0d3);
  border-radius: 3px;
  border: 0;
  color: white;
  height: 48px;
  padding: 0 30px;
`;

class RenderMusique extends React.PureComponent {
    render() {
    const { filtered, handleChangeMusicUrl, deleteFile, _getPlaylistFunc, handleChangeInput } = this.props;
    return (
    <MusiqueDiv>
        <ScrollMusicContainer>
            <MusicDivHeader>
              <h1>Playlist</h1>
              <h1 style={{color: '#FF3333'}}>Quidol</h1>
              <TextField
                id="searchTerm"
                label="Rechercher"
                onChange={event => handleChangeInput(event)}
              />
            </MusicDivHeader>
            <Table>
              <TableHeader/>
              <tbody>
                <TableContent data={filtered} handleChange={handleChangeMusicUrl} deleteFile={deleteFile}/>
              </tbody>
            </Table>
        </ScrollMusicContainer>
        <UploadContainer>
          <FileDrop><FileUpload {...uploadMusic} route={storageServer + 'goLiveMusic/'} /></FileDrop>
        </UploadContainer>
        <UrlAndRefresh>
          <StyledButtonMusique onClick={_getPlaylistFunc}>Charger les musiques</StyledButtonMusique>
        </UrlAndRefresh>
      </MusiqueDiv>
    );
  }
}

export default RenderMusique;
