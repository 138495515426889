import React from 'react';
import { connect } from 'react-redux';
import { Column, Table } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { bindActionCreators } from 'redux';

import allTheActions from '../../../actions';
import LoadingBar from '../../../components/loadingBar';
import { CommonText } from '../../../components/texts';

import {
  Answer,
  ErrorContainer,
  Question,
  QuestionContainer,
  StyledDiv,
} from './styles'

class DisplayQuiz extends React.PureComponent {
  componentDidMount() {
    const { actions, match: { params: { id } } } = this.props;
    actions.quizzes.getQuiz(id);
  }

  renderAnswers = answers => answers.map(answer => (
    <Answer isRight={answer.isRight} key={answer.id}>
      {`${answer.id}: ${answer.content.fr} - ${answer.content.dz}`}
    </Answer>
  ));

  renderQuestion = question => {
    return (
      <QuestionContainer>
        <Question>
          {question.fr}
        </Question>
        <Question>
          {question.dz}
        </Question>
      </QuestionContainer>
    );
  };

  renderTable = questions => (
    <Table
      headerHeight={20}
      height={1000}
      rowCount={questions.length}
      rowGetter={({ index }) => questions[index]}
      rowHeight={100}
      width={1500}
    >
      <Column label="Numéro de question" dataKey="questionNumber" width={400} />
      <Column
        cellRenderer={({ cellData }) => this.renderQuestion(cellData)}
        dataKey="question"
        flexGrow={1}
        label="Question"
        width={1200}
      />
      <Column
        cellRenderer={({ cellData }) => this.renderAnswers(cellData)}
        dataKey="answers"
        label="Réponses"
        width={500}
      />
    </Table>
  );

  render() {
    const { quizzes, isLoading } = this.props;
    return (
      <StyledDiv>
        {quizzes.error ? (
          <ErrorContainer>
            <CommonText color="error">{quizzes.error}</CommonText>
          </ErrorContainer>
        ) : null}
        <LoadingBar isLoading={isLoading} variant="contained" />
        {!isLoading && this.renderTable(quizzes.quiz)}
      </StyledDiv>
    );
  }
}

const mapStateToProps = state => {
  return {
    quizzes: state.quidolGo,
    isLoading: state.quidolGo.isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    quizzes: bindActionCreators(allTheActions.quidolGo, dispatch),
  },
});

DisplayQuiz = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DisplayQuiz);

export default DisplayQuiz;
