import React from 'react';
import styled from 'styled-components';
import { Button, Table, TextField } from '@material-ui/core';

import { TableContent, TableHeader } from '../answerTableBurger';
import Answer from '../../utils/getUsersAnswers';
import CardTitle from '../cardTitle';

const DivAnswer = styled.div`
  justify-content: center;
  flex-direction: row;
`;

const Container = styled.div`
  display: flex;
  flex: 7;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1%;
`;

const AnswerDivHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  border-image: linear-gradient(to right, #1db0dc, #23d0d3);
  background: linear-gradient(to right, #1db0dc, #23d0d3);
  border-radius: 3px;
  border: 0;
  color: white;
  height: 48px;
  padding: 0 30px;
`;

const ErrorContainer = styled.h5`
  color: '#FF3333';
`;

class AnswerBuger extends React.PureComponent {
  state = {
    quizId: '',
    userId: '',
    saveUserId: '',
    saveQuizId: '',
    data: [],
    error: false,
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  search = async (quizId, userId) => {
    try {
      const { data } = await Answer.get(quizId, userId);
      if (!data.length) {
        throw new Error(`L'utilisateur "${
          userId
        }" n'a aucune réponse sur le quiz "${quizId}"`);
      }
      return this.setState({ data, error: '' });
    } catch(error) {
      this.setState({ data: [], error: error.message });
    };
  }

  render() {
    const { quizId, userId, data, error } = this.state;
    return (
      <Container>
        <DivAnswer>
          <AnswerDivHeader>
            {CardTitle('Réponses')}
            <TextField
              id="quizId"
              label="ID de quiz"
              onChange={this.handleChange('quizId')}
            />
            <TextField
              id="userId"
              label="ID d'utilisateur"
              onChange={this.handleChange('userId')}
            />
            <StyledButton
              disabled={!quizId || !userId}
              onClick={() => this.search(quizId, userId)}
            >
              RECHERCHER
            </StyledButton>
          </AnswerDivHeader>
          <Table>
            <TableHeader />
            <tbody>
              <TableContent data={data}/>
            </tbody>
          </Table>
          {error && <ErrorContainer>{error}</ErrorContainer>}
        </DivAnswer>
      </Container>
    );
  }
}

export default AnswerBuger;
