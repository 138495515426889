import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';

import allTheActions from '../../../actions';
import { storageServer, templateGoCsv } from '../../../config';
import CardTitle from '../../../components/cardTitle';
import LoadingBar from '../../../components/loadingBar';
import { TableHeader, TableContent } from '../../../components/goQuizzes';
import { CommonText } from '../../../components/texts';
import FileUpload from '../../fileUpload';

import {
  ButtonsContainer,
  ButtonText,
  ErrorContainer,
  FileDrop,
  FileDropContainer,
  QuizTableContainer,
  SpinnerContainer,
  StyledButton,
  StyledCard,
  StyledTableCell,
} from './styles'

class QuidolGoQuizTable extends React.PureComponent {
  state = {
    limit: 10,
    page: 0,
    searchText: '',
    uploadModal: false,
  };
  downloadURI = (uri, name) => {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.quidolGo.getQuizzes();
  }

  handleChangeInput = event => {
    this.setState({
      searchText: event.target.value,
    });
  };

  handleChangePage = page => {
    const { limit, searchText } = this.state;
    const { actions } = this.props;
    this.setState({ page }, () => actions.quidolGo.getQuizzes(searchText, limit, page));
  };

  // we fetch again when we change number of items displayed on a page
  handleChangeLimit = value => {
    const { searchText } = this.state;
    const { actions } = this.props;
    this.setState({ limit: value, page: 0 }, () => actions.quidolGo.getQuizzes(searchText, value, 0));
  };

  handleSubmit = async (event, searchText) => {
    const { actions } = this.props;
    this.setState({ page: 0, searchText }, () => actions.quidolGo.getQuizzes(searchText));
    event.preventDefault();
  };

  render() {
    const { limit, page, searchText } = this.state;
    const { actions, isLoading, quidolGo, searchTerm } = this.props;
    return (
      <StyledCard>
        {CardTitle('Go Fun')}
        {quidolGo.error && (
          <ErrorContainer>
            <CommonText color="error">{quidolGo.error}</CommonText>
          </ErrorContainer>
        )}
        <LoadingBar isLoading={isLoading} variant="contained" />
        <ButtonsContainer>
          <StyledButton
            disabled={isLoading}
            onClick={() => this.setState({uploadModal: !this.state.uploadModal})}
            variant="contained"
          >
            Créer depuis un CSV
          </StyledButton>
        </ButtonsContainer>
        {this.state.uploadModal && (
          <FileDropContainer>
            <ButtonsContainer>
              <Button
                disabled={isLoading}
                variant="contained"
                onClick={() => {
                  this.downloadURI(templateGoCsv, "templateGoCsv.csv")
                }}
              >
                <span>Télécharger un exemple</span>
              </Button>
              <Button
                disabled={isLoading}
                onClick={() => {
                  actions.quidolGo.getQuizzes();
                }}
                variant="contained"
              >
                Rafraîchir
              </Button>
            </ButtonsContainer>
            <FileDrop>
              <FileUpload route={`${storageServer}goCsv/`}/>
            </FileDrop>
          </FileDropContainer>
        )}
        <form onSubmit={event => this.handleSubmit(event, searchText)}>
          <ButtonsContainer>
            <TextField
              id="searchTerm"
              label="Nom du quiz"
              name={searchTerm}
              onChange={event => this.handleChangeInput(event)}
              style={{ marginLeft: 40, display: 'block' }}
            />
            <StyledButton
              disabled={isLoading}
              color="primary"
              variant="contained"
              onClick={event => this.handleSubmit(event, searchText)}
            >
              <ButtonText>Rechercher</ButtonText>
            </StyledButton>
          </ButtonsContainer>
        </form>
        <QuizTableContainer>
          <Table>
            <TableHeader />
            <TableBody>
              {!isLoading && quidolGo && quidolGo.data ? (
                <TableContent quizzes={quidolGo.data} onUpdate={() => {
                  const { limit, searchText } = this.state;
                  const { actions } = this.props;
                  this.setState({ page }, () => actions.quidolGo.getQuizzes(searchText, limit, 0));
                }} />
              ) : (
                <TableRow>
                  <StyledTableCell colSpan={11}>
                    <SpinnerContainer>
                      <CircularProgress size={24} />
                    </SpinnerContainer>
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!isLoading && (
            <TablePagination
              colSpan={1}
              count={quidolGo.count}
              onChangePage={(e, value) => this.handleChangePage(value)}
              onChangeRowsPerPage={({ target: { value } }) => this.handleChangeLimit(value)}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={quidolGo.count > 50 ? [10, 50, 100] : quidolGo.count > 10 ? [10, 50] : []}
            />
          )}
        </QuizTableContainer>
      </StyledCard>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.quidolGo.isLoading,
    quidolGo: state.quidolGo,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    quidolGo: bindActionCreators(allTheActions.quidolGo, dispatch),
    upload: bindActionCreators(allTheActions.upload, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuidolGoQuizTable);
