const latinCharacters = "À-ʯ";
const specialCharacters = "(){}\\[\\]'’\"/\\+=˜±?&*`§£;:,._^%<>$#!~@,-°“”€²øåş";
const arabicCharacter = 'ء-ي';
const greekCharacters = "Ύ-Ͽ";
const exponent = "¹²³⁴⁵⁶⁷⁸⁹⁰⁻";

const regex = "^[a-zA-Z0-9" + latinCharacters + exponent + specialCharacters + greekCharacters + arabicCharacter + "]+(?: [a-zA-Z0-9" + latinCharacters + exponent + specialCharacters + greekCharacters + "]+)*$";
const stringRegExp = new RegExp(regex, 'i');

export { stringRegExp };
