import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, FormSection, reduxForm, change } from 'redux-form';
import React from 'react';
import styled from 'styled-components';
import Collapsible from 'react-collapsible';
import { Button, CircularProgress } from '@material-ui/core';

import { CommonText } from '../components/texts';
import { InputText, renderSwitch } from '../components/reduxFormsComponents';
import LoadingBar from '../components/loadingBar';
import Prompt from '../components/prompt';
import RenderMusique from '../components/music';
import Separator from '../components/separator';
import quidolGoMusic from '../utils/goLiveMusique';
import { storageServer, templateQuestionCsv, millisecondsBonusQuestionMusic } from '../config';
import FileUpload from './fileUpload';
import {
  Footer,
  HeaderField,
  NormalQuestionsField,
  ImageQuestionsField,
  PanelFormFields,
  validate,
} from '../components/quizEditForm';
import allTheActions from '../actions';
import { history } from '../config/store';
import {
  createNormalQuestion,
  createNormalQuestionObject,
  createPanelFormObject,
  editQuestions,
} from '../components/quizEditForm/utils';


const AllContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ContentQuiz = styled.div`
  display: flex;
  flex-direction: column;
`;

const CollapsibleStyled = styled(Collapsible)`
  cursor: pointer;
  margin: 10px;
`;

const QuizEditContainer = styled.div`
  background-color: ${props => props.theme.color.white};
  border-radius: 5px;
  box-shadow: 0px 1px 2px black;
  display: flex;
  flex-direction: column;
  margin: 10px;
  min-width: 800px;
  padding: 10px;
`;

const QuestionContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: stretch;
  margin: 10px 0px;
`;

const QuizEditForm = styled.div``;

const StyledForm = styled.form`
  background-color: ${props => props.theme.color.mainFormColor};
  padding: 20px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: 120px;
`;

const OptionStyled = styled.option`
  outline: none;
  padding: 5px;
  text-align: center;
`;

const InputAndLabelContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const StyledCommonText = styled(CommonText)`
  margin-right: 10px;
`;

const FileDropContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const FileDrop = styled.div`
  width: 100%;
`;

class CreateQuiz extends React.PureComponent {
  state = {
    nbQuestions: 0,
    questions: [],
    questionType: 'mcq',
    nbAnswers: 4,
    page: 0,
    searchText: '',
    rewardQuestion: false,
    music: false,
    uploadModal: false,
    quizId: null,
    goLive: false,
    filtered: [],
    currentList: [],
    musicUrl: '',
    duration: 0,
    isUniqueCheck: false,
  };

  componentDidMount() {
    const quizId = this.props.match.params.id;
    const { actions, isCustomQuiz } = this.props;
    if (isCustomQuiz) {
      actions.quizzes.getCustomQuiz(quizId);
    } else {
      actions.quizzes.getQuiz(quizId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { quizzes } = this.props;
    const currentPage = this.props.match.params.page;
    const currentQuiz = quizzes.quiz;
    const nextPage = nextProps.match.params.page;
    const nextQuiz = nextProps.quizzes.quiz;
    const { link, permissions } = nextProps;
    let quiz = undefined;

    if (nextProps.isCustomQuiz) {
      quiz = nextProps.quizzes.quiz;
    } else {
      if (nextProps.quizzes.quiz)
        quiz = nextProps.quizzes.quiz[0];
    }

    // check if the quiz have been fetched or if we changed page
    if (nextQuiz !== currentQuiz || nextPage !== currentPage) {
      // check if a quiz exist
      if (quiz) {
        this.setState({quizId: quiz.id, goLive: quiz.goLive});
        const questionNumber = Number(nextProps.match.params.page);
        const currentQuestion = quiz.questions[questionNumber];
        const currentQuestions = quiz.questions;
        // set the panel data to inject, the first time it take the mongoDb data, then it take the changes made by the user
        let panelInitialize;
        console.log(nextProps.permissions);
        if (!this.state.panelForm) {
          panelInitialize = createPanelFormObject(quiz, nextProps.permissions);
        } else {
          panelInitialize = createPanelFormObject(this.state.panelForm, permissions);
        }
        if (!quiz.answers) {
          quiz.answers = {};
        }
        // prevent error if the user reload on a non saved quizz
        if (!currentQuestion) {
          const id = quiz.id;
          history.push(`/home/quizzes/edition/${id}/0`);
          return;
        }
        if (currentQuestion.type === 'mcq' || currentQuestion.type === 'image') {
          const formInitialize = createNormalQuestionObject(currentQuestion);
          this.setState({
            questionType: currentQuestion.type,
            questions: currentQuestions,
            nbQuestions: currentQuestions.length,
            nbAnswers: currentQuestion.answers.length,
          });
          // we combine the panel (common to both questions) to the question object
          const formAndPanel = {
            ...formInitialize,
            ...panelInitialize,
          };
          this.props.initialize(formAndPanel);
        }
        this.setState({
          rewardQuestion: !!currentQuestion.reward,
          music: !!currentQuestion.musicUrl,
          musicUrl: currentQuestion.musicUrl,
          duration: currentQuestion.duration
        });
        if (currentQuestion.music) {
          quidolGoMusic.getPlaylist().then((el) => {
            return this.setState({
              filtered: el.data,
              currentList: el.data
            });
          }).catch(e => console.log(e));
        }
      }
    }
    if (link) {
      window.open(link, '_blank');
      this.clearLink();
    }
  }

  handleNbAnswersChange = e => {
    const nbAnswers = Number(e.target.value);
    this.setState({ nbAnswers });
  };

  // change dynamically the nb of Char on a free questions
  handleResponseChange = e => {
    const length = e.target.value.length ? e.target.value.length : 0;
    if (e.target.value.match(/^[0-9]*$/)) this.props.change('freeQuestions.freeForm', 'number');
    else this.props.change('freeQuestions.freeForm', 'string');
    this.props.change('freeQuestions.freeNbChar', length);
  };

  submit = values => {
    const { actions, match, quizzes, isCustomQuiz } = this.props;
    const { questions } = this.state;
    const questionNumber = Number(match.params.page);
    const currentQuestion = questions[questionNumber];
    const currentQuestions = questions;
    let newQuestion;

    // store the values of the panel on the state, to reinject it on component will receive props
    this.setState({
      panelForm: {
        ...values.panel,
        liveVideoUri: { ios: values.panel.ios, android: values.panel.android },
        goLive: !!values.panel.goLive,
        isBroadcasting: !!values.panel.isBroadcasting,
        isStarted: !!values.panel.isStarted,
        isEnded: !!values.panel.isEnded,
        loosersText: values.panel.loosersText,
      },
    });

    // create a proper formatted object
    if (values.header.questionType === 'mcq' || values.header.questionType === 'image') {
      newQuestion = createNormalQuestion(values, currentQuestion, values.header.nbAnswers);
      const answers = {};
      newQuestion.answers.forEach(a => {
        answers[a.id.toUpperCase()] = a.content;
      });
      actions.quizzes.guessAnswer(values.question, answers, questionNumber);
    }
    // replace the former question by the new one
    currentQuestions.splice(questionNumber, 1, newQuestion);

    // slice the array to get the number of question set by this.state.nbQuestions
    const questionsUpdated = currentQuestions.slice(0, this.state.nbQuestions || 0);

    const dataPut = {
      addPlayers: Number(values.panel.addPlayers),
      id: isCustomQuiz ? quizzes.quiz.id : quizzes.quiz[0].id,
      questions: questionsUpdated,
      startAt: values.panel.startAt,
      nbQuestions: values.panel.nbQuestions,
      name: values.panel.name,
      subtitle: values.panel.subtitle,
      isBroadcasting: !!values.panel.isBroadcasting,
      isStarted: !!values.panel.isStarted,
      isEnded: !!values.panel.isEnded,
      thumbnail: values.panel.thumbnail,
      quizmaster: values.panel.quizmaster,
      maxJokersCopycat: Number(values.panel.maxJokersCopycat),
      maxJokersNext: Number(values.panel.maxJokersNext),
      android: values.panel.android,
      ios: values.panel.ios,
      prizepool: values.panel.prizepool,
      prizeType: values.panel.prizeType,
      inAppStartAt: values.panel.inAppStartAt,
      inAppEndAt: values.panel.inAppEndAt,
      resyncDelay: Number(values.panel.resyncDelay),
      delayHideAnswer: Number(values.panel.delayHideAnswer),
      programName: values.panel.programName,
      partnerLogo: values.panel.partnerLogo,
      theme: values.panel.theme,
      quizType: values.panel.quizType,
      player: values.panel.player,
      controller: values.panel.controller,
      goLive: !!values.panel.goLive,
      loosersText: values.panel.loosersText,
    }
    if (isCustomQuiz) {
      actions.quizzes.putCustomQuiz(dataPut);
    } else {
      actions.quizzes.putQuiz(dataPut);
    }
  };

  //  Manage the number of questions displayed, push a new question if the array is empty
  handleNumberQuestion = e => {
    this.setState({ nbQuestions: e.target.value }, () => {
      const questions = this.state.questions;
      const newQuestions = editQuestions(questions, this.state.nbQuestions);
      this.setState({ questions: newQuestions });
    });
  };

  handleEnableReward = () => {
    this.setState({ rewardQuestion: !this.state.rewardQuestion })
  }

  handleEnableMusic = () => {
    const { music, duration } = this.state;
    const { changeFieldValue } = this.props;
    this.setState({ music: !this.state.music })
    if (!music) {
      changeFieldValue('duration', (duration + millisecondsBonusQuestionMusic));
      this.setState({ duration: (duration + millisecondsBonusQuestionMusic) })
      this._getPlaylistFunc();
    }
    else {
      changeFieldValue('duration', (duration - millisecondsBonusQuestionMusic));
      changeFieldValue('musicUrl', '');
      this.setState({ duration: (duration - millisecondsBonusQuestionMusic) })
    }
  }

  clearLink = async () => {
    const {
      actions: { quizzes },
    } = this.props;
    await quizzes.quizzesClearLink();
  };

  downloadQuizzes() {
    const { actions, match } = this.props;
    const quizId = match.params.id;
    actions.quizzes.downloadQuizzes(quizId);
  }

  handleChangeInput = event => {
    const { currentList } = this.state;
    this.setState({
      searchText: event.target.value,
    });

    let newList = [];
    if (event.target.value !== "") {
        newList = currentList.filter(item => {
        const lc = item.name.toLowerCase();
        const filter = event.target.value.toLowerCase();
        return lc.includes(filter);
      });
    } else {
      newList = currentList;
    }
    this.setState({
      filtered: newList
    });
  };

  _getPlaylistFunc = () => {
    quidolGoMusic.getPlaylist().then((el) => {
      console.log(el.data)
      return this.setState({
        filtered: el.data,
        currentList: el.data
      });
    }).catch(e => console.log(e));
  }

  downloadURI = (uri, name) => {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  handleChangeMusicUrl = (url) => {
    this.setState({musicUrl: url});
    this.props.changeFieldValue('musicUrl', url)
  };

  deleteFile = async (url, name) => {
    await quidolGoMusic.deleteFile(url, name);
    setTimeout(() => {
      this._getPlaylistFunc()
    }, 500);
  };

  _renderQuestionField = () => {
    const { questionType, nbAnswers } = this.state;
    const { quizzes } = this.props;
    let answers = undefined;

    if (!quizzes.quiz && !quizzes.quiz[0]) {
      return <p> Pas de Quiz Trouvé </p>;
    }
    if (quizzes.quiz && quizzes.quiz[0]) {
        if (quizzes.quiz[0].answers) {
          const currentPage = this.props.match.params.page;
          answers = quizzes.quiz[0].answers[currentPage];
          console.log('OBJECT HERE', JSON.stringify(answers, null,2));
      }
    }
    if (questionType === 'mcq') return (
      <FormSection name="normalQuestions">
        <NormalQuestionsField quizzes={quizzes} nbAnswers={nbAnswers} answers={answers} />
      </FormSection>
    );
    if (questionType === 'image') return (
      <FormSection name="normalQuestions">
        <ImageQuestionsField quizzes={quizzes} nbAnswers={nbAnswers} answers={answers} change={(name, value) => this.props.changeFieldValue(name, value)}/>
      </FormSection>
    );
  }

  _renderMusic = () => {
    const { music, filtered } = this.state;
    if (music) {
      return (
        <RenderMusique
          filtered={filtered}
          handleChangeMusicUrl={this.handleChangeMusicUrl}
          deleteFile={this.deleteFile}
          _getPlaylistFunc={this._getPlaylistFunc}
          handleChangeInput={this.handleChangeInput}
        />
      );
    }
  }

  render() {
    const { actions, dirty, handleSubmit, isDownloading, isLoading, pristine, match, quizzes, submitting } = this.props;
    const { questionType, searchText, goLive, music, uploadModal } = this.state;

    return (
      <AllContainer>
        <QuizEditContainer>
          {/* The Collabsible panel */}
          <StyledButtonContainer>
            <StyledButton
              disabled={isLoading || isDownloading}
              variant="contained"
              onClick={() => this.downloadQuizzes(searchText)}
            >
              {isDownloading ? <CircularProgress size={24} /> : <span>Télécharger</span>}
            </StyledButton>
            <StyledButton
              disabled={isLoading || isDownloading}
              variant="contained"
              onClick={() => this.setState({uploadModal: !this.state.uploadModal})}
            >
              {isDownloading ? <CircularProgress size={24} /> : <span>Upload from CSV</span>}
            </StyledButton>
          </StyledButtonContainer>
          {uploadModal &&
            <FileDropContainer>
              <Button
              disabled={isLoading || isDownloading}
              variant="contained"
              onClick={() => this.downloadURI(templateQuestionCsv, "templateQuestionCsv.csv")}
              >
                <span>Télécharger un exemple</span>
              </Button>
              <FileDrop>
                <FileUpload route={`${storageServer}questionCSV/`} info={match.params.id}/>
              </FileDrop>
            </FileDropContainer>
          }
          <CollapsibleStyled trigger="Plus d'options">
            <FormSection name="panel">
              <PanelFormFields change={this.props.change} handleNumberQuestion={this.handleNumberQuestion} goLive={goLive} />
            </FormSection>
          </CollapsibleStyled>
          <QuizEditForm>
            {/* Warning prompt before leaving a page */}
            {/* quizzes.cannotChangeForm is triggered after pushing on enregistrer, it deactivate the prompt*/}
            <Prompt warn={quizzes.cannotChangeForm ? dirty : quizzes.cannotChangeForm} />
            <LoadingBar isLoading={quizzes.isLoading} />
            <StyledForm onSubmit={handleSubmit(value => this.submit(value))}>
              {/* Header Form */}
              <FormSection name="header">
                <HeaderField
                  handleNbAnswersChange={this.handleNbAnswersChange}
                  match={match}
                  questionType={questionType}
                />
              </FormSection>
              <Separator />
              <FormSection name="question">
                <QuestionContainer>
                  {/*Question Field, Common to free and normal Questions*/}
                  <Field
                    component={InputText}
                    disabled={quizzes.isLoading}
                    helperText="Question"
                    name="fr"
                    placeholder="Insérez la question"
                    type="text"
                    style={{ fontWeight: 'bold' }}
                  />
                </QuestionContainer>
                <QuestionContainer>
                  <Field
                    component={InputText}
                    disabled={quizzes.isLoading}
                    helperText="السؤال"
                    name="dz"
                    placeholder="أدخل السؤال"
                    type="text"
                    style={{ fontWeight: 'bold' }}
                    dir="rtl"
                  />
                </QuestionContainer>
              </FormSection>
              {/* ---------------------------- MUSIC ON/OFF ---------------------------------- */}
              {goLive &&
                <InputAndLabelContainer style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                  <Field onChange={this.handleEnableMusic} component={renderSwitch} name="music"/>
                  <CommonText>Question avec Musique</CommonText>
                </InputAndLabelContainer>}
              {this._renderMusic()}
              {music && <Field
                component={InputText}
                disabled={true}
                helperText="Url de la musique"
                name="musicUrl"
                type="text"
              />}
              {/* ----------------------------------------------------------------------------- */}
              <ContentQuiz>
                {/* Display different form depending on the type of questions*/}
                {this._renderQuestionField()}
                {/* Duration Field*/}
                <Field
                  component={InputText}
                  disabled={quizzes.isLoading}
                  helperText="Durée"
                  name="duration"
                  placeholder="Inserez La durée"
                  type="number"
                />
                <InputAndLabelContainer>
                  <Field onChange={this.handleEnableReward} component={renderSwitch} name="rewardQuestion"/>
                  <CommonText>Gains intermédiaires</CommonText>
                </InputAndLabelContainer>
                {this.state.rewardQuestion &&
                <div>
                  <StyledCommonText fontSize={12}>Type de gain</StyledCommonText>
                  <Field
                    component="select"
                    name="prizeType"
                    value="money"
                    >
                    <OptionStyled value="money">Argent</OptionStyled>
                    <OptionStyled value="jokers">Jokers</OptionStyled>
                  </Field>
                  <Field
                    component={InputText}
                    disabled={quizzes.isLoading}
                    helperText="Gain en euro ou en joker"
                    name="prizePool"
                    placeholder="Insérez le montant du gain"
                    type="number"
                    />
                </div>}
                {/* Script Field (common to both)
                <ScriptQuizContainer>
                  <Field
                    component={InputText}
                    disabled={quizzes.isLoading}
                    name="script"
                    placeholder="Entrez le Script"
                    multiLine
                    rows={8}
                    type="text"
                  />
                </ScriptQuizContainer>*/}
              </ContentQuiz>
              {/*Footer Dynamically set depending on the numbers of questions*/}
              {this.state.nbQuestions ? (
                <Footer
                  actions={actions}
                  id={match.params.id}
                  // versionType={versionType}
                  nbQuestions={this.state.nbQuestions}
                  page={match.params.page}
                  pristine={pristine}
                  questions={this.state.questions}
                  submitting={submitting}
                />
              ) : null}
            </StyledForm>
          </QuizEditForm>
        </QuizEditContainer>
      </AllContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    isDownloading: state.quizzes.isDownloading,
    permissions: state.quizzes.permissions,
    link: state.quizzes.link,
    quizzes: state.quizzes,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    quizzes: bindActionCreators(allTheActions.quizzes, dispatch),
  },
  changeFieldValue: function(field, value) {
    dispatch(change('CreateQuiz', field, value))
  }
});

CreateQuiz = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateQuiz);

CreateQuiz = reduxForm({
  form: 'CreateQuiz', // a unique identifier for this form
  validate,
})(CreateQuiz);

export default CreateQuiz;
