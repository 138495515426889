import _ from 'lodash';

import {
  PENDING_MESSAGES,
  PENDING_DOWNLOAD,
  DISPLAY_MESSAGES,
  DISPLAY_MESSAGES_HISTORY,
  CLEAR_MESSAGES,
  CLEAR_MESSAGES_HISTORY,
  UPDATE_SCROLL_SPEED,
  UPDATE_QUIZ_ID,
  CHANGE_QUIZ,
  UPDATE_LAST_CREATED_AT,
  UPDATE_FONT_SIZE,
  MESSAGES_DOWNLOAD_LINK,
  MESSAGES_CLEAR_LINK
} from '../actions/messages';

const initialState = {
  chunkOfMessages: [],
  fetchInterval: 2000,
  fontSize: 16,
  isDownloading: false,
  isLoading: false,
  lastCreatedAt: null,
  link: '',
  messages: [],
  messagesHistory: [],
  quizId: null,
  scrollSpeed: 18
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PENDING_MESSAGES:
      return {
        ...state,
        isLoading: action.payload
      };
    case PENDING_DOWNLOAD:
      return {
        ...state,
        isDownloading: action.payload
      };
    case DISPLAY_MESSAGES:
      return {
        ...state,
        messages: _.uniqBy([...state.messages, ...action.payload], '_id')
      };
    case DISPLAY_MESSAGES_HISTORY:
      return {
        ...state,
        messagesHistory: _.uniqBy(
          [...state.messagesHistory, ...action.payload],
          '_id'
        )
      };
    case CLEAR_MESSAGES:
      return {
        ...state,
        messages: []
      };
    case CLEAR_MESSAGES_HISTORY:
      return {
        ...state,
        messagesHistory: []
      };
    case UPDATE_SCROLL_SPEED:
      return {
        ...state,
        scrollSpeed: action.payload
      };
    case UPDATE_QUIZ_ID:
      return {
        ...state,
        quizId: action.payload
      };
    case CHANGE_QUIZ:
      return {
        ...state,
        quizId: action.payload.quizId,
        messages: [],
        lastCreatedAt: null
      };
    case UPDATE_LAST_CREATED_AT:
      return {
        ...state,
        lastCreatedAt: action.payload
      };
    case UPDATE_FONT_SIZE:
      return {
        ...state,
        fontSize: action.payload
      };
    case MESSAGES_DOWNLOAD_LINK:
      return {
        ...state,
        link: action.payload
      };
    case MESSAGES_CLEAR_LINK:
      return {
        ...state,
        link: ''
      };
    default:
      return state;
  }
};
