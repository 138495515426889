import {
  DISPLAY_USER,
  DISPLAY_USERS_LENGTH,
  DISPLAY_USERS,
  PENDING_USER,
  PENDING_UPDATE_USER,
  PENDING_DOWNLOAD_USER,
  DELETE_SUCCESS,
  DISABLE_SUCCESS,
  USERS_DOWNLOAD_LINK,
  USERS_CLEAR_LINK
} from '../actions/users';

const initialState = {
  isLoading: false,
  pendingUpdate: false,
  pendingDownload: false,
  deleteSuccess: false,
  disableSuccess: false,
  user: {},
  users: [],
  length: 0,
  link: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_USER:
      return {
        ...state,
        user: action.payload
      };
    case DISPLAY_USERS_LENGTH:
      return {
        ...state,
        length: action.payload
      };
    case DISPLAY_USERS:
      return {
        ...state,
        users: action.payload
      };
    case PENDING_USER:
      return {
        ...state,
        isLoading: action.payload
      };
    case PENDING_UPDATE_USER:
      return {
        ...state,
        pendingUpdate: action.payload
      };
    case PENDING_DOWNLOAD_USER:
      return {
        ...state,
        pendingDownload: action.payload
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        deleteSuccess: action.payload
      };
    case DISABLE_SUCCESS:
      return {
        ...state,
        disableSuccess: action.payload
      };
    case USERS_DOWNLOAD_LINK:
      return {
        ...state,
        link: action.payload
      };
    case USERS_CLEAR_LINK:
      return {
        ...state,
        link: ''
      };
    default:
      return state;
  }
};
