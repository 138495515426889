import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { InputText } from '../reduxFormsComponents';
import validate from './validate';

const StyledForm = styled.form`
  background-color: #0089cc;
  padding: 20px;
`;

const StyledButton = styled.button`
  background-color: ${props => props.theme.color.mainColor};
  border: none;
  color: ${props => props.theme.color.white};
  cursor: pointer;
  font-size: 16px;
  height: 30px;
  margin-top: 10px;
  width: 100%;
`;

let LoginForm = props => {
  const { handleSubmit, onSubmit, pristine, submitting } = props;
  return (
    <StyledForm onSubmit={handleSubmit(values => onSubmit(values))}>
      <Field component={InputText} name="email" placeholder="Email" type="email" />
      <Field component={InputText} name="password" placeholder="Password" type="password" />
      <StyledButton type="submit" disabled={pristine || submitting}>
        SIGN IN
      </StyledButton>
    </StyledForm>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
};

LoginForm = reduxForm({
  form: 'LoginForm', // a unique identifier for this form
  validate, // validate condition for the form
})(LoginForm);

export default LoginForm;
