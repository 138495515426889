import React from 'react';
import styled from 'styled-components';

import InfoCard from '../infoCard';
import WaitAndGo from '../waitAndGo';

const Header = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 100%;
`;

const InfoCardPresenter = styled(InfoCard)`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;
`;

class PresenterHeader extends React.PureComponent {
  render() {
    const {
      isQuestionEnded,
      nbLoosers,
      nbPlayers,
      nbViewers,
      order,
      gainPerWinner,
      questionLabel,
      questionStates,
      questionId,
    } = this.props;
    let title = questionLabel ? questionLabel : order ? `Q${order}` : 'Q-';
    if (title.toLowerCase() === 'sondage') title = 'POLL';
    if (title.toLowerCase() === 'question bonus') title = 'BONUS';
    return (
      <Header>
        <InfoCardPresenter title={title} titleFontSize={55} />
        <InfoCardPresenter
          content={nbPlayers ? `${nbPlayers}` : '0'}
          contentFontSize={40}
          title={nbPlayers > 1 ? 'Joueurs' : 'Joueur'}
          titleFontSize={24}
        />
        <InfoCardPresenter
          content={nbViewers ? `${nbViewers}` : '0'}
          contentFontSize={40}
          title={nbViewers > 1 ? 'Spectateurs' : 'Spectateur'}
          titleFontSize={24}
        />
        <InfoCardPresenter
          content={nbLoosers ? `${nbLoosers}` : '0'}
          contentFontSize={40}
          title={nbLoosers > 1 ? 'Perdants' : 'Perdant'}
          titleFontSize={24}
        />
        <InfoCardPresenter
          content={gainPerWinner ? `${gainPerWinner}` : '-'}
          contentFontSize={40}
          title={'Gain par vainqueur'}
          titleFontSize={24}
        />
        <InfoCardPresenter content={questionStates[questionId] || '-'} contentFontSize={40} title={'Statut'} titleFontSize={24} />
        <WaitAndGo isQuestionEnded={isQuestionEnded} />
      </Header>
    );
  }
}

export default PresenterHeader;
