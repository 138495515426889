import { Check } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styled from 'styled-components';

import { CommonText } from '../texts';

const Card = styled.div`
  background-color: ${props => props.theme.color.cardTimerColor};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100px;
  justify-content: space-around;
  align-items: center;
  margin: 10px;
  width: 150px;
  flex-direction: column;
`;

const CircleNumberContainer = styled.div`
  position: relative;
`;
const TitleContainer = styled.p`
  color: white;
`;

const CommonTextAbsolute = styled(CommonText)`
  position: absolute;
  right: 25px;
  top: 25px;
`;

const CheckStyled = styled(Check)`
  font-size: 76px;
`;

export default class CardTimer extends React.Component {
  timer = null;

  state = {
    completed: 0,
    finish: false,
    second: 0,
  };

  componentDidMount() {
    if (this.props.isStarted) {
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(this.progress, 1000);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isStarted !== this.props.isStarted && nextProps.isStarted === true) {
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(this.progress, 1000);
    }
    if (nextProps.isStarted !== this.props.isStarted && nextProps.isStarted === false) {
      if (this.timer) clearInterval(this.timer);
    }
    if (nextProps.id !== this.props.id) {
      this.setState({
        completed: 0,
        finish: false,
        second: 0,
      });
      if (nextProps.isStarted) {
        if (this.timer) clearInterval(this.timer);
        this.timer = setInterval(this.progress, 1000);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  progress = () => {
    const { completed, second } = this.state;
    const progressTime = this.props.timer ? 100 / this.props.timer : 5;
    if (this.state.completed < 100) {
      this.setState({
        completed: completed + progressTime,
        second: second + 1,
      });
    } else {
      if (this.timer) clearInterval(this.timer);
      this.setState({ finish: true });
      if (this.props.onEnd) this.props.onEnd();
    }
  };

  render() {
    const { completed, finish } = this.state;
    const { isEnded, title } = this.props;
    return (
      <Card>
        <TitleContainer>{title}</TitleContainer>
        {finish || isEnded ? (
          <CheckStyled color="secondary" />
        ) : (
          <CircleNumberContainer>
            <CircularProgress color="secondary" size={75} value={completed} variant="static" />
            <CommonTextAbsolute color="white" fontSize={26}>
              {this.state.second}
            </CommonTextAbsolute>
          </CircleNumberContainer>
        )}
      </Card>
    );
  }
}
