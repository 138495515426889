import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  margin: 20px 0 0 40px;
`;

export const ButtonText = styled.span``;

export const SpinnerContainer = styled.div`
  padding: 20px 0;
  text-align: center;
  width: 100%;
`;

export const StyledCard = styled.div`
  width: 100%;
`;

export const QuizTableContainer = styled.div`
  padding: 0 24px;
`;

export const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-left: 30px;
`;

export const StyledTableCell = styled(TableCell)`
  width: 100%;
`;

export const FileDropContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const FileDrop = styled.div`
  width: 100%;
`;
