import styled from 'styled-components';

export const StyledDiv = styled.div`
  flex-direction: column;
  width: 100%;
`;

export const Answer = styled.div`
  background-color: ${props => (props.isRight ? 'lightgreen' : null)};
`;

export const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 100%;
`;

export const Question = styled.div`
`;

export const QuestionContainer = styled.div`
`;
