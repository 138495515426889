import React, { Component } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import Undo from '@material-ui/icons/Undo';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  margin: 10px;
`;

export class ButtonSearchWinners extends Component {
  render() {
    const { buttonsDisabled } = this.props;
    return (
      <Button
        disabled={buttonsDisabled}
        color="primary"
        variant="contained"
        style={{ marginLeft: 10, marginTop: 20 }}
        type="submit"
      >
        <span>Rechercher</span>
      </Button>
    );
  }
}

export class ButtonDownloadWinners extends Component {
  render() {
    const {
      buttonsDisabled,
      downloadWinners,
      getPhoneNumber,
      pendingDownload,
      searchText
    } = this.props;
    return (
      <Button
        disabled={buttonsDisabled}
        variant="contained"
        style={{ marginLeft: 20, marginTop: 20 }}
        onClick={() => downloadWinners(getPhoneNumber, searchText)}
      >
        {pendingDownload ? (
          <CircularProgress size={24} />
        ) : (
          <span>Télécharger</span>
        )}
      </Button>
    );
  }
}

export class ButtonDownloadIbanWinners extends Component {
  render() {
    const {
      buttonsDisabled,
      downloadIbanWinners,
      pendingDownloadIban
    } = this.props;
    return (
      <StyledButton
        disabled={buttonsDisabled}
        variant="contained"
        onClick={downloadIbanWinners}
      >
        {pendingDownloadIban ? (
          <CircularProgress size={24} />
        ) : (
          <span>Téléchargez les vainqueurs CCP</span>
        )}
      </StyledButton>
    );
  }
}

export class ButtonDownloadPaypalWinners extends Component {
  render() {
    const {
      buttonsDisabled,
      pendingDownloadPaypal,
      downloadPaypalWinners
    } = this.props;
    return (
      <StyledButton
        disabled={buttonsDisabled}
        variant="contained"
        onClick={downloadPaypalWinners}
      >
        {pendingDownloadPaypal ? (
          <CircularProgress size={24} />
        ) : (
          <span>Téléchargez les vainqueurs Paypal</span>
        )}
      </StyledButton>
    );
  }
}

export class ButtonValidateIbanWinners extends Component {
  render() {
    const {
      buttonsDisabled,
      pendingValidateIban,
      validateWinners
    } = this.props;
    return (
      <StyledButton variant="contained" disabled={buttonsDisabled}>
        <input
          accept="application/xml"
          onChange={({
            target: {
              files: [file]
            }
          }) => validateWinners('validateIban', file)}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: '0',
            bottom: '0',
            right: '0',
            left: '0',
            width: '100%',
            opacity: '0'
          }}
          type="file"
        />
        {pendingValidateIban ? (
          <CircularProgress size={24} />
        ) : (
          <span>Valider les gagnants CCP</span>
        )}
      </StyledButton>
    );
  }
}

export class ButtonValidatePaypalWinners extends Component {
  render() {
    const {
      buttonsDisabled,
      pendingValidatePaypal,
      validateWinners
    } = this.props;
    return (
      <StyledButton variant="contained" disabled={buttonsDisabled}>
        <input
          onChange={({
            target: {
              files: [file]
            }
          }) => validateWinners('validatePaypal', file)}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: '0',
            bottom: '0',
            right: '0',
            left: '0',
            width: '100%',
            opacity: '0'
          }}
          type="file"
        />
        {pendingValidatePaypal ? (
          <CircularProgress size={24} />
        ) : (
          <span>Valider les gagnants Paypal</span>
        )}
      </StyledButton>
    );
  }
}

export class RevertUniqWinnerButton extends Component {
  render() {
    const {
      buttonsDisabled,
      pendingRevertUniqWinner,
      pendingRevertUniqWinnerId,
      status,
      validateWinners,
      winnerId
    } = this.props;
    return (
      <Button
        variant="contained"
        disabled={status !== 'paid' || buttonsDisabled}
        onClick={() => validateWinners('revertUniqWinner', null, winnerId)}
      >
        {pendingRevertUniqWinner && winnerId === pendingRevertUniqWinnerId ? (
          <CircularProgress size={24} />
        ) : (
          <Undo />
        )}
      </Button>
    );
  }
}

export class ButtonValidateUniqIban extends Component {
  render() {
    const {
      buttonsDisabled,
      iban,
      pendingValidateUniqIban,
      pendingValidateUniqIbanWinnerId,
      status,
      validateWinners,
      winnerId
    } = this.props;
    return (
      <Button
        variant="contained"
        disabled={!iban || status !== 'new' || buttonsDisabled}
        onClick={() => validateWinners('validateUniqIban', null, winnerId)}
      >
        {pendingValidateUniqIban &&
        winnerId === pendingValidateUniqIbanWinnerId ? (
          <CircularProgress size={24} />
        ) : (
          <span>CCP</span>
        )}
      </Button>
    );
  }
}

export class ButtonValidateUniqPaypal extends Component {
  render() {
    const {
      buttonsDisabled,
      paypal,
      pendingValidateUniqPaypal,
      pendingValidateUniqPaypalWinnerId,
      status,
      validateWinners,
      winnerId
    } = this.props;
    return (
      <Button
        variant="contained"
        disabled={!paypal || status !== 'new' || buttonsDisabled}
        onClick={() => validateWinners('validateUniqPaypal', null, winnerId)}
      >
        {pendingValidateUniqPaypal &&
        winnerId === pendingValidateUniqPaypalWinnerId ? (
          <CircularProgress size={24} />
        ) : (
          <span>PayPal</span>
        )}
      </Button>
    );
  }
}
