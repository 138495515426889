import axios from 'axios';
import moment from 'moment';
import { config } from '../config/firebase';
export const UPDATE_STATS = 'UPDATE_STATS';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const UPDATE_START_DATE = 'UPDATE_START_DATE';
export const UPDATE_END_DATE = 'UPDATE_END_DATE';

export const updateStartDate = startDate => ({
  type: UPDATE_START_DATE,
  startDate,
});

export const updateEndDate = endDate => ({
  type: UPDATE_END_DATE,
  endDate,
});

export const getStats = () => async (dispatch, getState) => {
  const startDate = getState().admob.startDate;
  const endDate = getState().admob.endDate;
  if (startDate && endDate && startDate < endDate) {
    try {
      const { data: stats } = await axios({
        method: 'get',
        params: { endDate, startDate },
        url: `${config.cloudHttps}httpsAdminAdMobGenerateStats`,
      });
      console.log(stats);
      dispatch(updateStats(stats));
    } catch (e) {
      console.error(e);
    }
  }
};

export const updateStats = stats => ({
  type: UPDATE_STATS,
  stats,
});

export const getConfig = () => async dispatch => {
  try {
    const { data: adsConfig } = await axios({
      method: 'get',
      url: `${config.cloudHttps}httpsAdminAdMobGetConfig`,
    });
    dispatch(updateConfig(adsConfig));
  } catch (e) {
    console.error(e);
  }
};

export const updateConfig = config => ({
  type: UPDATE_CONFIG,
  config,
});

export const generateStatsFile = () => async (dispatch, getState) => {
  const startDate = getState().admob.startDate;
  const endDate = getState().admob.endDate;
  if (startDate && endDate && startDate < endDate) {
    try {
      const { data: playersStats } = await axios({
        method: 'get',
        url: `${config.cloudHttps}httpsAdminAdMobDownloadStats`,
        params: {
          startDate,
          endDate,
        },
      });
      console.log(playersStats);
      dispatch(updateStats({ playersStats }));
      let csvContent = 'data:text/csv;charset=utf-8,';
      csvContent += '"User Id";"Nombre de cliques sur le bouton visualiser";"Nombre de pubs vues";"Nombres de jokers donnés"\n';
      playersStats.forEach(e => {
        csvContent += `"${e.user}";${e.visualiseClicked};${e.adsSeen};${e.jokersGiven}\n`;
      });

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `adMobStatsFrom${moment(startDate).format('DD-MM-YY')}To${moment(endDate).format('DD-MM-YY')}.csv`);
      document.body.appendChild(link); // Required for FF

      link.click();
    } catch (e) {
      console.error(e);
    }
  }
};

export const saveConfig = () => async (dispatch, getState) => {
  const newConfig = getState().admob.config;
  try {
    const { data: adsConfig } = await axios({
      method: 'put',
      url: `${config.cloudHttps}httpsAdminAdMobChangeAdsConfig`,
      data: newConfig,
    });
    dispatch(updateConfig(adsConfig));
  } catch (e) {
    console.error(e);
  }
};
