import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import React from 'react';
import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { config } from '../../config/firebase';

const LinkStyled = styled(Link)`
  text-decoration: none;
`;

const TableContent = props => {
  return props.quizzes.map(quiz => {
    return (
      <TableRow key={quiz._id}>
        <TableCell>{quiz.name}</TableCell>
        <TableCell>{moment(quiz.startAt).format('DD/MM/YYYY HH:mm')}</TableCell>
        <TableCell>
          {!quiz.isEnded ?  <LinkStyled to={`/home/controls/${quiz.id}/`}>
              <Button>Jouer</Button>
            </LinkStyled>
            : <center><span role="img" aria-label={'yes'} >✔</span></center> }
        </TableCell>
        <TableCell>{quiz.prizeType === 'money' ? `${quiz.prizepool} DA` : `${quiz.prizepool} ${quiz.prizeType}`}</TableCell>
        <TableCell>{quiz.nbQuestions}</TableCell>
        <TableCell>{quiz.id}</TableCell>
        <TableCell>
          {config.enableMessageHistory && quiz.isEnded ?
            (<LinkStyled to={`/home/messagesHistory/${quiz.id}`}>
              <Button>MESSAGES</Button>
            </LinkStyled>)
            : null}
        </TableCell>
        <TableCell>
          <LinkStyled
            to={`/home/quizzes/edition/${quiz.id}/0`}
          >
            <Button>MODIFIER</Button>
          </LinkStyled>
        </TableCell>
      </TableRow>
    );
  });
};

export default TableContent;
