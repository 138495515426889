import React from 'react';
import styled from 'styled-components';

import CardTimer from '../cardTimer';
import ControlsActionLine from '../controlsActionsLine';
import QuestionDisplayer from '../questionDisplayer';
import VideoPlayer from '../videoPlayer';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuestionVideoContainer = styled.div`
  display: flex;
`;

const VideoInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardTimerContainer = styled.div`
  display: flex;
  margin: 10px;
`;

class ControlsContent extends React.Component {
  render() {
    const {
      actions,
      currentQuestion,
      isBroadcasting,
      quizId,
      urlStream,
      videoChunkTime,
      answerHideDelay,
      previousQuestion,
      maxJokersNext,
      maxJokersCopycat,
    } = this.props;
    if (!currentQuestion) {
      return null;
    }
    const questionDuration = (currentQuestion.duration || 1000) / 1000 + 3;
    return (
      <ContentContainer>
        <QuestionVideoContainer>
          {/*Display The Question and the count of answers timeOut... */}
          <QuestionDisplayer currentQuestion={currentQuestion} maxJokersNext={maxJokersNext} maxJokersCopycat={maxJokersCopycat} />
          {/* Contain the Video Player and the Timer Cards*/}
          <VideoInfoContainer>
            <VideoPlayer actions={actions} urlStream={urlStream} />
            <CardTimerContainer>
              {/* onEnd is launched when the timer ends */}
              <CardTimer
                isStarted={!!currentQuestion.display}
                isEnded={currentQuestion.isExpired}
                title="Affichage"
                id={currentQuestion.id}
                timer={questionDuration}
              />
            </CardTimerContainer>
          </VideoInfoContainer>
        </QuestionVideoContainer>
        {/* Control Button, Display Question, Next Question, Hide Question */}
        <ControlsActionLine
          actions={actions}
          currentQuestion={currentQuestion}
          isBroadcasting={isBroadcasting}
          questionId={currentQuestion.id}
          previousQuestion={previousQuestion}
          quizId={quizId}
          answerHideDelay={answerHideDelay}
          videoChunkTime={videoChunkTime}
          questionDuration={questionDuration}
        />
      </ContentContainer>
    );
  }
}

export default ControlsContent;
