import axios from 'axios';
import { config } from '../config/firebase';

export const DISPLAY_WINNERS = 'DISPLAY_WINNERS';
export const DISPLAY_WINNERS_LENGTH = 'DISPLAY_WINNERS_LENGTH';
export const PENDING_WINNERS = 'PENDING_WINNERS';
export const PENDING_DOWNLOAD_WINNERS = 'PENDING_DOWNLOAD_WINNERS';
export const PENDING_DOWNLOAD_IBAN_WINNERS = 'PENDING_DOWNLOAD_IBAN_WINNERS';
export const PENDING_DOWNLOAD_PAYPAL_WINNERS =
  'PENDING_DOWNLOAD_PAYPAL_WINNERS';
export const PENDING_REVERT_UNIQ_WINNER = 'PENDING_REVERT_UNIQ_WINNER';
export const PENDING_VALIDATE_IBAN = 'PENDING_VALIDATE_IBAN';
export const PENDING_VALIDATE_PAYPAL = 'PENDING_VALIDATE_PAYPAL';
export const PENDING_VALIDATE_UNIQ_IBAN = 'PENDING_VALIDATE_UNIQ_IBAN';
export const PENDING_VALIDATE_UNIQ_PAYPAL = 'PENDING_VALIDATE_UNIQ_PAYPAL';
export const WINNERS_CLEAR_LINK = 'WINNERS_CLEAR_LINK';
export const WINNERS_DOWNLOAD_LINK = 'WINNERS_DOWNLOAD_LINK';

export const displayWinners = winners => ({
  type: 'DISPLAY_WINNERS',
  payload: winners
});

export const displayWinnersLength = length => ({
  type: 'DISPLAY_WINNERS_LENGTH',
  payload: length
});

export const pending = boolean => ({
  type: 'PENDING_WINNERS',
  payload: boolean
});

export const pendingDownloadWinners = boolean => ({
  type: 'PENDING_DOWNLOAD_WINNERS',
  payload: boolean
});

export const pendingDownloadIbanWinners = boolean => ({
  type: 'PENDING_DOWNLOAD_IBAN_WINNERS',
  payload: boolean
});

export const pendingDownloadPaypalWinners = boolean => ({
  type: 'PENDING_DOWNLOAD_PAYPAL_WINNERS',
  payload: boolean
});

export const pendingRevertUniqWinner = (boolean, winnerId) => ({
  type: 'PENDING_REVERT_UNIQ_WINNER',
  payload: {
    boolean,
    winnerId
  }
});

export const pendingValidateIban = boolean => ({
  type: 'PENDING_VALIDATE_IBAN',
  payload: boolean
});

export const pendingValidatePaypal = boolean => ({
  type: 'PENDING_VALIDATE_PAYPAL',
  payload: boolean
});

export const pendingValidateUniqIban = (boolean, winnerId) => ({
  type: 'PENDING_VALIDATE_UNIQ_IBAN',
  payload: {
    boolean,
    winnerId
  }
});

export const pendingValidateUniqPaypal = (boolean, winnerId) => ({
  type: 'PENDING_VALIDATE_UNIQ_PAYPAL',
  payload: {
    boolean,
    winnerId
  }
});

export const winnersClearLink = link => ({
  type: 'WINNERS_CLEAR_LINK',
  payload: link
});

export const winnersDownloadLink = link => ({
  type: 'WINNERS_DOWNLOAD_LINK',
  payload: link
});

export const getWinners = (searchTerm, page = 0, limit = 10, from = new Date(0), to = new Date()) => async dispatch => {
  dispatch(pending(true));
  let query;
  if (searchTerm) {
    query = {
      $or: [
        { iban: { $regex: searchTerm, $options: 'i' } },
        { quizId: { $regex: searchTerm, $options: 'i' } },
        { quizName: { $regex: searchTerm, $options: 'i' } },
        { nickname: { $regex: searchTerm, $options: 'i' } },
        { paypal: { $regex: searchTerm, $options: 'i' } },
        { status: { $regex: searchTerm, $options: 'i' } },
        { userId: { $regex: searchTerm, $options: 'i' } },
      ],
    };
  }
  const skip = limit * page;
  try {
    const [{ data: winners }, { data: winnersLength }] = await Promise.all([
      axios({
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          idToken: localStorage.getItem('firebaseToken')
        },
        method: 'GET',
        params: { limit, query, skip, from, to },
        url: `${config.userServer}winners`,
      }),
      axios({
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          idToken: localStorage.getItem('firebaseToken')
        },
        method: 'GET',
        params: { query, from, to },
        url: `${config.userServer}winnersLength`,
      }),
    ]);
    dispatch(displayWinners(winners));
    dispatch(displayWinnersLength(winnersLength));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(pending(false));
  }
};

export const downloadWinners = (getPhoneNumber, searchTerm) => dispatch => {
  dispatch(pendingDownloadWinners(true));
  axios({
    method: 'GET',
    url: `${config.cloudHttps}httpsAdminWinnersDownload`,
    params: {
      getPhoneNumber,
      searchTerm
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      idToken: localStorage.getItem('firebaseToken')
    }
  })
    .then(res => {
      dispatch(winnersDownloadLink(res.data));
      dispatch(pendingDownloadWinners(false));
      return;
    })
    .catch(err => {
      alert(err);
      console.log(err);
      dispatch(pendingDownloadWinners(false));
      return;
    });
};

export const downloadIbanWinners = (searchTerm, from, to) => dispatch => {
  let query = {};
  dispatch(pendingDownloadIbanWinners(true));
  if (searchTerm) {
    query = {
      $or: [
        { iban: { $regex: searchTerm, $options: 'i' } },
        { quizId: { $regex: searchTerm, $options: 'i' } },
        { quizName: { $regex: searchTerm, $options: 'i' } },
        { nickname: { $regex: searchTerm, $options: 'i' } },
        { paypal: { $regex: searchTerm, $options: 'i' } },
        { status: { $regex: searchTerm, $options: 'i' } },
        { userId: { $regex: searchTerm, $options: 'i' } },
      ],
    };
  }
  axios.get(`${config.userServer}downloadCCP`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      idToken: localStorage.getItem('firebaseToken')
    },
    params: {
      oui: 'non',
      from,
      to,
      query,
    },
  })
    .then(res => {
      if (res.status !== 200) return alert('Une erreur est survenue');
      const content = res.data;
      const file = new Blob([content], { type: 'application/xml' });
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'winners.csv';
      a.click();
      window.URL.revokeObjectURL(url);
      dispatch(pendingDownloadIbanWinners(false));
      return;
    })
    .catch(err => {
      alert(err);
      console.log(err);
      dispatch(pendingDownloadIbanWinners(false));
      return;
    });
};

export const downloadPaypalWinners = () => dispatch => {
  dispatch(pendingDownloadPaypalWinners(true));
  axios({
    method: 'GET',
    url: `${config.cloudHttps}httpsAdminWinnersDownloadPaypal`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      idToken: localStorage.getItem('firebaseToken')
    }
  })
    .then(res => {
      res.data.map((link) =>
        dispatch(winnersDownloadLink(link))
      );
      dispatch(pendingDownloadPaypalWinners(false));
      return;
    })
    .catch(err => {
      alert(err);
      console.log(err);
      dispatch(pendingDownloadPaypalWinners(false));
      return;
    });
};

const buttonSpinner = (action, bool, dispatch, winnerId) => {
  switch (action) {
    case 'revertUniqWinner':
      dispatch(pendingRevertUniqWinner(bool, winnerId));
      break;
    case 'validateIban':
      dispatch(pendingValidateIban(bool));
      break;
    case 'validatePaypal':
      dispatch(pendingValidatePaypal(bool));
      break;
    case 'validateUniqIban':
      dispatch(pendingValidateUniqIban(bool, winnerId));
      break;
    case 'validateUniqPaypal':
      dispatch(pendingValidateUniqPaypal(bool, winnerId));
      break;
    default:
      break;
  }
};

export const validateWinners = (action, file, winnerId) => dispatch => {
  buttonSpinner(action, true, dispatch, winnerId);
  if (file) {
    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onload = async ({ target: { result: content } }) => {
      return axios({
        method: 'PUT',
        url: `${config.cloudHttps}httpsAdminWinnersValidate`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          idToken: localStorage.getItem('firebaseToken')
        },
        data: {
          action,
          content
        }
      })
        .then(res => {
          if (res.status !== 200) alert('Une erreur est survenue');
          buttonSpinner(action, false, dispatch);
          return window.location.reload();
        })
        .catch(err => {
          alert(err);
          console.log(err);
          buttonSpinner(action, false, dispatch);
          window.location.reload();
        });
    };
  } else {
    return axios({
      method: 'PUT',
      url: `${config.cloudHttps}httpsAdminWinnersValidate`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        idToken: localStorage.getItem('firebaseToken')
      },
      data: {
        action,
        winnerId
      }
    })
      .then(res => {
        if (res.status !== 200) alert('Une erreur est survenue');
        return buttonSpinner(action, false, dispatch, winnerId);
      })
      .catch(err => {
        alert(err);
        console.log(err);
        return buttonSpinner(action, false, dispatch, winnerId);
      });
  }
};
