import React from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';

import InfoCard from '../infoCard';
import WaitAndGo from '../waitAndGo';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InfoCardContainer = styled.div`
  display: flex;
`;

const RowHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 12px;
`;

export default class controlsHeader extends React.PureComponent {
  render() {
    const { actions, currentQuestion, isBroadcasting, nbPlayers, nbViewers, quizName, id } = this.props;
    return (
      <HeaderContainer>
        <RowHeader>
          {isBroadcasting ? (
            <Button color="secondary" onClick={() => actions.controls.stopLive(currentQuestion.id)} variant="contained">
              Stopper le Live
            </Button>
          ) : (
            <Button color="secondary" onClick={() => actions.controls.startLive(currentQuestion.id)} variant="contained">
              Démarrer le Live
            </Button>
          )}
            <Button color="secondary" onClick={() => actions.controls.endLive(currentQuestion.id)} variant="contained">
              Finir le Live
            </Button>
          <div>
            <Button color="primary" onClick={() => actions.controls.resetQuiz(currentQuestion.id)} variant="outlined">
              Réinitialiser le Quiz
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => window.open(`/home/quizzes/edition/${id}/0`)}>
              Modifier
            </Button>
          </div>
        </RowHeader>
        <RowHeader>
          <InfoCardContainer>
          <InfoCard content={quizName} />
            <InfoCard content={nbViewers || '0'} title={nbViewers > 1 ? 'Spectateurs' : 'Spectateur'} />
            <InfoCard content={nbPlayers || '0'} title={nbPlayers > 1 ? 'Joueurs' : 'Joueur'} />
          </InfoCardContainer>
          <WaitAndGo isQuestionEnded={currentQuestion.answersProcessed} />
        </RowHeader>
      </HeaderContainer>
    );
  }
}
