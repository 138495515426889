import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import allTheActions from '../actions';
import { UsersTable } from '../components/usersTable';

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      length: 0,
      limit: 10,
      page: 0,
      searchTerm: null
    };
  }

  clearLink = async () => {
    const {
      actions: { users }
    } = this.props;
    await users.usersClearLink();
  }

  componentDidMount = async () => {
    await this.getUsers();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { link } = nextProps;
    if (link) {
      window.open(link, '_blank');
      this.clearLink();
    }
  }

  downloadUsers = async searchText => {
    const {
      actions: { users }
    } = this.props;
    await users.downloadUsers(searchText);
  };

  getUsers = async searchText => {
    const {
      actions: { users }
    } = this.props;
    const { limit, page } = this.state;
    users.getUsers(searchText, page, limit);
  };

  handleChangeLimit = async limit => {
    const { searchTerm, page } = this.state;
    await this.setState({ limit, page: 0 });
    await this.getUsers(searchTerm, page, limit);
  };

  handleChangePage = async page => {
    const { searchTerm } = this.state;
    await this.setState({ page });
    await this.getUsers(searchTerm, page);
  };

  handleChangeSearchTerm = async searchTerm => {
    await this.setState({ page: 0, searchTerm });
    if (searchTerm) {
      await this.getUsers(searchTerm);
    } else {
      await this.getUsers();
    }
  };

  render() {
    const { limit, page, searchTerm } = this.state;
    const { length, isLoading, users, pendingDownload } = this.props;
    return (
      <UsersTable
        downloadUsers={this.downloadUsers}
        handleChangePage={this.handleChangePage}
        handleChangeLimit={this.handleChangeLimit}
        handleChangeSearchTerm={this.handleChangeSearchTerm}
        isLoading={isLoading}
        length={length}
        limit={limit}
        page={page}
        pendingDownload={pendingDownload}
        searchTerm={searchTerm}
        users={users}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users.users,
    length: state.users.length,
    link: state.users.link,
    isLoading: state.users.isLoading,
    pendingDownload: state.users.pendingDownload
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    users: bindActionCreators(allTheActions.users, dispatch)
  }
});

UserList = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserList);

export default UserList;
