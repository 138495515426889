import React, { Component } from "react";
import { Route, Switch } from "react-router";

import UserList from "./userList";
import UserDetails from "./userDetails";

class UsersScreen extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/home/users" component={UserList} />
          <Route path="/home/users/:id" component={UserDetails} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default UsersScreen;
