import React from "react";
import styled from "styled-components";

import { CommonText } from "../texts";

const Go = styled.div`
  background-color: ${props =>
    props.go ? props.theme.color.go : props.theme.color.inactive};
  align-items: center;
  border-radius: 50px;
  display: flex;
  height: 90px;
  justify-content: center;
  margin: 5px;
  width: 90px;
`;
const Wait = styled.div`
  align-items: center;
  background-color: ${props =>
    props.wait ? props.theme.color.wait : props.theme.color.inactive};
  border-radius: 50px;
  display: flex;
  height: 90px;
  justify-content: center;
  margin: 5px;
  width: 90px;
`;

const WaitAndGoContainer = styled.div`
  display: flex;
`;

export default class WaitAndGo extends React.Component {
  render() {
    const { isQuestionEnded } = this.props;
    return (
      <WaitAndGoContainer className={this.props.className}>
        <Wait wait={!isQuestionEnded}>
          <CommonText> En attente </CommonText>
        </Wait>
        <Go go={isQuestionEnded}>
          <CommonText> En cours </CommonText>
        </Go>
      </WaitAndGoContainer>
    );
  }
}
