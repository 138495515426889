import styled from 'styled-components'

const Title = styled.h2`
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '40px')}
  color: ${props =>
    props.color ? props.theme.color[props.color] : props.theme.color.black};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
`
const SubTitle = styled.span`
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '16px')}
  color: ${props =>
    props.color
      ? props.theme.color[props.color]
      : props.theme.color.secondaryColor};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
`
const CommonText = styled.span`
font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '12px')}
  color: ${props =>
    props.color ? props.theme.color[props.color] : props.theme.color.black};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
`

export { CommonText, Title, SubTitle }
