import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

const InputAndErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledErrorWarning = styled.span`
  color: red;
  padding: 5px 0px 5px 0px;
`;

const AllContainer = styled.div`
  margin: 10px 0px 10px 0px;
  width: 100%;
`;

const InputText = ({
  disabled,
  helperText,
  input,
  inputProps,
  inputRef,
  label,
  multiLine,
  placeholder,
  rows,
  type,
  value,
  style,
  dir,
  meta: { touched, error, warning }
}) => {
  return (
    <AllContainer>
      <InputAndErrorContainer>
        <TextField
          {...input}
          {...inputProps}
          autoComplete="off"
          disabled={disabled}
          helperText={helperText}
          inputProps={{ ...input }}
          inputRef={inputRef}
          label={type === "number" ? "" : label}
          multiline={multiLine}
          placeholder={placeholder}
          rows={rows}
          type={type}
          value={value}
          style={style}
          dir={dir}
        />
        {touched &&
          ((error && <StyledErrorWarning>{error}</StyledErrorWarning>) ||
            (warning && <StyledErrorWarning>{warning}</StyledErrorWarning>))}
      </InputAndErrorContainer>
    </AllContainer>
  );
};

InputText.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  type: PropTypes.string
};

export default InputText;
