import React from "react";
import { Field } from "redux-form";
import inputText from './inputText';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`;

const FieldArrayItem = styled.div`
  display: flex;
  justify-content: space-around;
`;

const CancelButton = styled.div`
  background-color: red;
  color: white;
  align-self: center;
  text-align: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  
`;

export const InputArray = ({ fields }) => (
  <Container>
    {fields.map((code, index) => (
      // eslint-disable-next-line
      <FieldArrayItem key={index}>
        <Field
          name={code}
          type="text"
          component={inputText}
          placeholder={`Phrase #${index + 1}`}
          autoFocus
        />
        <CancelButton type="button" onClick={() => fields.remove(index)} onMouseEnter={() => document.body.style.cursor = "pointer"} onMouseLeave={() => document.body.style.cursor = "default"}>
          &times;
        </CancelButton>
      </FieldArrayItem>
    ))}
    <Button onClick={() => fields.push()} onMouseEnter={() => document.body.style.cursor = "pointer"} onMouseLeave={() => document.body.style.cursor = "default"}>
      Ajoutez un texte pour l'écran looser
    </Button>
  </Container>
);