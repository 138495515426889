import axios from "axios";
import {config} from "../config/firebase";
import { pending } from "./winners";

export const DISPLAY_SEASONS = 'DISPLAY_SEASONS';

export const displaySeasons = seasons => ({
  type: DISPLAY_SEASONS,
  payload: seasons
});

export const getSeasons = () => async dispatch => {
  dispatch(pending(true));
  try {
    const res = await axios({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        idToken: localStorage.getItem('firebaseToken')
      },
      method: 'GET',
      url: `${config.userServer}seasons`,
    });
    console.log('RES SEASON:', res.data);
    dispatch(displaySeasons(res.data));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(pending(false));
  }
};
