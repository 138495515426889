import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export default function SurveysTableHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Type</TableCell>
        <TableCell>Gain</TableCell>
        <TableCell>Type de gain</TableCell>
        <TableCell/>
        <TableCell>Question</TableCell>
        <TableCell>A.</TableCell>
        <TableCell>B.</TableCell>
        <TableCell>C.</TableCell>
        <TableCell>D.</TableCell>
        <TableCell/>
      </TableRow>
    </TableHead>
  );
}
