import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import React from 'react';
import Moment from 'react-moment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

import allTheActions from '../actions';
import MessagesDisplayer from '../components/messagesDisplayer';
import MessagesHeader from '../components/messagesHeader';

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 100%;
`;

const MessagesHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 32px;
  width: 100%;
  min-width: 960px;
`;

const MessagesHeaderOptions = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

const ButtonText = styled.span``;

class MessagesHistoryDetails extends React.Component {
  state = {
    pause: false,
    open: false,
    messages: []
  };

  _fetchMessages = quizId => {
    const { lastCreatedAt } = this.props;
    const { getMessagesHistory } = this.props.actions.messages;
    if (quizId) {
      getMessagesHistory({
        quizId,
        afterDate: lastCreatedAt
      });
    }
  };

  _changeScrollSpeed = plusOperator => {
    const { actions, scrollSpeed } = this.props;
    let newScrollSpeed = plusOperator ? scrollSpeed - 1 : scrollSpeed + 1;
    if (plusOperator && newScrollSpeed < 1) {
      newScrollSpeed = 1;
    }
    if (!plusOperator && newScrollSpeed > 30) {
      newScrollSpeed = 30;
    }
    actions.messages.updateScrollSpeed(newScrollSpeed);
  };

  _changeFontSize = plusOperator => {
    const { actions, fontSize } = this.props;
    const newFontSize = plusOperator
      ? fontSize + 1
      : !plusOperator && fontSize > 10
        ? fontSize - 1
        : fontSize;
    actions.messages.updateFontSize(newFontSize);
  };

  _pauseMessages = () => {
    const { pause } = this.state;
    this.setState({ pause: !pause });
  };

  _handleChange = async value => {
    const {
      actions: { messages }
    } = this.props;
    await messages.clearMessagesHistory();
    await messages.changeQuiz({ quizId: value });
    await this.props.history.push(`/home/messagesHistory/${value}`);
    await this._fetchMessages(value);
  };

  _handleClose = () => {
    this.setState({ open: false });
  };

  _handleOpen = () => {
    this.setState({ open: true });
  };

  _getQuizId = () => {
    const match = matchPath(this.props.history.location.pathname, {
      path: '/home/messagesHistory/:quizId',
      exact: true,
      strict: false
    });
    if (match && match.params) {
      return match.params.quizId;
    }
    return null;
  };

  _muteUser = async (userId) => {
    const { actions } = this.props;
    actions.users.deleteUser('mute', userId);
  };

  clearLink = async () => {
    const {
      actions: { messages }
    } = this.props;
    await messages.messagesClearLink();
  };

  componentDidMount() {
    const { quizzes } = this.props.actions;
    quizzes.getQuizzes(0, 50);
    const {
      actions: { messages }
    } = this.props;
    const quizId = this._getQuizId();
    messages.clearMessagesHistory();
    messages.changeQuiz({ quizId });
    this._fetchMessages(quizId);
  }

  componentDidUpdate(nextProps) {
    const { link } = nextProps;
    if (link) {
      window.open(link, '_blank');
      this.clearLink();
    }
    const {
      actions: { messages }
    } = this.props;
    const quizId = this._getQuizId();
    if (quizId !== nextProps.quizId) {
      messages.clearMessagesHistory();
      messages.changeQuiz({ quizId });
      this._fetchMessages(quizId);
    }
  }

  componentWillUnmount() {
    const { messages } = this.props.actions;
    messages.clearMessagesHistory();
    messages.changeQuiz({ quizId: null });
  }

  render() {
    const {
      actions,
      fontSize,
      isDownloading,
      isLoading,
      isMuting,
      messagesHistory,
      quizId,
      quizzes,
      scrollSpeed
    } = this.props;
    const { open } = this.state;
    return (
      <MessagesContainer>
        <MessagesHeaderContainer>
          <MessagesHeaderOptions>
            <Select
              open={open}
              onClose={this._handleClose}
              onOpen={this._handleOpen}
              value={quizId || ''}
              onChange={event => this._handleChange(event.target.value)}
              inputProps={{
                name: 'quizId',
                id: 'demo-controlled-open-select'
              }}
            >
              {quizzes &&
              quizzes.length &&
              quizzes.map(
                quiz =>
                  quiz.isEnded && (
                    <MenuItem key={quiz.id} value={quiz.id}>
                      <Moment
                        locale="fr"
                        date={quiz.startAt}
                        format="dddd, D MMMM YYYY, HH:MM:SS"
                      />{' '}
                      - {quiz.name}
                    </MenuItem>
                  )
              )}
            </Select>
            <StyledButton
              disabled={isLoading || isDownloading || !quizId}
              color="primary"
              variant="contained"
              onClick={() => actions.messages.downloadMessages(quizId)}
            >
              {isDownloading ? (
                <CircularProgress size={24} />
              ) : (
                <ButtonText>Télécharger les messages</ButtonText>
              )}
            </StyledButton>
          </MessagesHeaderOptions>
          <MessagesHeader
            numberOfMessages={messagesHistory.length}
            mode={'readonly'}
          />
        </MessagesHeaderContainer>
        <MessagesDisplayer
          changeFontSize={this._changeFontSize}
          changeScrollSpeed={this._changeScrollSpeed}
          fontSize={fontSize}
          isLoading={isLoading}
          isMuting={isMuting}
          messages={messagesHistory}
          mode={'readonly'}
          muteUser={this._muteUser}
          pause={true}
          pauseMessages={this._pauseMessages}
          scrollSpeed={scrollSpeed}
        />
      </MessagesContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    fontSize: state.messages.fontSize,
    initialValues: state.messages.messagesHistory,
    isDownloading: state.messages.isDownloading,
    isLoading: state.messages.isLoading,
    isMuting: state.users.isLoading,
    lastCreatedAt: state.messages.lastCreatedAt,
    link: state.messages.link,
    messageIdMuting: state.messages.messageIdMuting,
    messagesHistory: state.messages.messagesHistory,
    quizId: state.messages.quizId,
    quizzes: state.quizzes.data
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    messages: bindActionCreators(allTheActions.messages, dispatch),
    quizzes: bindActionCreators(allTheActions.quizzes, dispatch),
    users: bindActionCreators(allTheActions.users, dispatch)
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesHistoryDetails);
