import React from "react";
import styled, { keyframes } from "styled-components";

const AnimatedCircleContainer = styled.div`
  background-color: ${props =>
    props.backgroundColor ? props.theme.color.mainColor : "transparent"}
  display: inline-block;
  height: 128px;
  position: relative;
  width: 128px;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const AnimatedCircle = styled.div`
  animation: ${rotate360} 0.7s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 6px solid
    ${props =>
      props.color ? props.theme.color[props.color] : props.theme.color.white};
  border-color: ${props =>
      props.color ? props.theme.color[props.color] : props.theme.color.white}
    transparent transparent transparent;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 101px;
  margin: 6px;
  position: absolute;
  width: 101px;
`;

const AnimatedCircleChildOne = styled.div`
  animation-delay: -0.45s;
`;

const AnimatedCircleChildTwo = styled.div`
  animation-delay: -0.3s;
`;

const AnimatedCircleChildThree = styled.div`
  animation-delay: -0.15s;
`;

const CircleAnimation = props => (
  <AnimatedCircleContainer backgroundColor={props.backgroundColor}>
    <AnimatedCircle color={props.color} />
    <AnimatedCircleChildOne />
    <AnimatedCircleChildTwo />
    <AnimatedCircleChildThree />
  </AnimatedCircleContainer>
);

export default CircleAnimation;
