import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router';

import Display from './Display';
import Table from './Table';

export default class goQuizzes extends Component {
  render() {
    return (
      <Fragment>
        <Switch>
          <Route exact path="/home/goQuizzes" component={Table} />
          <Route path="/home/goQuizzes/:id" component={Display} />
        </Switch>
      </Fragment>
    );
  }
}
