import axios from 'axios';
import { storageServer } from '../config';

class GoLiveMusic {
  getPlaylist = () => {
    return axios ({
      method: 'GET',
      url: `${storageServer}getAllFile`,
    });
  }

  deleteFile = (url, name) => {
    return axios ({
      method: 'PUT',
      url: `${storageServer}deleteFile`,
      data: {
        path: 'backOfficeUploads/goliveMusic',
        name: name,
        url: url,
      },
    });
  }

  deleteAllFiles = () => {
    return axios ({
      method: 'POST',
      url: `${storageServer}deleteAllFile`,
    });
  }
}

const quidolGoMusic = new GoLiveMusic();
export default quidolGoMusic;
