import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import controls from './controls';
import messages from './messages';
import presenter from './presenter';
import quizzes from './quizzes';
import surveys from './surveys';
import modal from './modal';
import users from './users';
import upload from './upload';
import winners from './winners';
import seasons from './seasons';
import quidolGo from './quidolGo';
import admob from './adMob';
import achievements from './achievements';
import cards from './cards';

export default combineReducers({
  controls,
  form: formReducer, // the key have to be 'form' according to the redux-form doc
  messages,
  presenter,
  quizzes,
  surveys,
  modal,
  users,
  upload,
  winners,
  quidolGo,
  admob,
  achievements,
  cards,
  seasons,
});
