import { Field } from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import React from 'react';
import styled from 'styled-components';

import {
  InputText,
  renderDateTimePicker,
  renderRadioGroup,
  renderSwitch,
} from '../reduxFormsComponents';
import { CommonText } from '../texts';
import { config } from '../../config/firebase';

const QuizPanelForm = styled.div`
  align-items: center;
  background-color: ${props => props.theme.color.mainFormColor};
  display: flex;
  flex-direction: column;
  min-width: 300px;
  padding: 5px;
`;

const InputAndLabelContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const RadioContainer = styled.div`
  display: flex;
`;

export default class PanelFormFields extends React.Component {
  render() {
    const { handleNumberQuestion } = this.props;
    return (
      <QuizPanelForm>
        <Field component={InputText} disabled helperText="ID" name="id" type="string" />
        <Field component={renderDateTimePicker} label="Date de départ" name="startAt" />
        <Field component={InputText} helperText="Titre" name="name" type="string" />
        <Field component={InputText} helperText="Nom du programme" name="programName" type="string" />
        <Field component={InputText} helperText="Thème du live - Champs utile pour créer des achievements spéciaux (ex: Licorne, Récré, Spielberg, etc...)" name="theme" type="string" />
        <Field component={InputText} helperText="Logo du partenaire" name="partnerLogo" type="string" />
        <RadioContainer>
          <InputAndLabelContainer>
            <Field component={renderSwitch} name="isBroadcasting" />
            <CommonText>Diffusé actuellement</CommonText>
          </InputAndLabelContainer>
          <InputAndLabelContainer>
            <Field component={renderSwitch} name="isStarted" />
            <CommonText>Démarré</CommonText>
          </InputAndLabelContainer>
          <InputAndLabelContainer>
            <Field component={renderSwitch} name="isEnded" />
            <CommonText>Terminé</CommonText>
          </InputAndLabelContainer>
        </RadioContainer>
        <Field
          component={InputText}
          helperText="Nombre de questions"
          name="nbQuestions"
          onChange={handleNumberQuestion}
          type="number"
        />
        <Field
          component={InputText}
          helperText="Nombre de jokers maximum"
          name="maxJokersNext"
          type="number"
        />
        {config.inAppBeforeQuiz ? (
          <div>
            <Field component={renderDateTimePicker} label="inAppStartAt" name="inAppStartAt" />
            <Field component={renderDateTimePicker} label="inAppEndAt" name="inAppEndAt" />
          </div>
        ) : (
          <div />
        )}
        <Field
          component={InputText}
          helperText="Délai de synchronisation"
          name="resyncDelay"
          type="number"
        />
        <Field
          component={InputText}
          helperText="Temps d'affichage des réponses"
          name="delayHideAnswer"
          type="number"
        />
        <Field component={renderRadioGroup} name="prizeType" onChange={this.handleChangePrizeType}>
          <FormControlLabel control={<Radio color="primary" />} label="argent" value="money" />
          <FormControlLabel control={<Radio color="primary" />} label="jokers" value="jokers" />
          <FormControlLabel control={<Radio color="primary" />} label="rien" value="nothing" />
        </Field>
        <Field component={InputText} helperText="Somme à partager" name="prizepool" type="number" />
        <Field
          component={InputText}
          disabled
          helperText="Nombre de gagnants"
          name="winnersCount"
          type="text"
        />
        <Field
          component={InputText}
          disabled
          helperText="Nombre de jokers utilisés"
          name="jokersNextCount"
          type="text"
        />
        <Field
          component={InputText}
          disabled
          helperText="Gain par gagnant"
          name="gainPerWinner"
          type="text"
        />
        <Field
          component={InputText}
          helperText="Joueurs autorisés (séparateur ,)"
          name="player"
          placeholder="*"
          type="string"
        />
      </QuizPanelForm>
    );
  }
}
