import { Field } from 'redux-form';
import React from 'react';
import styled from 'styled-components';

import { InputText } from '../reduxFormsComponents';

const QuestionsQuiz = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionStyled = styled.option`
  outline: none;
  padding: 5px;
  text-align: center;
`;

class FreeQuestionsField extends React.Component {
  render() {
    const { handleResponseChange, quizzes } = this.props;
    return (
      <QuestionsQuiz>
        <Field
          component={InputText}
          disabled={quizzes.isLoading}
          helperText="Phrase de réponse"
          name="freeAnswer"
          placeholder="Inserez la phrase de réponse"
          type="text"
        />
        <Field
          component={InputText}
          disabled={quizzes.isLoading}
          helperText="Bonne réponse"
          name="freeResponse"
          onChange={handleResponseChange}
          placeholder="Inserez la bonne réponse"
          type="text"
        />
        <Field component="select" defaultValue="mcq" name="freeForm">
          <OptionStyled value="string">Texte</OptionStyled>
          <OptionStyled value="number">Nombre</OptionStyled>
        </Field>
        <Field
          component={InputText}
          disabled
          helperText="Nombre de caractères"
          name="freeNbChar"
          type="number"
        />
      </QuestionsQuiz>
    );
  }
}

export default FreeQuestionsField;
