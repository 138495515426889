import { Redirect } from 'react-router';
import React from 'react';
import styled from 'styled-components';

import { CircleAnimation } from '../components/animation';
import LoginForm from '../components/loginForm';
import { history } from '../config/store';

import { getToken, refreshUserToken } from '../utils/adminTokens';
import errorHandler from '../components/loginForm/errorHandler';

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const FormContainer = styled.div`
  align-items: center;
  background-color: ${props => props.theme.color.mainColor};
  display: flex;
  flex: 1;
  height: 100vh;
  justify-content: center;
  width: 100%;
`;

class Login extends React.Component {
  state = {
    loading: false,
    redirectToRefferer: false
  };

  componentDidMount() {
    if (localStorage.getItem('refreshToken')) {
      refreshUserToken();
      this.setState({ redirectToRefferer: true, loading: false });
      history.push('/home');
    }
  }

  submit = values => {
    this.setState({ loading: true });
    const { email, password } = values;
    return getToken(email, password)
      .then(res => {
        if (res && res.idToken) {
          this.setState({ redirectToRefferer: true, loading: false });
          localStorage.setItem('firebaseToken', res.idToken);
          localStorage.setItem('refreshToken', res.refreshToken);
          return history.push('/home');
        }
        return this.setState({ loading: false });
      })
      .catch(errors => {
        console.log(errors);
        this.setState({ loading: false });
        return errorHandler(errors);
      });
  };

  render() {
    const { redirectToRefferer } = this.state;
    // if the user is already logged, he is redirect to the dashboard
    if (redirectToRefferer) {
      return <Redirect to="/home" />;
    }

    return (
      <React.Fragment>
        <MainContainer>
          <FormContainer>
            {this.state.loading ? (
              <CircleAnimation />
            ) : (
              <LoginForm onSubmit={this.submit} />
            )}
          </FormContainer>
        </MainContainer>
      </React.Fragment>
    );
  }
}

export default Login;
