import { Redirect, Route, Switch } from 'react-router';
import React, { Component } from 'react';
import styled from 'styled-components';

import { config } from '../config/firebase';
import BurgerMenu from '../components/burgerMenu';
import Controls from './controls';
import Header from '../components/header';
import Presenter from './presenter';
import Quizzes from './quizzes';
import Users from './users';
import Winners from './winners';
import GoQuizzes from './goQuizzes';
import Answers from './answer/answer';
import Surveys from './surveys';
import Replays from './replays';
import Wording from './wording';
import Admin from './admin';
import Messages from './messages';
import MessagesHistory from './messagesHistory';


const ContentContainer = styled.div`
  display: flex;
  width: 100%;
`;

const MenuAndContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

class HomePage extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <MenuAndContentContainer>
          <BurgerMenu />
          <ContentContainer>
            <Switch>
              <Route path="/home/users" component={Users} />
              <Route path="/home/winners" component={Winners} />
              <Route path="/home/quizzes" component={Quizzes} />
              <Route path="/home/presenter" component={Presenter} />
              <Route exact path="/home/controls/:id" component={Controls} />
              <Route path="/home/controls/" component={Controls} />
              {config.enableAnswers ? <Route path="/home/answers" component={Answers} /> : null}
              {config.fileUpload.enable ? <Route path="/home/goQuizzes" component={GoQuizzes} /> : null}
              <Route path="/home/admin" component={Admin} />
              <Route path="/home/answer" component={Answers} />
              <Route path="/home/surveys" component={Surveys} />
              <Route path="/home/wording" component={Wording} />
              <Route path="/home/replays" component={Replays} />
              {config.enableMessages ? <Route path="/home/messages" component={Messages} /> : null}
              {config.enableMessageHistory ? <Route path="/home/messagesHistory" component={MessagesHistory} /> : null}
              <Redirect to="/home/quizzes" />
            </Switch>
          </ContentContainer>
        </MenuAndContentContainer>
      </React.Fragment>
    );
  }
}

export default HomePage;
