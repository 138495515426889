import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { config } from '../../config/firebase';

const DisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DisplayContainerLine = styled.div`
  display: flex;
  justify-content: space-between; 
  margin-bottom: 10px;
  width: 410px;
`;

const QuestionControlContainer = styled.div``;

const ButtonStyled = styled(Button)`
  && {
    background-color: ${props => props.color};
    width: 200px;
  }
`;

export default class ControlsActionsLine extends React.Component {
  _getRandomViewer = () => {
    const { getRandomViewer } = this.props;
    getRandomViewer();
  };

  render() {
    const {
      actions,
      answerHideDelay,
      currentQuestion,
      isBroadcasting,
      questionDuration,
      questionId,
      videoChunkTime
    } = this.props;

    const responseButtonProdDisabled = !isBroadcasting || !currentQuestion.isExpired || !!currentQuestion.isAnswerDisplayed;
    const responseButtonDevDisabled = !isBroadcasting || !currentQuestion.display;
    const responseButtonDisabled = config.dev ? responseButtonDevDisabled : responseButtonProdDisabled;
    return (
      <QuestionControlContainer>
        <DisplayContainer>
          <DisplayContainerLine>
          {/* AFFICHER QUESTION */}
            <ButtonStyled
              color="primary"
              disabled={!isBroadcasting || !!currentQuestion.display}
              onClick={() => {
                actions.controls.displayQuestion(questionId, videoChunkTime, questionDuration);
                actions.controls.logsChunkTime({
                  date: new Date().toISOString(),
                  type: 'set',
                  chunkTime: videoChunkTime.chunkTime,
                });
              }}
              variant="contained"
            >
              AFFICHER QUESTION
            </ButtonStyled>
            {/* AFFICHER REPONSE */}
            <ButtonStyled
              color="secondary"
              disabled={responseButtonDisabled}
              onClick={() => {
                actions.controls.showAnswer(questionId, answerHideDelay);
              }}
              variant="contained"
            >
              AFFICHER REPONSE
            </ButtonStyled>
          </DisplayContainerLine>
          <DisplayContainerLine>
            <ButtonStyled
              color="#00bfff"
              onClick={() => {
                actions.controls.showQuestionTV(currentQuestion);
              }}
              variant="contained"
            >
              AFFICHER QUESTION TV
            </ButtonStyled>
            <ButtonStyled
              color="#ff0040"
              onClick={() => {
                actions.controls.showAnswerTV(currentQuestion);
              }}
              variant="contained"
            >
              AFFICHER REPONSE TV
            </ButtonStyled>
          </DisplayContainerLine>
        </DisplayContainer>
      </QuestionControlContainer>
    );
  }
}
