import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';
import styled from 'styled-components';

import allTheActions from '../actions';
import MessagesDisplayer from '../components/messagesDisplayer';
import MessagesHeader from '../components/messagesHeader';

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 100%;
`;

class Messages extends React.Component {
  state = {
    pause: false,
    open: false,
    messages: []
  };

  _fetchMessages = () => {
    const { lastCreatedAt, quizId } = this.props;
    const { getMessages } = this.props.actions.messages;
    if (quizId) getMessages({ quizId, afterDate: lastCreatedAt });
  };

  _fetchMessagesInterval;

  _fetchMessagesWithInterval = () => {
    const { fetchInterval } = this.props;
    this._clearAllInterval();
    this._fetchMessagesInterval = setInterval(
      () => this._fetchMessages(),
      fetchInterval
    );
  };

  _clearAllInterval = () => {
    if (this._fetchMessagesInterval) clearInterval(this._fetchMessagesInterval);
  };

  _changeScrollSpeed = plusOperator => {
    const { actions, scrollSpeed } = this.props;
    let newScrollSpeed = plusOperator ? scrollSpeed - 1 : scrollSpeed + 1;
    if (plusOperator && newScrollSpeed < 1) {
      newScrollSpeed = 1;
    }
    if (!plusOperator && newScrollSpeed > 30) {
      newScrollSpeed = 30;
    }
    actions.messages.updateScrollSpeed(newScrollSpeed);
  };

  _changeFontSize = plusOperator => {
    const { actions, fontSize } = this.props;
    const newFontSize = plusOperator
      ? fontSize + 1
      : !plusOperator && fontSize > 10
        ? fontSize - 1
        : fontSize;
    actions.messages.updateFontSize(newFontSize);
  };

  _muteUser = async userId => {
    const { actions } = this.props;
    actions.users.deleteUser('mute', userId);
  };

  _pauseMessages = () => {
    const { pause } = this.state;
    this.setState({ pause: !pause });
  };

  _handleClose = () => {
    this.setState({ open: false });
  };

  _handleOpen = () => {
    this.setState({ open: true });
  };

  changeEventListener = e => {
    let intercept = false;
    if (e.code === 'ArrowRight') {
      this._changeScrollSpeed(true);
      intercept = true;
    } else if (e.code === 'ArrowLeft') {
      this._changeScrollSpeed(false);
      intercept = true;
    } else if (e.code === 'Space') {
      this._pauseMessages();
      intercept = true;
    } else if (e.code === 'Digit1') {
      this._changeFontSize(false);
      intercept = true;
    } else if (e.code === 'Digit2') {
      this._changeFontSize(true);
      intercept = true;
    }
    if (intercept) {
      if (e.preventDefault) {
        e.preventDefault();
      } else {
        e.returnValue = false;
      }
      e.stopImmediatePropagation();
    }
  };

  componentDidMount() {
    const {
      messages: { getQuizId, clearMessages },
      quizzes: { getQuizzes }
    } = this.props.actions;
    clearMessages();
    getQuizId();
    getQuizzes();
    this._fetchMessages();
    this._fetchMessagesWithInterval();
    window.addEventListener('keydown', this.changeEventListener);
  }

  componentWillUnmount() {
    const { messages } = this.props.actions;
    this._clearAllInterval();
    messages.clearMessages();
    window.removeEventListener('keydown', this.changeEventListener);
  }

  render() {
    const { scrollSpeed, fontSize, isMuting, messages } = this.props;
    const { pause } = this.state;
    return (
      <MessagesContainer>
        <MessagesHeader numberOfMessages={messages.length} />
        <MessagesDisplayer
          changeFontSize={this._changeFontSize}
          changeScrollSpeed={this._changeScrollSpeed}
          fontSize={fontSize}
          isMuting={isMuting}
          messages={messages}
          muteUser={this._muteUser}
          pause={pause}
          pauseMessages={this._pauseMessages}
          scrollSpeed={scrollSpeed}
        />
      </MessagesContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    isMuting: state.users.isLoading,
    messages: state.messages.messages,
    quizId: state.messages.quizId,
    quizName: state.messages.quizName,
    fetchInterval: state.messages.fetchInterval,
    scrollSpeed: state.messages.scrollSpeed,
    lastCreatedAt: state.messages.lastCreatedAt,
    fontSize: state.messages.fontSize,
    quizzes: state.quizzes.data,
    user: state.users.user
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    messages: bindActionCreators(allTheActions.messages, dispatch),
    quizzes: bindActionCreators(allTheActions.quizzes, dispatch),
    users: bindActionCreators(allTheActions.users, dispatch)
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Messages);
