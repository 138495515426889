import {
  PRESENTER_CURRENT_QUIZ,
  UPDATE_QUESTION_STATES,
  PRESENTER_CURRENT_QUESTION,
  PRESENTER_NB_PLAYERS,
  PRESENTER_NB_VIEWERS,
} from '../actions/presenter';

const initialState = {
  nbPlayers: 0,
  nbViewers: 0,
  currentQuestion: {},
  currentQuiz: {},
  questionStates: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PRESENTER_NB_VIEWERS: {
      return {
        ...state,
        nbViewers: action.payload,
      };
    }
    case PRESENTER_NB_PLAYERS: {
      return {
        ...state,
        nbPlayers: action.payload,
      };
    }
    case UPDATE_QUESTION_STATES: {
      return {
        ...state,
        questionStates: { ...state.questionStates, [action.questionId]: action.state },
      };
    }
    case PRESENTER_CURRENT_QUESTION: {
      return {
        ...state,
        currentQuestion: action.payload,
      };
    }
    case PRESENTER_CURRENT_QUIZ: {
      return {
        ...state,
        currentQuiz: action.payload,
      };
    }
    default:
      return state;
  }
};
