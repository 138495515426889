import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import React from 'react';
import styled from 'styled-components';

import { CommonText } from '../components/texts';
import { InputText, renderRadioGroup, renderDateTimePicker } from '../components/reduxFormsComponents';
import LoadingBar from '../components/loadingBar';

import allTheActions from '../actions';
import validate from '../components/quizCreateForm/validate';

const StyledForm = styled.form`
  background-color: ${props => props.theme.color.mainFormColor};
  padding: 20px;
  min-width: 500px;
`;

const StyledButton = styled.button`
  background-color: ${props => props.theme.color.white};
  border: none;
  cursor: pointer;
  font-size: 16px;
  height: 30px;
  margin-top: 10px;
  width: 100%;
`;

const Header = styled.div`
  background-color: ${props => props.theme.color.white};
  display: flex;
  height: 40px;
  justify-content: center;
`;

const Title = styled(CommonText)`
  padding: 10px;
`;

const AllContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const QuizCreateContainer = styled.div`
  background-color: ${props => props.theme.color.white};
  border-radius: 5px;
  box-shadow: 0px 1px 2px black;
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
`;

class CreateQuizForm extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    isCustomQuiz: PropTypes.bool,
  };

  state = {
    goLive: false,
  };

  componentDidMount() {
    // Get The initial Object set in mapstateToProps and initialise the form
    this.props.initialize(this.props.initialValues);
  }

  onSubmit = values => {
    const { actions, isCustomQuiz } = this.props;
    if (isCustomQuiz) {
      actions.quizzes.postCustomQuiz(values);
    } else {
      actions.quizzes.postQuiz(values);
    }
  };

  handleChangePrizeType = event => {
    if (event.target.value === 'nothing') {
      this.props.change('prizepool', 0);
    }
  };

  render() {
    const { handleSubmit, pristine, quizzes, submitting } = this.props;
    return (
      <AllContainer>
        <QuizCreateContainer>
          <LoadingBar isLoading={quizzes.isLoading} />
          <Header>
            <Title fontSize={18}>CRÉER UN NOUVEAU QUIZ</Title>
          </Header>
          {/*Handle submit is a method provided by redux form that allows us to get the values*/}
          <StyledForm onSubmit={handleSubmit(values => this.onSubmit(values))}>
            <Field
              component={renderDateTimePicker}
              label="Démarre à"
              name="startAt"
            />
            {/*Nom du quiz*/}
            <Field
              component={InputText}
              helperText="Nom du quiz"
              name="name"
              type="string" />
            {/*Nom de l'émission*/}
            <Field
              component={InputText}
              helperText="Nom du programme"
              name="programName"
              type="string"
            />
            {/*PrizeType*/}
            <Field component={renderRadioGroup} name="prizeType" onChange={this.handleChangePrizeType}>
              <FormControlLabel control={<Radio color="primary" />} label="Argent" value="money" />
              <FormControlLabel control={<Radio color="primary" />} label="Jokers" value="jokers" />
              <FormControlLabel control={<Radio color="primary" />} label="Rien" value="nothing" />
            </Field>
            {/*PrizePool*/}
            <Field
              component={InputText}
              helperText="Somme à partager"
              label="Somme à partager"
              name="prizepool"
              type="number"
            />
            {/*Nombre questions*/}
            <Field
              component={InputText}
              helperText="Nombre de questions"
              label="Nombre de questions"
              name="nbQuestions"
              type="number"
            />
            <StyledButton type="submit" disabled={pristine || submitting || quizzes.isLoading}>
              CRÉER
            </StyledButton>
          </StyledForm>
        </QuizCreateContainer>
      </AllContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    initialValues: state.quizzes.quizCreateInitialState,
    quizzes: state.quizzes,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    quizzes: bindActionCreators(allTheActions.quizzes, dispatch),
  },
});

// first we connect the form to redux in order to get our initial state stored in the reducers
CreateQuizForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateQuizForm);

// then we connect to redux form (gives us acces to methods, allow the form to handle errors and form validation)
CreateQuizForm = reduxForm({
  form: 'CreateQuizForm', // a unique identifier for this form
  validate, // the validate rules
})(CreateQuizForm);

export default CreateQuizForm;
