import axios from 'axios';

import { userServer } from '../../../config';

export default async function ({ email, password }, enqueueSnackbar) {
  try {
    const { data, error } = await axios({
      data: { email, password },
      method: 'POST',
      url: `${userServer}admin/post`,
    });
    if (data && data.message) enqueueSnackbar(data.message, { variant: 'success' });
    if (error && error.message) enqueueSnackbar(error.message, { variant: 'error' });
    return { data, error };
  } catch (error) {
    if (error && error.message) enqueueSnackbar(error.message, { variant: 'error' });
    return { error };
  }
}
