import { stringRegExp } from '../../utils/allRegexp';

const validate = values => {
  const errors = {};

  if (!values.startAt) {
    errors.startAt = 'Veuillez entrer une date';
  }
  if (!values.name) {
    errors.name = 'Veuillez entrer un nom';
  }
  if (!stringRegExp.test(values.name)) {
    errors.name = 'Veuillez entrer une adresse email valid (Sans espace au début ou à la fin)';
  }
  if (values.maxJokersCopycat < 0 || (!values.maxJokersCopycat && values.maxJokersCopycat !== 0)) {
    errors.maxJokersCopycat = 'Veuillez entrer un nombre à partir de 0';
  }
  if (values.maxJokersNext < 0 || (!values.maxJokersNext && values.maxJokersNext !== 0)) {
    errors.maxJokersNext = 'Veuillez entrer un nombre à partir de 0';
  }
  if (values.prizepool < 0 || (!values.prizepool && values.prizepool !== 0)) {
    errors.maxJokersNext = 'Veuillez entrer un nombre à partir de 0';
  }
  if (values.nbQuestions <= 0 || !values.nbQuestions) {
    errors.nbQuestions = 'Veuillez entrer un nombre plus grand que 0';
  }
  return errors;
};

export default validate;
