import { Backdrop, Button, Modal, TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { Fragment, useState } from 'react';

import addAdmin from '../../requests/add';
import useStyles from './styles';

export default function Admin({ getAllAdmin }) {
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [password, setPassword] = useState("");
  async function onClick() {
    setIsLoading(true);
    if ((await addAdmin({ email, password }, enqueueSnackbar)).data) {
      await getAllAdmin();
      setIsOpen(false);
    }
    setIsLoading(false);
  }
  const classes = useStyles();
  return (
    <Fragment>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className={classes.paper}>
          <TextField
            label="Adresse mail"
            onChange={event => setEmail(event.target.value)}
            required
          />
          <TextField
            label="Mot de passe"
            onChange={event => setPassword(event.target.value)}
            required
          />
          <Button
            disabled={isLoading}
            onClick={onClick}
            variant="outlined"
          >
            Ajouter un administrateur
          </Button>
        </div>
      </Modal>
      <Button
        disabled={isLoading}
        onClick={() => setIsOpen(true)}
        variant="outlined"
      >Ajouter un administrateur</Button>
    </Fragment>
  );
}
