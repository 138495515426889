import axios from 'axios';
import { config } from '../config/firebase';

export const DISPLAY_USER = 'DISPLAY_USER';
export const DISPLAY_USERS_LENGTH = 'DISPLAY_USERS_LENGTH';
export const DISPLAY_USERS = 'DISPLAY_USERS';
export const PENDING_USER = 'PENDING_USER';
export const PENDING_UPDATE_USER = 'PENDING_UPDATE_USER';
export const PENDING_DELETE_USER = 'PENDING_DELETE_USER';
export const PENDING_DISABLE_USER = 'PENDING_DISABLE_USER';
export const PENDING_DOWNLOAD_USER = 'PENDING_DOWNLOAD_USER';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DISABLE_SUCCESS = 'DISABLE_SUCCESS';
export const USERS_DOWNLOAD_LINK = 'USERS_DOWNLOAD_LINK';
export const USERS_CLEAR_LINK = 'USERS_CLEAR_LINK';

export const usersClearLink = link => ({
  type: 'USERS_CLEAR_LINK',
  payload: link
});

export const displayUser = user => ({
  type: 'DISPLAY_USER',
  payload: user
});

export const displayUsersLength = length => ({
  type: 'DISPLAY_USERS_LENGTH',
  payload: length
});

export const displayUsers = users => ({
  type: 'DISPLAY_USERS',
  payload: users
});

export const pending = boolean => ({
  type: 'PENDING_USER',
  payload: boolean
});

export const pendingUpdateUser = boolean => ({
  type: 'PENDING_UPDATE_USER',
  payload: boolean
});

export const pendingDownloadUser = boolean => ({
  type: 'PENDING_DOWNLOAD_USER',
  payload: boolean
});

export const deleteSuccess = boolean => ({
  type: 'DELETE_SUCCESS',
  payload: boolean
});

export const disableSuccess = boolean => ({
  type: 'DISABLE_SUCCESS',
  payload: boolean
});

export const usersDownloadLink = link => ({
  type: 'USERS_DOWNLOAD_LINK',
  payload: link
});

export const getUser = userId => dispatch => {
  dispatch(pending(true));
  axios({
    method: 'GET',
    url: `${config.cloudHttps}httpsAdminUsers?action=getUser&userId=${userId}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      idToken: localStorage.getItem('firebaseToken')
    }
  })
    .then(res => {
      dispatch(pending(false));
      return dispatch(displayUser(res.data));
    })
    .catch(err => {
      dispatch(pending(false));
      console.log(err);
    });
};

export const getUsers = (searchQuery, page = 0, limit = 10) => dispatch => {
  dispatch(pending(true));
  axios({
    method: 'GET',
    url: `${config.cloudHttps}httpsAdminUsersGet`,
    params: {
      searchQuery,
      limit,
      skip: page * limit
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      idToken: localStorage.getItem('firebaseToken')
    }
  })
    .then(res => {
      dispatch(pending(false));
      if (res && res.data) {
        dispatch(displayUsersLength(res.data.count));
        dispatch(displayUsers(res.data.users));
      }
      return;
    })
    .catch(err => {
      dispatch(pending(false));
      console.log(err);
    });
};

export const putUser = user => dispatch => {
  dispatch(pendingUpdateUser(true));
  axios({
    method: 'PUT',
    url: `${config.cloudHttps}httpsAdminUsersPut`,
    data: {
      id: user.id,
      jokers: user.jokers,
      jokersChangeReason: user.jokersChangeReason,
      nickname: user.nickname,
      phoneNumber: user.phoneNumber,
      referral: user.referral,
      totalEarned: user.totalEarned
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      idToken: localStorage.getItem('firebaseToken')
    }
  })
    .then(res => {
      if (res.status !== 200) {
        alert("Impossible de modifier l'utilisateur");
      }
      dispatch(pendingUpdateUser(false));
      return dispatch(displayUser(res.data));
    })
    .catch(err => {
      alert(err);
      dispatch(pendingUpdateUser(false));
      console.log(err);
    });
};

export const deleteUser = (action, id) => dispatch => {
  dispatch(pending(true));
  axios({
    method: 'DELETE',
    url: `${config.cloudHttps}httpsAdminUsersDelete`,
    data: {
      action,
      id
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      idToken: localStorage.getItem('firebaseToken')
    }
  })
    .then(res => {
      dispatch(pending(false));
      if (res.status !== 200) {
        return alert("Impossible de désactiver l'utilisateur");
      }
      if (action === 'mute') {
        alert('User muted');
        return dispatch(disableSuccess(true));
      }
      if (action === 'delete') {
        return dispatch(deleteSuccess(true));
      }
      return dispatch(disableSuccess(true));
    })
    .catch(err => {
      alert(err);
      console.log(err);
      return dispatch(pending(false));
    });
};

export const downloadUsers = searchTerm => dispatch => {
  dispatch(pendingDownloadUser(true));
  axios({
    method: 'GET',
    url: `${config.cloudHttps}httpsAdminUsersDownload`,
    params: {
      searchTerm
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      idToken: localStorage.getItem('firebaseToken')
    }
  })
    .then(res => {
      dispatch(usersDownloadLink(res.data));
      dispatch(pendingDownloadUser(false));
      return;
    })
    .catch(err => {
      alert(err);
      console.log(err);
      dispatch(pendingDownloadUser(false));
      return;
    });
};
