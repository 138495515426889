import { Field } from 'redux-form';
import React from 'react';
import styled from 'styled-components';

import { CommonText } from '../texts';
import { renderSwitch } from '../reduxFormsComponents';

const InputAndLabelContainer = styled.div`
  align-items: center;
  display: flex;
`;

const StyledInputAndLabelContainer = styled(InputAndLabelContainer)`
  margin-top: 10px;
`;

const StyledCommonText = styled(CommonText)`
  margin-right: 10px;
`;

const HeaderQuiz = styled.div`
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: space-around;
  width: 100%;
`;

const OptionStyled = styled.option`
  outline: none;
  padding: 5px;
  text-align: center;
`;

class HeaderFields extends React.Component {
  render() {
    const { handleNbAnswersChange, match } = this.props;
    const page = Number(match.params.page) + 1;
    return (
      <HeaderQuiz>
        <CommonText fontSize={24}>{`Question ${page}`}</CommonText>
          <StyledInputAndLabelContainer>
            <StyledCommonText fontSize={12}>
              Nombre de réponses
            </StyledCommonText>
            <Field
              component="select"
              name="nbAnswers"
              onChange={handleNbAnswersChange}
            >
              <OptionStyled value="1">1</OptionStyled>
              <OptionStyled value="2">2</OptionStyled>
              <OptionStyled value="3">3</OptionStyled>
              <OptionStyled value="4">4</OptionStyled>
            </Field>
          </StyledInputAndLabelContainer>
        <InputAndLabelContainer>
          <Field component={renderSwitch} name="disableQuestionSound" />
          <CommonText>Son désactivé</CommonText>
        </InputAndLabelContainer>
        <InputAndLabelContainer>
          <Field component={renderSwitch} name="isJokerDisabled" />
          <CommonText>Joker désactivé</CommonText>
        </InputAndLabelContainer>
      </HeaderQuiz>
    );
  }
}

export default HeaderFields;
