import axios from 'axios';

import { config } from '../config/firebase';

export const DISPLAY_QUIZZES_QUIDOL_GO = 'DISPLAY_QUIZZES_QUIDOL_GO';
export const DISPLAY_QUIZ_QUIDOL_GO = 'DISPLAY_QUIZ_QUIDOL_GO';
export const ERROR_FETCH_QUIZ_QUIDOL_GO = 'ERROR_FETCH_QUIZ_QUIDOL_GO';
export const PENDING_QUIDOL_GO = 'PENDING_QUIDOL_GO';
export const CANNOT_CHANGE_FORM_QUIDOL_GO = 'CANNOT_CHANGE_FORM_QUIDOL_GO';

export const displayQuidolGoQuizzes = quiz => ({
  type: DISPLAY_QUIZZES_QUIDOL_GO,
  payload: quiz,
});

export const pending = bool => ({
  type: PENDING_QUIDOL_GO,
  payload: bool,
});

export const displayQuiz = questions => ({
  type: DISPLAY_QUIZ_QUIDOL_GO,
  payload: questions,
});

export const displayQuizError = err => ({
  type: ERROR_FETCH_QUIZ_QUIDOL_GO,
  payload: err,
});

export const cannotChangeForm = bool => ({
  type: CANNOT_CHANGE_FORM_QUIDOL_GO,
  payload: bool,
});

export const getQuizzes = (searchTerm, limit = 10, page = 0) => dispatch => {
  dispatch(pending(true));
  axios({
    method: 'get',
    url: `${config.cloudHttps}/httpsAdminQuidolGoGetQuidolGoQuizzes`,
    params: {
      limit,
      searchTerm,
      skip: page * limit,
      type: 'challenge',
    },
  })
    .then(res => {
      dispatch(displayQuidolGoQuizzes(res.data));
      dispatch(pending(false));
      return;
    })
    .catch(err => {
      dispatch(displayQuizError(err));
      dispatch(pending(false));
    });
};

export const getQuiz = quizId => dispatch => {
  dispatch(pending(true));
  axios({
    method: 'get',
    url: `${config.cloudHttps}/httpsAdminQuidolGoGetQuidolGoQa`,
    params: {
      quizId: quizId,
    },
  })
    .then(res => {
      dispatch(processQuiz(res.data));
      dispatch(pending(false));
      return;
    })
    .catch(err => {
      dispatch(displayQuizError(err));
      dispatch(pending(false));
    });
};

export const processQuiz = questions => dispatch => {
  const results = questions.map((questionArray, index) => {
    return questionArray.map(question => {
      return {
        id: question._id,
        answers: question.answers,
        question: question.question,
        type: question.type,
        questionNumber: index + 1,
      };
    });
  });
  dispatch(displayQuiz(results.flat()));
};
