import { Route, Switch } from "react-router";
import QuizCreate from "./quizCreate";
import QuizEdit from "./quizEdit";
import QuizTable from "./quizTable";
import React, { Component } from "react";

class Quizzes extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/home/quizzes" component={QuizTable} />
          <Route path="/home/quizzes/creation" component={(props) => <QuizCreate {...props} isCustomQuiz={false} />} />
          <Route path="/home/quizzes/edition/:id/:page" component={(props) => <QuizEdit {...props} isCustomQuiz={false} />} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default Quizzes;
