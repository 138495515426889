import React, {useEffect, useState} from 'react';
import firebase from "firebase";

import LoadingBar from '../../components/loadingBar';
import { Fab, Container, AppBar, Toolbar, Typography, Grid, TextField } from "@material-ui/core";
const storageRef = firebase.storage().ref();
let wordingRef = storageRef.child('backOfficeUploads/wording/wording.json');

const DashboardWording = (props) => {
  const [loading, setLoading] = useState(true)
  const [textColor, setTextColor] = useState('black')
  const [wordingJson, setWordingJson] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    wordingRef.getDownloadURL().then(function(url) {
      fetch(url).then((resp) => {
        resp.json().then((data) => {
          setWordingJson(data);
          setLoading(false);
          return true;
        }).catch(() => {
          console.error('Bad Json');
          setErrorMessage('requested a bad json');
          return false;
        });
        return true;
      }).catch((e) => {
        setErrorMessage('Erreur lors du telechargement du fichier de traduction');
        console.error(`[WORDING] ${e}`);
      });
      return true;
    }).catch(function(error) {
      switch (error.code) {
        case 'storage/object-not-found':
          setErrorMessage("Le fichier n'existe pas");
          break;
        case 'storage/unauthorized':
          setErrorMessage('Vous n\'avez pas les droits');
          break;
        case 'storage/canceled':
          setErrorMessage('Annuler par un utilisateur');
          break;
        case 'storage/unknown':
          setErrorMessage('Erreur inconnue');
          break;
        default:
          setErrorMessage('Erreur de telechargement');
      }
    });
  }, []);

  const jsonRenderer = (data, name = '', tab = 0) => {
    let mapperJsonTrad = [];
    let orderedData = {}

    if (typeof data === "object") {
      Object.keys(data).sort().forEach(function(key) {
        orderedData[key] = data[key];
        delete data[key];
      });
      for (let key in orderedData) {
        data[key] = orderedData[key];
      }
    }
    for (let key in data) {
      if (data.hasOwnProperty(key) && typeof data[key] === 'object') {
        mapperJsonTrad.push(
          <AppBar key={`${name}-${key}`} style={{marginTop: 30, marginBottom: 30}} position="static">
            <Toolbar>
              <Typography variant="h6" >
                {`${'-'.repeat(tab + 1)} ${key}`}
              </Typography>
            </Toolbar>
          </AppBar>);
        mapperJsonTrad.push(jsonRenderer(data[key], key, tab + 1));
        mapperJsonTrad.push(<hr style={{width: '100%'}} key={`${name}-${key}-hr`} />);
      } else {
        mapperJsonTrad.push(<div key={`${name}-${key}`} style={{marginLeft: tab * 2}}>
          <TextField onChange={(event) => {
            console.log(wordingJson);
            data[key] = event.target.value;
          }} style={{width: '100%'}} defaultValue={data[key]} label={key} /></div>)
      }
    }
    return mapperJsonTrad;
  };

  return (
    <Container maxWidth="lg">
      <LoadingBar isLoading={loading} />
      <p style={{color: 'red'}}>{errorMessage}</p>
      {!loading &&
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <p style={{color: 'cyan'}}>DZ</p>
          {jsonRenderer(wordingJson.dz.translation, 'Dz')}
        </Grid>
        <Grid item xs={6}>
          <p style={{color: 'cyan'}}>FR</p>
          {jsonRenderer(wordingJson.fr.translation, 'Fr')}
        </Grid>
        <Fab onClick={() => {
          setLoading(true);
          wordingRef.putString(JSON.stringify(wordingJson)).then((snapshot) => {
            setLoading(false);
            setTextColor('green');
            return console.log('FILE UPLOADED !');
          }).catch(e => {
            console.error('An error occured while uploading the file.');
            setErrorMessage("Une erreur est survenue");
            setTextColor('red');
          });
        }} style={{position: 'fixed', top: '90%'}} color="primary" aria-label="add">
          <p style={{color: textColor}}>save</p>
        </Fab>
      </Grid>

      }
    </Container>
  );
}

export default DashboardWording;