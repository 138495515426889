import React from "react";
import Switch from "@material-ui/core/Switch";

const renderSwitch = ({ input, inputProps, inputRef, disabled, onValues}) => {

  if (onValues)
    onValues(input.value);
  return (
    <Switch
      {...input}
      {...inputProps}
      checked={input.value}
      inputProps={{...input}}
      inputRef={inputRef}
      onChange={input.onChange}
      disabled={(disabled === 'notSet' ? input.value !== "checked" : disabled)}
      // fix the compatibility issue between redux form who send a boolean and material ui that accept only string for a switch for some unknown reason
      value={input.value === true ? "checked" : ""}
    />
  );
};

export default renderSwitch;
