import { SubmissionError } from "redux-form";

const errorHandler = errors => {
  // add custom error next to designated fields
  if (errors.code === "auth/user-not-found") {
    throw new SubmissionError({
      email: "email not found, the user may have been deleted",
      _error: errors.message
    });
  }
  if (errors.code === "auth/wrong-password") {
    throw new SubmissionError({
      password: "Password incorrect",
      _error: errors.message
    });
  }
};

export default errorHandler;
