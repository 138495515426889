import { Route, Switch } from 'react-router';
import React from 'react';
import DashboardReplay from "./replayControl";

class Replays extends React.PureComponent {

  render() {
    
    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/home/replays" component={DashboardReplay} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default Replays;

