import React from 'react';
import styled from 'styled-components';

import { CommonText } from '../texts';

const ResultText = styled(CommonText)`
  padding: 5px;
`;

const QuestionsDisplayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 10px;
`;

const Question = styled.div`
  background-color: ${props => props.theme.color.white}
  box-shadow: 0px 0px 1px rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const QuestionText = styled(CommonText)`
  flex: 1;
  padding: 5px;
`;

const LetterContainer = styled.div`
  align-items: center;
  background-color: ${props =>
    props.isRight ? props.theme.color.questionRight : props.theme.color.questionWrong}
  display: flex;
  height: 50px;
  justify-content: center;
  margin-right: 15px;
  text-transform: uppercase;
  width: 65px;
`;

const IndexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
`;

const RowContainer = styled.div`
  align-items: center;
  background-color: ${props =>
    props.odd % 2 === 0 ? props.theme.color.questionOdd : props.theme.color.white};
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

const AnswerImage = styled.img`
  max-height: 50px;
`;

const JokersContainer = styled.div`
  display: flex;
  width: 100%;
`;

const SectionContainer = styled.div`
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
`;

export default class QuestionsDisplayer extends React.Component {
  displayAnswers = ({answers, type}) => {
    const tableau = [];
    const {
      currentQuestion: { questionId },
    } = this.props;
    let totalNbAnswers = 0;
    for (const i in answers) {
      totalNbAnswers += answers[i].nbAnswers || 0;
    }
    for (const answer in answers) {
      const { content, isRight, nbAnswers } = answers[answer];
      const id = answers[answer].id || answer;
      const percent = totalNbAnswers ? Math.round((nbAnswers / totalNbAnswers) * 100) : 0;
      tableau.push(
        <RowContainer key={`${questionId}${id}`}>
          <IndexContainer>
            <LetterContainer isRight={isRight}>{id}</LetterContainer>
            {type === 'image' ? <AnswerImage src={content} alt={content}/> : <QuestionText fontSize={16}>{content.fr}<br/>{content.dz}</QuestionText>}
          </IndexContainer>
          <ResultText fontSize={16}>{`${percent}% ( ${nbAnswers || 0} )`}</ResultText>
        </RowContainer>
      );
    }
    return tableau;
  };

  render() {
    const { currentQuestion, maxJokersCopycat, maxJokersNext, timeoutDisabled } = this.props;
    if (!currentQuestion.answers) return null;
    return (
      <QuestionsDisplayerContainer>
        <Question>
          <QuestionText fontSize={16}>Question N° {currentQuestion.order}</QuestionText>
          <QuestionText fontSize={16}>{currentQuestion.question.fr} <br/> {currentQuestion.question.dz}</QuestionText>
        </Question>
        <SectionContainer>{this.displayAnswers(currentQuestion)}</SectionContainer>
        {!timeoutDisabled && (
          <SectionContainer>
            <RowContainer>
              <QuestionText fontSize={16}>N'ayant pas répondu</QuestionText>
              <ResultText fontSize={16}>{currentQuestion.timeoutAnswersCount}</ResultText>
            </RowContainer>
          </SectionContainer>
        )}
        {maxJokersNext || maxJokersCopycat ? (
          <SectionContainer>
            <RowContainer>
              <JokersContainer>
                {maxJokersNext ? (
                  <React.Fragment>
                    <QuestionText fontSize={16}>Nombre de jokers utilisés</QuestionText>
                    <ResultText fontSize={16}>{currentQuestion.jokersNextCount}</ResultText>
                  </React.Fragment>
                ) : null}
              </JokersContainer>
            </RowContainer>
          </SectionContainer>
        ) : null}
      </QuestionsDisplayerContainer>
    );
  }
}
