import SocketIOClient from 'socket.io-client';

import { socketTVEndPoint } from '../config';

var socket;

export const connect = async (token) => {
  if (socket && socket.connected) socket.disconnect();
  socket = SocketIOClient(socketTVEndPoint, { rejectUnauthorized: false });
  socket.on('connect', async () => {
    console.log('Authentication TV');
    const { error } = await new Promise(resolve => socket.emit('authentication', token, resolve));
    if (error) {
      console.error(error);
      socket.close();
    }
  });
};

const showAnswer = async (args) => {
  if (!socket) return;
  const { error } = await new Promise(resolve => socket.emit('showAnswer', args, resolve));
  if (error) console.error(error);
};

const showQuestion = async (args) => {
  if (!socket) return;
  const { error } = await new Promise(resolve => socket.emit('showQuestion', args, resolve));
  if (error) console.error(error);
};

export default { showAnswer, showQuestion };
