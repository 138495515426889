import { SubTitle } from "../texts";
import React from "react";
import styled from "styled-components";

import config from "../../config";

const HeaderBar = styled.div`
  align-items: center;
  background-color: ${props => props.theme.color.mainColor};
  display: flex;
  height: inherit;
  margin: auto;
  position: fixed;
  width: 100%;
  z-index: 10;
`;

const HeaderContainer = styled.div`
  height: 60px;
`;

const StyledIcon = styled.div`
  height: 50px;
  margin: 0px 15px 0px 15px;
  width: 50px;
`;

const HeaderTitle = styled(SubTitle)`
  padding-right: 15px;
`;

const Title = config.name;

const Header = () => (
  <HeaderContainer>
    <HeaderBar>
      <StyledIcon />
      <HeaderTitle color="white" fontSize={24}>
        {Title}
      </HeaderTitle>
    </HeaderBar>
  </HeaderContainer>
);

export default Header;
