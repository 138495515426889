import {
  UPDATE_CONFIG,
  UPDATE_STATS,
  UPDATE_START_DATE,
  UPDATE_END_DATE,
} from '../actions/adMob';

const initialState = {
  stats: {},
  config: {},
  startDate: undefined,
  endDate: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CONFIG:
      return { ...state, config: { ...state.config, ...action.config } };
    case UPDATE_STATS:
      return { ...state, stats: { ...state.stats, ...action.stats } };
    case UPDATE_START_DATE:
      return { ...state, startDate: action.startDate };
    case UPDATE_END_DATE:
      return { ...state, endDate: action.endDate };
    default:
      return state;
  }
};
