import moment from 'moment'
import React, { Component } from 'react';
import {
  CardContent,
  Checkbox,
  CircularProgress, Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';

import {
  ButtonDownloadIbanWinners,
  ButtonDownloadWinners,
  ButtonSearchWinners,
  ButtonValidateIbanWinners,
  ButtonValidateUniqIban,
  RevertUniqWinnerButton,
} from './winnersButtons';
import CardTitle from '../cardTitle';

import styled from 'styled-components';

const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-left: 30px;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px 0;
`;

const NameContainer = styled.div`
  width: 200px;
`;

export class WinnersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      getPhoneNumber: false,
      from: new Date(0).toISOString(),
      to: new Date().toISOString(),
      limit: 100,
      page: 0,
      searchTerm: null,
    };
    this.downloadWinnersCCP = this.downloadWinnersCCP.bind(this);
  }

  componentDidMount = async () => {
    await this.getWinners();
  }

  getWinners = async (searchText) => {
    const {
      actions: { winners }
    } = this.props;
    const { limit, page } = this.state;
    winners.getWinners(searchText, page, limit, this.state.from, this.state.to);
  };
  handleChangeLimit = async limit => {
    const { searchTerm, page } = this.state;
    await this.setState({ limit, page: 0 });
    this.getWinners(searchTerm, page, limit);
  };
  handleChangePage = async page => {
    const { searchTerm } = this.state;
    await this.setState({ page });
    this.getWinners(searchTerm, page);
  };
  handleChangeSearchTerm = async searchTerm => {
    await this.setState({ page: 0, searchTerm });
    if (searchTerm) {
      this.getWinners(searchTerm);
    } else {
      this.getWinners();
    }
  };
  validateWinners = async (action, file, winnerId) => {
    const {
      actions: { winners }
    } = this.props;
    const { searchTerm, page, limit } = this.state;
    await winners.validateWinners(action, file, winnerId);
    if (winnerId) {
      this.getWinners(searchTerm, page, limit);
    }
  };

  downloadWinnersCCP = () => {
    const { searchTerm } = this.state;
    this.props.downloadIbanWinners(searchTerm, this.state.from, this.state.to);
  }

  handleChange = event => {
    this.setState({ getPhoneNumber: event.target.checked });
  };
  handleSubmit(event, searchText) {
    this.handleChangeSearchTerm(searchText);
    event.preventDefault();
  }
  render() {
    const { getPhoneNumber } = this.state;
    const {
      props: {
        downloadWinners,
        isLoading,
        length,
        limit,
        page,
        pendingDownload,
        pendingDownloadIban,
        pendingDownloadPaypal,
        pendingRevertUniqWinner,
        pendingRevertUniqWinnerId,
        pendingValidateIban,
        pendingValidatePaypal,
        pendingValidateUniqIban,
        pendingValidateUniqIbanWinnerId,
        pendingValidateUniqPaypal,
        searchTerm,
        winners,
        seasons
      }
    } = this;
    const buttonsDisabled =
      isLoading ||
      pendingDownload ||
      pendingDownloadIban ||
      pendingDownloadPaypal ||
      pendingRevertUniqWinner ||
      pendingValidateIban ||
      pendingValidatePaypal ||
      pendingValidateUniqIban ||
      pendingValidateUniqPaypal;
    let index = 0;
    const { searchText } = this.state;
    return (
      <div>
        {CardTitle('Vainqueurs & paiements')}
        <ButtonsContainer>
          <ButtonDownloadIbanWinners
            buttonsDisabled={buttonsDisabled}
            downloadIbanWinners={this.downloadWinnersCCP}
            pendingDownloadIban={pendingDownloadIban}
          />
          <ButtonValidateIbanWinners
            buttonsDisabled={buttonsDisabled}
            validateWinners={this.validateWinners}
            pendingValidateIban={pendingValidateIban}
          />
        </ButtonsContainer>
        <form onSubmit={event => this.handleSubmit(event, searchText)}>
          <TextField
            id="winnerId"
            label="Rechercher"
            name={searchTerm}
            onChange={event =>
              this.setState({ searchText: event.target.value })
            }
            style={{ marginLeft: 40, marginTop: 20, display: 'block' }}
          />
          <Select
            native
            value={this.state.age}
            onChange={(event) => {
              const item = JSON.parse(event.target.value);
              const {
                actions: { winners }
              } = this.props;

              this.setState({ from: item.from, to: item.to })
              winners.getWinners(searchText, page, limit, item.from, item.to);
            }}
            inputProps={{
              name: 'age',
              id: 'age-native-simple',
            }}
            style={{ marginLeft: 40, marginTop: 20, display: 'block', width: '300px' }}
          >
            <option aria-label="None" value={JSON.stringify({from: new Date(0).toISOString(), to: new Date().toISOString()})} />
            {seasons.map((item) => {
              return <option key={item.title.fr} value={JSON.stringify(item)}>{item.title.fr}</option>
            })}
          </Select>
          <ButtonsContainer>
            <ButtonSearchWinners buttonsDisabled={buttonsDisabled} />
            <ButtonDownloadWinners
              buttonsDisabled={buttonsDisabled}
              getPhoneNumber={getPhoneNumber}
              downloadWinners={downloadWinners}
              pendingDownload={pendingDownload}
              searchText={searchText}
            />
            <Checkbox
              checked={getPhoneNumber}
              color="primary"
              disabled={buttonsDisabled}
              onChange={event => this.handleChange(event)}
            />
            Avec les numéros de téléphone
          </ButtonsContainer>
        </form>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow width="100">
                <TableCell>
                  <NameContainer>Nom du quiz</NameContainer>
                </TableCell>
                <TableCell>ID du quiz</TableCell>
                <TableCell>ID d'utilisateur</TableCell>
                <TableCell>Pseudo</TableCell>
                <TableCell>Date de création</TableCell>
                <TableCell>Date de demande de paiement</TableCell>
                <TableCell>Statut</TableCell>
                <TableCell>Mode de paiement</TableCell>
                <TableCell>Date de paiement</TableCell>
                <TableCell>CCP</TableCell>
                <TableCell>Gain</TableCell>
                <TableCell>Erreur</TableCell>
                <TableCell>Valider le paiement CCP</TableCell>
                <TableCell>Annuler le paiement</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && winners && winners.length ? (
                winners.map(winner => (
                  <TableRow key={index++}>
                    <TableCell>{winner.quizName}</TableCell>
                    <TableCell>{winner.quizId}</TableCell>
                    <TableCell>{winner.userId}</TableCell>
                    <TableCell>{winner.nickname}</TableCell>
                    <TableCell>{winner.createdAt ? moment(winner.createdAt).format('D/M/YY HH:mm') : ''}</TableCell>
                    <TableCell>{winner.requestedAt ? moment(winner.requestedAt).format('D/M/YY HH:mm') : ''}</TableCell>
                    <TableCell>{winner.status === 'waiting_request' ? 'en attente': winner.status}</TableCell>
                    <TableCell>{winner.paidMethod}</TableCell>
                    <TableCell>{winner.paidAt ? moment(winner.paidAt).format('D/M/YY HH:mm') : ''}</TableCell>
                    <TableCell>{(winner.postal) ? winner.postal.code : ''}</TableCell>
                    <TableCell>{winner.gain}</TableCell>
                    <TableCell>{winner.issue}</TableCell>
                    <TableCell>
                      <ButtonValidateUniqIban
                        iban={(winner.postal) ? winner.postal.code : ''}
                        status={winner.status}
                        winnerId={winner._id}
                        buttonsDisabled={buttonsDisabled}
                        pendingValidateUniqIban={pendingValidateUniqIban}
                        pendingValidateUniqIbanWinnerId={
                          pendingValidateUniqIbanWinnerId
                        }
                        validateWinners={this.validateWinners}
                      />
                    </TableCell>
                    <TableCell>
                      <RevertUniqWinnerButton
                        status={winner.status}
                        winnerId={winner._id}
                        pendingRevertUniqWinner={pendingRevertUniqWinner}
                        pendingRevertUniqWinnerId={pendingRevertUniqWinnerId}
                        validateWinners={this.validateWinners}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={13}>
                    <SpinnerContainer>
                      {isLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <span>Aucun gagnant</span>
                      )}
                    </SpinnerContainer>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            colSpan={1}
            count={length}
            onChangePage={(e, value) => this.handleChangePage(value)}
            onChangeRowsPerPage={({ target: { value } }) =>
              this.handleChangeLimit(value)
            }
            page={page}
            rowsPerPage={limit < length ? limit : length}
            rowsPerPageOptions={
              length > 50 ? [10, 50, 100] : length > 10 ? [10, 50] : [10]
            }
          />
        </CardContent>
      </div>
    );
  }
}
