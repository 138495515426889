import React from 'react';
import styled from 'styled-components';

const LogsContainer = styled.div`
  background-color: aliceblue;
  height: 400px;
  overflow: scroll;
  width: 100%;
`;

const Logs = styled.p`
  font-weight: ${props => (props.type === 'set' ? 'bold' : 'normal')};
`;

class LogsChunk extends React.PureComponent {
  render() {
    const { logs, currentQuestion } = this.props;
    let index = 0;

    return (
      <LogsContainer>
        {logs && logs[0]
          ? logs.map(log => (
              <Logs key={`${log.type}${log.chunkTime}-${index++}`} type={log.type}>
                {log.date} type: {log.type} chunkTime: {log.chunkTime} details: Q{currentQuestion} - {Date.now()}
              </Logs>
            ))
          : 'Pas de détail disponible'}
      </LogsContainer>
    );
  }
}

export default LogsChunk;
