import {
  SET_CARD_LIST,
  DELETE_CARD,
  CREATE_CARD,
  EDIT_CARD,
  PENDING,
  RESET_SAVE_STATE,
} from '../actions/cards';
import { cloneDeep } from 'lodash';

const initialState = {
  cardsList: {},
  isLoading: false,
  needSave: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CARD_LIST:
      return {
        ...state,
        cardsList: {
          primaries: action.payload.primaries.sort((prev, next) => prev.order - next.order),
          secondary: action.payload.secondary.sort((prev, next) => prev.order - next.order),
        },
      };
    case PENDING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case DELETE_CARD:
      const splicedArray = cloneDeep(state.cardsList);
      splicedArray[action.cardType].splice(action.index, 1)
      return {
        ...state,
        cardsList: splicedArray,
        needSave: true,
      };
    case CREATE_CARD:
      const clone = cloneDeep(state.cardsList);
      clone[action.cardType].push(action.card);
      clone[action.cardType].sort((prev, next) => prev.order - next.order);
      return {
        ...state,
        cardsList: clone,
        needSave: true,
      };
    case RESET_SAVE_STATE:
      return {
        ...state,
        needSave: false,
      };
    case EDIT_CARD:
      const cloneCards = cloneDeep(state.cardsList);
      cloneCards.primaries[action.index] = action.card;
      return {
        ...state,
        cardsList: cloneCards,
        needSave: true,
      }
    default:
      return state;
  }
};
