import { cloneDeep } from 'lodash';
import {
  DISPLAY_ERROR_SURVEYS,
  PENDING,
  CURRENT_SURVEY,
  SET_SURVEY_LIST,
} from '../actions/surveys';

const initialState = {
  count: 0,
  data: [],
  error: '',
  isLoading: false,
  surveys: [],
  isSurveyEnded: false,
  isSurveyFetching: false,
  isSurveyStarted: false,
  currentSurvey: {},
  surveyList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_ERROR_SURVEYS:
      return {
        ...state,
        error: action.payload.message,
      };
    case PENDING:
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    case CURRENT_SURVEY: {
      return {
        ...state,
        currentSurvey: action.payload,
      };
    }
    case SET_SURVEY_LIST:
      const clone = cloneDeep(action.surveys);
      return {
        ...state,
        surveyList: clone
      }
    default:
      return state;
  }
};
