import axios from 'axios';
import firebase from 'firebase';

import { config } from '../config/firebase';

export const PENDING_MESSAGES = 'PENDING_MESSAGES';
export const PENDING_DOWNLOAD = 'PENDING_DOWNLOAD';
export const DISPLAY_MESSAGES = 'DISPLAY_MESSAGES';
export const DISPLAY_MESSAGES_HISTORY = 'DISPLAY_MESSAGES_HISTORY';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const CLEAR_MESSAGES_HISTORY = 'CLEAR_MESSAGES_HISTORY';
export const UPDATE_SCROLL_SPEED = 'UPDATE_SCROLL_SPEED';
export const UPDATE_QUIZ_ID = 'UPDATE_QUIZ_ID';
export const CHANGE_QUIZ = 'CHANGE_QUIZ';
export const UPDATE_LAST_CREATED_AT = 'UPDATE_LAST_CREATED_AT';
export const UPDATE_FONT_SIZE = 'UPDATE_FONT_SIZE';
export const MESSAGES_DOWNLOAD_LINK = 'MESSAGES_DOWNLOAD_LINK';
export const MESSAGES_CLEAR_LINK = 'MESSAGES_CLEAR_LINK';

export const pending = boolean => ({
  type: PENDING_MESSAGES,
  payload: boolean
});

export const pendingDownload = boolean => ({
  type: PENDING_DOWNLOAD,
  payload: boolean
});

export const displayMessages = messages => ({
  type: DISPLAY_MESSAGES,
  payload: messages
});

export const displayMessagesHistory = messagesHistory => ({
  type: DISPLAY_MESSAGES_HISTORY,
  payload: messagesHistory
});

export const clearMessages = messages => ({
  type: CLEAR_MESSAGES,
  payload: messages
});

export const clearMessagesHistory = messagesHistory => ({
  type: CLEAR_MESSAGES_HISTORY,
  payload: messagesHistory
});

export const updateScrollSpeed = scrollSpeed => ({
  type: UPDATE_SCROLL_SPEED,
  payload: scrollSpeed
});

export const updateQuizId = quizId => ({
  type: UPDATE_QUIZ_ID,
  payload: quizId
});

export const changeQuiz = values => ({
  type: CHANGE_QUIZ,
  payload: values
});

export const updateLastCreatedAt = lastCreatedAt => ({
  type: UPDATE_LAST_CREATED_AT,
  payload: lastCreatedAt
});

export const updateFontSize = fontSize => ({
  type: UPDATE_FONT_SIZE,
  payload: fontSize
});

export const messagesDownloadLink = link => ({
  type: MESSAGES_DOWNLOAD_LINK,
  payload: link
});

export const messagesClearLink = link => ({
  type: MESSAGES_CLEAR_LINK,
  payload: link
});

export const getMessages = ({ quizId, afterDate }) => dispatch => {
  dispatch(pending(true));
  axios({
    method: 'GET',
    url: `${config.cloudHttps}/httpsAdminMessagesGet`,
    params: {
      quizId,
      afterDate,
      limit: 500
    }
  })
    .then(res => {
      if (res.status !== 200 && res.status !== 304)
        return alert(
          'Une erreur est survenue: ',
          `httpsAdminMessageGet status: ${res.status}`
        );
      dispatch(pending(false));
      const messages = res.data;
      if (messages && messages.length)
        dispatch(updateLastCreatedAt(messages[messages.length - 1].createdAt));
      return dispatch(displayMessages(messages));
    })
    .catch(err => {
      dispatch(pending(false));
      console.log(err);
      return alert(
        'Une erreur est survenue: ',
        `httpsAdminMessageGet status: ${err}`
      );
    });
};

const fetchMessages = (
  quizId,
  afterDate,
  limit = 500,
  skip = 0,
  messagesHistory = []
) => {
  return axios({
    method: 'GET',
    url: `${config.cloudHttps}/httpsAdminMessagesGet`,
    params: {
      quizId,
      afterDate,
      limit,
      skip
    }
  }).then(res => {
    if (res.status !== 200 && res.status !== 304) {
      return alert(
        'Une erreur est survenue: ',
        `httpsAdminMessageGet status: ${res.status}`
      );
    }
    if (res.data && res.data.length) {
      messagesHistory = messagesHistory.concat(res.data);
      skip = skip + limit;
      return fetchMessages(quizId, afterDate, limit, skip, messagesHistory);
    }
    return messagesHistory;
  });
};

export const getMessagesHistory = ({ quizId, afterDate }) => dispatch => {
  dispatch(pending(true));
  fetchMessages(quizId, afterDate)
    .then(res => {
      dispatch(pending(false));
      const messagesHistory = res;
      if (messagesHistory && messagesHistory.length)
        dispatch(
          updateLastCreatedAt(
            messagesHistory[messagesHistory.length - 1].createdAt
          )
        );
      return dispatch(displayMessagesHistory(messagesHistory));
    })
    .catch(err => {
      dispatch(pending(false));
      console.log(err);
      return alert(
        'Une erreur est survenue: ',
        `httpsAdminMessageGet status: ${err}`
      );
    });
};

export const getQuizId = () => dispatch => {
  firebase
    .database()
    .ref('nextQuiz/quizId')
    .on('value', snap =>
      dispatch(updateQuizId(snap ? snap.val() || null : null))
    );
};

export const downloadMessages = quizId => dispatch => {
  dispatch(pendingDownload(true));
  axios({
    method: 'GET',
    headers: { 'Content-Type': 'text/csv' },
    url: `${config.cloudHttps}/httpsAdminMessagesDownload`,
    params: {
      quizId
    }
  })
    .then(res => {
      if (res.status !== 200) {
        alert('Erreur: Impossible de télécharger des messages');
        dispatch(pendingDownload(false));
      }
      // const { content } = res.data;
      // const blob = new Blob([content], { type: 'application/csv' });
      // const url = window.URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.href = url;
      // a.download = `${new Date()
      //   .toISOString()
      //   .slice(0, -5)
      //   .replace(':', '-')}-messages.csv`;
      // a.click();
      // window.URL.revokeObjectURL(url);
      dispatch(messagesDownloadLink(res.data))
      dispatch(pendingDownload(false));
      return;
    })
    .catch(err => {
      alert(err);
      console.log(err);
      dispatch(pendingDownload(false));
      return;
    });
};
