import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class TableHeader extends React.PureComponent {
  render() {
    return (
      <TableHead>
        <TableRow>
          <TableCell>Nom</TableCell>
          <TableCell>Date et heure de début</TableCell>
          <TableCell>Terminé ?</TableCell>
          <TableCell>Somme à gagner</TableCell>
          <TableCell>Nombre de questions</TableCell>
          <TableCell>ID du live</TableCell>
          <TableCell>Messages</TableCell>
          <TableCell>Modifier</TableCell>
        </TableRow>
      </TableHead>
    );
  }
}
export default TableHeader;
