import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { hideModal } from '../../actions/modal';
import Modal from '../../components/modal';
import CreateSurvey from '../../screens/surveys';

const SurveyCreateModal = ({ title, quizId, afterClose, hideModal }) => {
  const onClose = () => {
    hideModal();

    if (afterClose) {
      afterClose();
    }
  };

  return (
    <Modal title={title} onClose={onClose}>
      <CreateSurvey quizId={quizId} onClose={onClose} />
    </Modal>
  );
};

SurveyCreateModal.propTypes = {
  title: PropTypes.string,
  quizId: PropTypes.string.isRequired,
};

export default connect(
  null,
  { hideModal }
)(SurveyCreateModal);
