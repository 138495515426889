const validate = values => {
  const errors = {};
  if (!values.email) errors.email = 'Required';
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))
    errors.email = 'Invalid email address';
  if (!values.password) errors.password = 'Please provide a password';
  return errors;
};

export default validate;
