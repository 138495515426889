import axios from 'axios';
import config from '../../../config';

export default async function stopQuiz(enqueueSnackbar, data) {
  try {
    await axios({
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        idToken: localStorage.getItem('firebaseToken'),
      },
      method: 'PUT',
      url: `${config.goServer}admin/quizzes`,
    });
    enqueueSnackbar(`Le quiz ${data._id} a été correctement arrêté`, { variant: 'success' });
  } catch (error) {
    enqueueSnackbar(error.message, { variant: 'error' });
  }
}
