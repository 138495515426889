import React from "react";
import styled from "styled-components";

import { CircleAnimation } from "../animation";
import { Title } from "../texts";

const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: ${props => (props.viewHeight ? `${props.viewHeight}vh` : "70vh")};
  width: 100%;
`;

class LoadingScreen extends React.Component {
  render() {
    const { content, viewHeight } = this.props;
    return (
      <LoadingContainer viewHeight={viewHeight}>
        <CircleAnimation color="mainColor" />
        <Title fontSize={34}>{content ? content : "CHARGEMENT EN COURS"}</Title>
      </LoadingContainer>
    );
  }
}

export default LoadingScreen;
