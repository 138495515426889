import SocketIOClient from 'socket.io-client';
import { liveControlEndPoint, isSurveyEnabled } from '../config';

class QuidolSocket {
  static socket;
  static state = 'off';
  static endpoint = liveControlEndPoint;
  static quizId;
  static listeners = [];
  static answersSent = {};
  static socketOptions = {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
    transports: ['websocket'],
  };
  static debug = true;

  get endpoint() {
    return QuidolSocket.endpoint;
  }

  get quizId() {
    return QuidolSocket.quizId;
  }

  get socket() {
    return QuidolSocket.quizId;
  }

  set endpoint(value) {
    return (QuidolSocket.endpoint = value);
  }

  set quizId(value) {
    return (QuidolSocket.quizId = value);
  }

  connect = async () => {
    const token = await localStorage.getItem('firebaseToken');
    if (!token) return;
    QuidolSocket.promise = new Promise((resolve, reject) => {
      if (QuidolSocket.socket && QuidolSocket.state === 'on' && QuidolSocket.socket.connected) return resolve(QuidolSocket.socket);
      if (QuidolSocket.socket && QuidolSocket.state === 'off') return resolve(QuidolSocket.promise);
      QuidolSocket.socket = SocketIOClient(QuidolSocket.endpoint, QuidolSocket.socketOptions);
      QuidolSocket.socket.on('error', e => {
        console.error(`[QuidolSocket] error: ${JSON.stringify(e)}`);
      });
      QuidolSocket.socket.on('connect_error', e => {
        console.error(`[QuidolSocket] connect_error: ${JSON.stringify(e)}`);
      });
      QuidolSocket.socket.on('connect', () => {
        console.log('################# connecting', QuidolSocket.state, !!QuidolSocket.socket, token);
        this.emitAsync({ event: 'authentication', payload: { token } })
          .then(() => {
            QuidolSocket.state = 'on';
            console.log('################# connected', QuidolSocket.state);
            return resolve(QuidolSocket.socket);
          })
          .catch(error => {
            QuidolSocket.socket = null;
            reject(error);
          });
      });
      QuidolSocket.socket.on('reconnect_attempt', () => {
        console.log('[RECOOOO] Attemp');
        // QuidolSocket.socket.io.opts.transports = ['polling', 'websocket'];
      });
    });
    return QuidolSocket.promise;
  };
  log = (...msg) => (QuidolSocket.debug ? console.log('[QuidolSocket] ', ...msg) : null);
  //management
  addListeners = async (channel, ...args) => {
    await this.connect();
    this.log('addListeners', channel);
    if (QuidolSocket.listeners.indexOf(channel) === -1) {
      QuidolSocket.listeners.push(channel);
      // QuidolSocket.reconnectListeners.push({ channel, ...args });
    }
    return null;
  };
  // EVENTS
  resetQuiz = async quizId => {
    await this.connect();
    this.log('resetQuiz');
    return this.emitAsync({
      event: 'ResetQuiz',
      payload: { quizId: quizId, isSurveyEnabled: isSurveyEnabled },
    });
  };
  getNextQuizzes = async () => {
    await this.connect();
    this.log('getNextQuizzes');
    return this.emitAsync({
      event: 'GetNextQuizzes',
      payload: { collectionType: 'live' },
    });
  };
  getCurrentQuizzes = async () => {
    await this.connect();
    this.log('getCurrentQuizzes');
    return this.emitAsync({
      event: 'GetQuizzes',
    });
  };
  getPlayersViewers = async () => {
    await this.connect();
    this.log('getPlayersViewers');
    const { data: nbPlayers } = await this.emitAsync({
      event: 'GetNbPlayersQuiz',
      payload: { quizId: QuidolSocket.quizId },
    });
    const { data: nbViewers } = await this.emitAsync({
      event: 'GetNbViewersQuiz',
      payload: { quizId: QuidolSocket.quizId },
    });
    return { nbPlayers, nbViewers };
  };
  joinQuiz = async quizId => {
    await this.connect();
    this.log('joinQuiz', quizId);
    QuidolSocket.quizId = quizId;
    QuidolSocket.answersSent = {};
    return this.emitAsync({
      event: 'JoinQuiz',
      payload: { quizId, admin: true },
    });
  };
  startLive = async (quizCollectionType = 'live') => {
    await this.connect();
    this.log('startLive', this.quizId);
    return this.emitAsync({
      event: 'StartLive',
      payload: { quizId: this.quizId, quizCollectionType },
    });
  };
  leaveQuiz = async () => {
    await this.connect();
    this.log('leaveQuiz', QuidolSocket.quizId);
    QuidolSocket.quizId = null;
    QuidolSocket.listeners.map(listener => QuidolSocket.socket.off(listener));
    QuidolSocket.listeners = [];
    QuidolSocket.answersSent = {};
    return this.emitAsync({
      event: 'LeaveQuizzes',
      payload: { quizId: this.quizId },
    });
  };
  endLive = async () => {
    await this.connect();
    this.log('endLive');
    return this.emitAsync({
      event: 'EndLive',
      payload: { quizId: this.quizId },
    });
  };
  stopLive = async () => {
    await this.connect();
    this.log('stoplive');
    return this.emitAsync({
      event: 'StopLive',
      payload: { quizId: this.quizId },
    });
  };
  getRandomViewer = async () => {
    await this.connect();
    this.log('getRandomUser');
    return this.emitAsync({
      event: 'GetRandomUser',
      payload: { quizId: this.quizId },
    });
  };
  showQuestion = async args => {
    await this.connect();
    this.log('showQuestion');
    return this.emitAsync({
      event: 'ShowQuestion',
      payload: { quizId: this.quizId, ...args },
    });
  };
  getCurrentQuestion = async args => {
    await this.connect();
    this.log('getCurrentQuestion');
    return this.emitAsync({
      event: 'GetCurrentAdminQuestion',
      payload: { quizId: this.quizId, ...args },
    });
  };
  showAnswer = async args => {
    await this.connect();
    this.log('showAnswer');
    return this.emitAsync({
      event: 'ShowAnswers',
      payload: { quizId: this.quizId, ...args },
    });
  };
  retrievePlayers = async args => {
    await this.connect();
    console.log('RETRIEVEPLAYERS');
    this.log('RetrievePlayers');
    return this.emitAsync({
      event: 'RetrievePlayers',
      payload: { quizId: this.quizId, ...args },
    });
  };
  rewardPlayers = async args => {
    await this.connect();
    console.log('Reward Players');
    this.log('RewardPlayers');
    return this.emitAsync({
      event: 'RewardPlayers',
      payload: { quizId: this.quizId, ...args },
    });
  };
  getAnswers = async () => {
    await this.connect();
    this.log('getAnswers', QuidolSocket.quizId);
    return this.emitAsync({
      event: 'GetAnswers',
      payload: { quizId: this.quizId },
    });
  };
  getUserStatus = async () => {
    await this.connect();
    this.log('getUserStatus', QuidolSocket.quizId);
    return this.emitAsync({
      event: 'GetUserStatus',
      payload: { quizId: this.quizId },
    });
  };
  getQuestions = async () => {
    await this.connect();
    this.log('getQuestions', QuidolSocket.quizId);
    return this.emitAsync({
      event: 'GetQuestionsShow',
      payload: { quizId: this.quizId },
    });
  };
  sendQuestionSurvey = async question => {
    await this.connect();
    this.log('sendQuestionSurvey', QuidolSocket.quizId);
    return this.emitAsync({
      event: 'ShowQuestionStandalone',
      payload: { quizId: this.quizId, question: { ...question, isViewerAllowed: true } },
    });
  };
  setAnswer = async (questionId, answer) => {
    await this.connect();
    if (QuidolSocket.answersSent[questionId] && QuidolSocket.answersSent[questionId].answer === answer.answer) return Promise.resolve();
    this.log('setAnswer', questionId, answer);
    return this.emitAsync({
      event: 'Answer',
      payload: { quizId: this.quizId, questionId, answer },
    }).then(res => {
      if (res && res.code === 200) QuidolSocket.answersSent[questionId] = answer;
      return res;
    });
  };

  //LISTENERS
  offOn = async (event, callback) => {
    await QuidolSocket.socket.off(event);
    return QuidolSocket.socket.on(event, callback);
  };
  /* eslint-disable */
  emitAsync = ({ event, payload, expectedValues = [{ code: 200 }] }) =>
    new Promise((res2, rej2) => {
      const args = [];
      if (event) args.push(event);
      if (payload) args.push(payload);

      QuidolSocket.socket.emit(...args, res => {
        const expectedLength = expectedValues.reduce((acc, item) => {
          const attr = Object.keys(item)[0];
          return res[attr] === item[attr] ? acc + 1 : acc;
        }, 0);
        if (res.code && res.code === 403) {
          localStorage.removeItem('firebaseToken');
          localStorage.removeItem('refreshToken');
          window.location = '/login';
        }
        if (expectedLength !== expectedValues.length) {
          return rej2(
            new Error(`One or more values are not found ( ${event}: ${JSON.stringify(expectedValues)}) in ${JSON.stringify(res)}`)
          );
        }
        return res2(res);
      });
    });
  /* eslint-enable */
  onLiveStarted = async callback => {
    await this.connect();
    this.log('onLiveStarted', callback);
    return this.offOn('StartLive', callback);
  };
  onLiveEnded = async callback => {
    await this.connect();
    this.log('onLiveEnded');
    this.addListeners('EndLive', callback);
    return this.offOn('EndLive', callback);
  };
  onShowQuestionExecuted = async callback => {
    await this.connect();
    this.log('onShowQuestionExecuted', callback);
    this.addListeners('ShowQuestionExecuted');
    return this.offOn('ShowQuestionExecuted', callback);
  };
  onShowAnswerExecuted = async callback => {
    await this.connect();
    this.log('onShowAnswerExecuted');
    this.addListeners('ShowAnswerExecuted', callback);
    return this.offOn('ShowAnswerExecuted', callback);
  };
  onPlayersViewers = async callback => {
    await this.connect();
    this.log('onPlayersViewers');
    this.addListeners('GetPlayersViewers', callback);
    return this.offOn('GetPlayersViewers', callback);
  };
}

const quidolSocket = new QuidolSocket();
export default quidolSocket;
