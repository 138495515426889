import React from 'react';
import styled from 'styled-components';

import { CommonText } from '../texts';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Participations = styled.div`
  border-radius: 50px;
  background-color: ${props => props.theme.color.messagesParticipation};
  margin: 0 10px 10px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ParticipationsNumber = styled(CommonText)`
  font-weight: lighter;
  font-size: 19px;
  color: white;
`;

const Desc = styled.div`
  display: flex;
  text-align: center;
  justify-content: space-around;
  margin: 10px 0;
`;
const ShortCut = styled.div`
  color: #7f7f7f;
`;
const KeyLabel = styled.div`
  font-size: 12px;
`;
const Key = styled.div`
  font-size: 13px;
  font-weight: bold;
`;

class MessagesHeader extends React.PureComponent {
  render() {
    const { numberOfMessages, mode } = this.props;
    return (
      <Header>
        <Head>
          <Participations>
            <ParticipationsNumber>{numberOfMessages || 0}</ParticipationsNumber>
          </Participations>
          <CommonText> Participations</CommonText>
        </Head>
        <Desc>
          <ShortCut>
            <KeyLabel>Augmenter / diminuer la taille de la police :</KeyLabel>
            <Key>1 | 2</Key>
          </ShortCut>
          {mode !== 'readonly' ? (
            <ShortCut>
              <KeyLabel>Augmenter / diminuer le délais:</KeyLabel>
              <Key>&rarr; | &larr;</Key>
            </ShortCut>
          ) : null}
          <ShortCut>
            <KeyLabel>Remonter / Descendre :</KeyLabel>
            <Key>&uarr; | &darr;</Key>
          </ShortCut>
          {mode !== 'readonly' ? (
            <ShortCut>
              <KeyLabel>Pause:</KeyLabel>
              <Key>espace</Key>
            </ShortCut>
          ) : null}
        </Desc>
      </Header>
    );
  }
}

export default MessagesHeader;
