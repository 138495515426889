import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import styled from 'styled-components';

const StyledTableCell = styled(TableCell)`
  font-weight: bold;
  color: black;
  font-size: 1rem;
`;

class TableHeader extends React.PureComponent {
  render() {
    return (
      <TableHead>
        <TableRow>
          <StyledTableCell>Nom de la musique</StyledTableCell>
          <StyledTableCell>Choisir cette musique</StyledTableCell>
          <StyledTableCell>Écouter la l'original</StyledTableCell>
          <StyledTableCell>Écouter la transcodé</StyledTableCell>
          <StyledTableCell>Date de création</StyledTableCell>
          <StyledTableCell>Supprimer une musique</StyledTableCell>
        </TableRow>
      </TableHead>
    );
  }
}
export default TableHeader;
