import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';
import styled from 'styled-components';

import PresenterContent from '../components/presenterContent';
import PresenterHeader from '../components/presenterHeader';

import allTheActions from '../actions';

const PresenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
`;

class Presenter extends React.PureComponent {
  componentDidMount() {
    const { actions } = this.props;
    actions.presenter.watchQuiz();
  }

  render() {
    const { currentQuiz, currentQuestion, nbPlayers, nbViewers, questionStates } = this.props.presenter;
    const wrongAnswersCount = (currentQuestion.answers || []).reduce((acc, answer) => (acc + (answer.isRight ? 0 : answer.nbAnswers || 0)), 0);

    const nbLoosers = wrongAnswersCount + Number(currentQuestion.timeoutAnswersCount);

    if (currentQuiz.versionType === 'liveV2') {
      return (
        <PresenterContainer>
          <PresenterHeader
            questionLabel={null}
            questionStates={[null]}
            gainPerWinner={currentQuestion.prizePerWinner}
            isQuestionEnded={false}
            nbPlayers={0}
            nbViewers={0}
            nbLoosers={0}
            order={null}
            questionId={currentQuestion.id}
          />
          <PresenterContent data={{ answers: null }} nbPlayers={0} quiz={{ timeoutAnswersCount: null }} />
        </PresenterContainer>
      )
    }
    return (
      <PresenterContainer>
        <PresenterHeader
          questionLabel={currentQuestion.label}
          questionStates={questionStates}
          gainPerWinner={currentQuestion.prizePerWinner}
          isQuestionEnded={currentQuestion.isExpired}
          nbPlayers={nbPlayers}
          nbViewers={nbViewers}
          nbLoosers={nbLoosers}
          order={currentQuestion.order}
          questionId={currentQuestion.id}
        />
        <PresenterContent data={currentQuestion} nbPlayers={currentQuiz.nbPlayers} quiz={currentQuiz} />
      </PresenterContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    presenter: state.presenter,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    presenter: bindActionCreators(allTheActions.presenter, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Presenter);
