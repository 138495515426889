// TODO CONFIG

const config = {
  name: 'el Quizz',
  firebase_config: {
    apiKey: 'AIzaSyB9ywAIVuW4wYgGELfa1vi0Hax-hiowqik',
    authDomain: 'el-quiz.firebaseapp.com',
    databaseURL: 'https://el-quiz.firebaseio.com',
    projectId: 'el-quiz',
    storageBucket: 'el-quiz.appspot.com',
    messagingSenderId: '715490829333',
    appId: '1:715490829333:web:fa05a0cb58c5221e4b5a1c'
  },
  dev: false,
  cloudHttps: 'https://europe-west1-el-quiz.cloudfunctions.net/',
  backgroundColor: '#0097a7',
  templateQuestionCsv: 'https://firebasestorage.googleapis.com/v0/b/quidol-146ff.appspot.com/o/backOfficeUploads%2FquizzesCsv%2FtemplateQuestions.csv?alt=media&token=23ac0289-d9c9-4960-aa11-eb7e67221387',
  templateQuizzesCsv: 'https://firebasestorage.googleapis.com/v0/b/quidol-146ff.appspot.com/o/backOfficeUploads%2FquizzesCsv%2FtemplateQuizzes.csv?alt=media&token=50f6bdec-9ccb-4eab-a273-b2b962f8aa2c',
  templateGoCsv: 'https://firebasestorage.googleapis.com/v0/b/el-quiz.appspot.com/o/backOfficeUploads%2FgoFun%2Ftemplate.csv?alt=media&token=98085444-8171-4bb6-87e9-989fbbcafe75',
  firebaseLoad: () => require('./firebase'),
  version: '1.0.0',
  author: 'EL QUIZ <el-quiz>',
  defaultLiveVideoUri: 'default',
  defaultBackOffice: false,
  enableAnswers: true,
  enableMessageHistory: true,
  enableMessages: true,
  enableUserDelete: true,
  enableUserDetails: true,
  numberJokersNext: 2,
  showQuizType: true,
  defaultNumberOfQuestions: 10,
  paymentMethod: { iban: true, paypal: true },
  isSurveyEnabled: true,
  isGetRandomViewer: true,
  fileUpload: { enable: true, type: 'csv' },
  achievements: true,
  achievementsServer: 'https://achievement.elquizz.com/',
  goServer: 'https://go-rest.elquizz.com/',
  quizzesServer: "https://quizzes.elquizz.com/",
  storageServer: "https://storage.elquizz.com/",
  userServer: 'https://user-rest.elquizz.com/',
  millisecondsBonusQuestionMusic: 8000,
  socketTVEndPoint: 'wss://tv-api.elquizz.com',
  liveControlEndPoint: 'wss://live-control.elquizz.com:3000', 
  defaultQuestionDuration: 12000,
};

module.exports = config;
