import {
  BROADCAST,
  CURRENT_QUESTION_ID,
  CURRENT_QUESTION_PROCESSED,
  CURRENT_QUESTION,
  CURRENT_QUIZ,
  DISPLAY_CHUNKTIME,
  DISPLAY_CURRENT_QUESTION,
  RANDOM_VIEWER,
  LOGS_CHUNKTIME,
  NB_PLAYERS,
  NB_VIEWERS,
  PENDING,
  QUESTION_ENDED,
  QUESTION_FETCHING,
  QUESTION_STARTED,
  UPDATE_TIME,
} from '../actions/controls';

// TODO: voir les reducers non utilisés
const initialState = {
  currentQuestion: {},
  currentQuestionId: 0,
  currentQuiz: [],
  randomViewer: null,
  isBroadcasting: false,
  isLoading: false,
  isQuestionEnded: false,
  isQuestionFetching: false,
  isQuestionStarted: false,
  logsChunkTime: [],
  videoChunkTime: {
    chunkTime: new Date().getTime(),
    //FIXME: ????
    chunkSeenAt: new Date().getTime()
  },
  time: {}
};

const isLastQuestion = (state, action) => {
  if (!state.currentQuiz || action.payload >= state.currentQuiz.length) {
    return { ...state };
  } else {
    return {
      ...state,
      currentQuestion: state.currentQuiz[action.payload],
      currentQuestionIndex: action.payload
    };
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PENDING:
      return {
        ...state,
        isLoading: action.payload
      };
    case BROADCAST: {
      return {
        ...state,
        isBroadcasting: action.payload
      };
    }
    case LOGS_CHUNKTIME: {
      return {
        ...state,
        logsChunkTime: [action.payload, ...state.logsChunkTime]
      };
    }
    case NB_VIEWERS: {
      return {
        ...state,
        nbViewers: action.payload
      };
    }
    case NB_PLAYERS: {
      return {
        ...state,
        nbPlayers: action.payload
      };
    }
    case QUESTION_STARTED: {
      return {
        ...state,
        isQuestionStarted: action.payload
      };
    }
    case QUESTION_ENDED: {
      return {
        ...state,
        isQuestionEnded: action.payload
      };
    }
    case QUESTION_FETCHING: {
      return {
        ...state,
        isQuestionFetching: action.payload
      };
    }
    case CURRENT_QUESTION: {
      return isLastQuestion(state, action);
    }
    case CURRENT_QUESTION_ID: {
      return {
        ...state,
        currentQuestionId: action.payload
      };
    }
    case DISPLAY_CURRENT_QUESTION: {
      return {
        ...state,
        currentQuestion: action.payload
      };
    }
    case RANDOM_VIEWER: {
      return {
        ...state,
        randomViewer: action.payload
      };
    }
    case CURRENT_QUESTION_PROCESSED: {
      return {
        ...state,
        currentQuestion: action.payload
      };
    }
    case CURRENT_QUIZ: {
      return {
        ...state,
        currentQuiz: action.payload
      };
    }
    case DISPLAY_CHUNKTIME: {
      return {
        ...state,
        videoChunkTime: {
          chunkTime: action.payload,
          chunkSeenAt: Date.now() + (state.time.delta || 0)
        }
      };
    }
    case UPDATE_TIME: {
      return {
        ...state,
        time: action.payload
      };
    }
    default:
      return state;
  }
};
