import config from '../../config';

const quizCreateInitialState = {
  addPlayers: config.addPlayers || 0,
  quizType: 'live',
  maxJokersCopycat: config.numberJokersCopyCat,
  maxJokersNext: config.numberJokersNext,
  nbQuestions: config.defaultNumberOfQuestions,
  prizeType: 'money',
  prizepool: 100000,
  player: '*',
  controller: '',
  programName: 'El Quiz Live',
  subtitle: '',
  theme: '',
  goLive: false,
};

export default quizCreateInitialState;
