import { Link } from 'react-router-dom';
import { Menu } from '@material-ui/icons';
import { push as MenuBurger } from 'react-burger-menu';
import React from 'react';
import styled from 'styled-components';

import { history } from '../../config/store';
import { config } from '../../config/firebase';
import RowMenu from '../rowMenu';

// fixes the invisible hamburger bug on react-burger-menu package
import './index.css';

const StyledLink = styled(Link)`
  text-decoration: none;
`;
const StyledMenu = styled(MenuBurger)`
  background-color: #202020;
  width: 300px;
`;

const LogoutButton = styled(RowMenu)`
  background-color: ${props => props.theme.color.logoutButtonColor};
  bottom: 60px;
  position: absolute;
  user-select: none;
  &:hover {
    background-color: ${props => props.theme.color.logoutButtonHoverColor};
  }
`;

const HollowMenu = styled.div`
  display: flex;
  flex-shrink: 0;
  height: 100%;
  transition: all 0.5s ease 0s;
  width: ${props => (props.isMenuOpen ? '300px' : '0px')};
`;

class BurgerMenu extends React.Component {
  state = {
    isMenuOpen: false,
  };

  logOut = () => {
    // admin.auth().signOut();
    localStorage.removeItem('firebaseToken');
    localStorage.removeItem('refreshToken');
    history.push('/');
  };

  isMenuOpen = state => {
    this.setState({ isMenuOpen: state.isOpen });
  };

  render() {
    return (
      <React.Fragment>
        <StyledMenu noOverlay customBurgerIcon={<Menu />} customCrossIcon={false} onStateChange={this.isMenuOpen}>
          <StyledLink to="/home/quizzes">
            <RowMenu principal name="Quiz" />
          </StyledLink>
          <StyledLink to="/home/controls">
            <RowMenu name="Gestion du live" />
          </StyledLink>
          <StyledLink to="/home/users">
            <RowMenu name="Utilisateurs" />
          </StyledLink>
          <StyledLink to="/home/presenter">
            <RowMenu name="Vue présentateur" />
          </StyledLink>
          <StyledLink to="/home/winners">
            <RowMenu name="Vainqueurs & paiements" />
          </StyledLink>
          <StyledLink to="/home/goQuizzes">
            <RowMenu name="Go Fun" />
          </StyledLink>
          <StyledLink to="/home/answer">
            <RowMenu name="Réponses" />
          </StyledLink>

          {config.enableMessages ? (
            <StyledLink to="/home/messages">
              <RowMenu name="Messages" />
            </StyledLink>
          ) : null}
          {config.enableMessageHistory ? (
            <StyledLink to="/home/messagesHistory">
              <RowMenu name="Historique des messages" />
            </StyledLink>
          ) : null}

          <StyledLink to="/home/replays">
            <RowMenu name="Contenus vidéo" />
          </StyledLink>
          <StyledLink to="/home/surveys">
            <RowMenu name="Sondages & questions bonus" />
          </StyledLink>
          <StyledLink to="/home/wording">
            <RowMenu name="Wording" />
          </StyledLink>
          <StyledLink to="/home/admin">
            <RowMenu name="Administration" />
          </StyledLink>
            <div style={{height: '100px'}}/>
            <LogoutButton
              disableIcon
              color="black"
              onClick={this.logOut}
              name="Déconnexion"
            />
        </StyledMenu>
        <HollowMenu isMenuOpen={this.state.isMenuOpen} />
      </React.Fragment>
    );
  }
}

export default BurgerMenu;
