import axios from 'axios';
import { storageServer } from '../config';

export const SET_CARD_LIST = 'SET_CARD_LIST';
export const DELETE_CARD = 'DELETE_CARD';
export const CREATE_CARD = 'CREATE_CARD';
export const EDIT_CARD = 'EDIT_CARD';
export const RESET_SAVE_STATE = 'RESET_SAVE_STATE';
export const PENDING = 'PENDING';

export const setCardsList = cardsList => ({
  type: SET_CARD_LIST,
  payload: cardsList,
});

export const pending = payload => ({
  type: PENDING,
  payload,
});

export const deleteCard = (cardType, index) => ({
  type: DELETE_CARD,
  cardType,
  index,
});

export const createCard = (cardType, card) => ({
  type: CREATE_CARD,
  cardType,
  card
})

export const editCard = (index, card) => ({
  type: EDIT_CARD,
  card,
  index,
})

export const resetOrSave = () => ({
  type: RESET_SAVE_STATE,
})

export const getCardsList = () => async dispatch => {
  dispatch(pending(true));
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${storageServer}getCards`,
    });
    dispatch(setCardsList(data.dashboard));
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(pending(false));
  }
};

export const createCards = cardsList => async dispatch => {
  dispatch(pending(true));
  try {
    const { data, status } = await axios({
      method: 'POST',
      url: `${storageServer}postCards`,
      data: cardsList,
    });
    return { status, data }
  } catch (e) {
    return {status: 403, error: e.message}
  } finally {
    dispatch(pending(false));
  }
};
