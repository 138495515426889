import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Collapsible from 'react-collapsible';
import idx from 'idx';
import React, { Component } from 'react';
import styled from 'styled-components';

import ControlsContent from '../components/controlsContent';
import ControlsSurvey from '../components/controlsSurvey';
import ControlsFooter from '../components/controlsFooter';
import ControlsHeader from '../components/controlsHeader';
import LoadingScreen from '../components/loadingScreen';
import LogsChunk from '../components/logsChunk';
import ModalRoot from '../components/modal/modalRoot';

import allTheActions from '../actions';
import { isSurveyEnabled } from '../config';

const CollapsibleStyled = styled(Collapsible)`
  cursor: pointer;
  margin: 10px;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px;
  width: 100%;
`;

class Controls extends Component {
  componentDidMount() {
    const { actions } = this.props;
    const quizId = this.props.match.params.id;
    actions.controls.getTime();
    actions.controls.watchQuiz(quizId);
  };

  render() {
    const { actions, controls, isLoading, randomViewer, surveys } = this.props;
    if (!controls.currentQuestion.id || !controls.currentQuiz) {
      return <LoadingScreen />;
    }

    return (
      <ControlsContainer>
        <ControlsHeader
          actions={actions}
          currentQuestion={controls.currentQuestion}
          isBroadcasting={controls.isBroadcasting}
          isLoading={isLoading}
          isQuestionEnded={controls.isQuestionEnded}
          nbPlayers={controls.nbPlayers}
          nbViewers={controls.nbViewers}
          quizName={controls.currentQuiz.name}
          id={controls.currentQuiz.id}
          versionType={controls.currentQuiz.versionType}
        />
        <ControlsContent
          actions={actions}
          answerHideDelay={6000}
          currentQuestion={controls.currentQuestion}
          isBroadcasting={controls.isBroadcasting}
          isQuestionStarted={controls.isQuestionStarted}
          isQuestionEnded={controls.isQuestionEnded}
          isQuestionFetching={controls.isQuestionFetching}
          maxJokersNext={controls.currentQuiz.maxJokersNext}
          maxJokersCopycat={controls.currentQuiz.maxJokersCopycat}
          previousQuestion={controls.currentQuestion.order - 1}
          survey={controls.survey}
          quizId={idx(this.props, _ => _.controls.currentQuiz.id) ? this.props.controls.currentQuiz.id : ''}
          urlStream={idx(this.props, _ => _.controls.currentQuiz.liveVideoUri.ios) ? this.props.controls.currentQuiz.liveVideoUri.ios : ''}
          videoChunkTime={controls.videoChunkTime}
        />
        <ControlsFooter
          actions={actions}
          currentSurvey={controls.currentSurvey}
          page={idx(this.props, _ => _.controls.currentQuiz.nbQuestions) ? this.props.controls.currentQuiz.nbQuestions : 0}
          currentPage={controls.currentQuestion.order - 1}
        />
        {isSurveyEnabled && (
          <ControlsSurvey
            actions={actions}
            currentSurvey={surveys.currentSurvey}
            currentQuestion={controls.currentQuestion}
            previousQuestion={controls.currentQuestion.order - 1}
            videoChunkTime={controls.videoChunkTime}
            randomViewer={randomViewer}
            quizId={idx(this.props, _ => _.controls.currentQuiz.id) ? this.props.controls.currentQuiz.id : null}
          />
        )}
        <CollapsibleStyled trigger="Afficher les détails">
          <LogsChunk logs={this.props.controls.logsChunkTime} currentQuestion={controls.currentQuestion.order - 1} />
        </CollapsibleStyled>
        <ModalRoot />
      </ControlsContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    controls: state.controls,
    surveys: state.surveys,
    isLoading: state.controls.isLoading,
    notFound: state.controls.notFound,
    randomViewer: state.controls.randomViewer,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    controls: bindActionCreators(allTheActions.controls, dispatch),
    quizzes: bindActionCreators(allTheActions.quizzes, dispatch),
    survey: bindActionCreators(allTheActions.surveys, dispatch),
    showModal: bindActionCreators(allTheActions.modal.showModal, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controls);
