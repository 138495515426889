import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const ButtonsContainer = styled.div`
  display: flex;
`;

export const ButtonText = styled.span`
 text-align: center;
`;

export const LineContainer = styled.tr`
  align-items: center;
  border-style: solid;
  border-color: gray;
  border-width: 1px;
  display: flex;
  justify-content: space-between;
  padding: 1em;
  width: 100%;
`;

export const Spacing = styled.div`
  width: 1em;
`;

export const StyledButton = styled(Button)`
  height: 100%;
  margin: 20px 0 0 40px;
`;

export const StyledText = styled.span`
  margin-right: 1em;
`;
