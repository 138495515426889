import { history } from './store';
import axios from 'axios';

axios.interceptors.request.use(
  config => {
    // Do something before request is sent
    config.headers.common['idToken'] = localStorage.getItem('firebaseToken');
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// intercept the responses sent by the server and delog the users if he is not authorised
axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error && error.response && 401 === error.response.status) {
      // admin.auth().signOut();
      history.push('/');
    } else {
      return Promise.reject(error);
    }
  }
);
