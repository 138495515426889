import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import allTheActions from '../actions';

import { WinnersTable } from '../components/winnersTable';

class WinnersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      length: 0,
      limit: 100,
      page: 0,
      searchTerm: null,
    };
  }
  clearLink = async () => {
    const {
      actions: { winners }
    } = this.props;
    await winners.winnersClearLink();
  };
  componentDidMount = async () => {
    await this.getSeasons();
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { link } = nextProps;
    if (link) {
      window.open(link, '_blank');
      this.clearLink();
    }
  }
  downloadIbanWinners = async (searchTerm, from, to) => {
    const {
      actions: { winners }
    } = this.props;
    await winners.downloadIbanWinners(searchTerm, from, to);
  };
  downloadPaypalWinners = async searchText => {
    const {
      actions: { winners }
    } = this.props;
    await winners.downloadPaypalWinners();
  };
  downloadWinners = async (getPhoneNumber, searchText) => {
    const {
      actions: { winners }
    } = this.props;
    await winners.downloadWinners(getPhoneNumber, searchText);
  };
  getSeasons = async () => {
    const {
      actions: { seasons }
    } = this.props;
    seasons.getSeasons();
  };

  render() {
    const { page, limit, userId } = this.state;
    const { seasons } = this.props;
    const {
      length,
      isLoading,
      winners,
      pendingDownload,
      pendingDownloadIban,
      pendingDownloadPaypal,
      pendingRevertUniqWinner,
      pendingRevertUniqWinnerId,
      pendingValidateIban,
      pendingValidatePaypal,
      pendingValidateUniqIban,
      pendingValidateUniqIbanWinnerId,
      pendingValidateUniqPaypal,
      pendingValidateUniqPaypalWinnerId
    } = this.props;
    return (
      <WinnersTable
        actions={this.props.actions}
        downloadIbanWinners={this.downloadIbanWinners}
        downloadPaypalWinners={this.downloadPaypalWinners}
        downloadWinners={this.downloadWinners}
        length={length}
        page={page}
        limit={limit}
        winners={winners}
        seasons={seasons}
        userId={userId}
        getListPage={this.getListPage}
        getWinners={this.getWinners}
        handleChangeUserId={this.handleChangeUserId}
        isLoading={isLoading}
        paypalPayment={this.paypalPayment}
        pendingDownload={pendingDownload}
        pendingDownloadIban={pendingDownloadIban}
        pendingDownloadPaypal={pendingDownloadPaypal}
        pendingRevertUniqWinner={pendingRevertUniqWinner}
        pendingRevertUniqWinnerId={pendingRevertUniqWinnerId}
        pendingValidateIban={pendingValidateIban}
        pendingValidatePaypal={pendingValidatePaypal}
        pendingValidateUniqIban={pendingValidateUniqIban}
        pendingValidateUniqIbanWinnerId={pendingValidateUniqIbanWinnerId}
        pendingValidateUniqPaypal={pendingValidateUniqPaypal}
        pendingValidateUniqPaypalWinnerId={pendingValidateUniqPaypalWinnerId}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    length: state.winners.length,
    link: state.winners.link,
    isLoading: state.winners.isLoading,
    pendingDownload: state.winners.pendingDownload,
    pendingDownloadIban: state.winners.pendingDownloadIban,
    pendingDownloadPaypal: state.winners.pendingDownloadPaypal,
    pendingRevertUniqWinner: state.winners.pendingRevertUniqWinner,
    pendingRevertUniqWinnerId: state.winners.pendingRevertUniqWinnerId,
    pendingValidateIban: state.winners.pendingValidateIban,
    pendingValidatePaypal: state.winners.pendingValidatePaypal,
    pendingValidateUniqIban: state.winners.pendingValidateUniqIban,
    pendingValidateUniqIbanWinnerId:
      state.winners.pendingValidateUniqIbanWinnerId,
    pendingValidateUniqPaypal: state.winners.pendingValidateUniqPaypal,
    pendingValidateUniqPaypalWinnerId:
      state.winners.pendingValidateUniqPaypalWinnerId,
    winners: state.winners.winners,
    seasons: state.seasons.seasons,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    winners: bindActionCreators(allTheActions.winners, dispatch),
    seasons: bindActionCreators(allTheActions.seasons, dispatch),
  }
});

WinnersList = connect(
  mapStateToProps,
  mapDispatchToProps
)(WinnersList);

export default WinnersList;
