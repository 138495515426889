import React, { Component } from 'react';
import { Route, Switch } from 'react-router';

import WinnersList from './winnersList';

class Winners extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/home/winners" component={WinnersList} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default Winners;
