import { RadioButtonGroup } from "material-ui/RadioButton";
import React from "react";
import styled from "styled-components";

const RadioButtonGroupStyled = styled(RadioButtonGroup)`
  display: flex;
  width: 300px;
`;

const renderRadioGroup = ({ input, onChange, ...rest }) => (
  <RadioButtonGroupStyled
    {...input}
    {...rest}
    onChange={(event, value) => input.onChange(event)}
    valueSelected={input.value}
  />
);

export default renderRadioGroup;
