import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';

import AddButton from './components/AddButton';
import Table from './components/Table';
import getAdmin from './requests/get';
import { AdminContainer } from './styles';

export default function Admin() {
  const enqueueSnackbar = useSnackbar().enqueueSnackbar;
  const [allAdmin, setAllAdmin] = useState([]);
  let { current: getAllAdmin } = useRef(async function() {
    const { data } = await getAdmin(enqueueSnackbar);
    if (data) setAllAdmin(data);
  });
  useEffect(() => {
    getAllAdmin();
  }, [getAllAdmin]);
  return (
    <AdminContainer>
      <AddButton getAllAdmin={getAllAdmin} />
      <Table allAdmin={allAdmin} getAllAdmin={getAllAdmin} />
    </AdminContainer>
  );
}
