import { stringRegExp } from '../../utils/allRegexp';

const validate = values => {
  if ((!values.normalQuestions && !values.freeQuestions) || !values.header || !values.panel) {
    return;
  }
  const errors = {
    freeQuestions: {},
    header: {},
    normalQuestions: {
      A: {},
      B: {},
      C: {},
      D: {}
    },
    question: {},
    panel: {},
  };
  if (!values.question.dz) {
    errors.question.dz = 'Veuillez choisir une question';
  }
  if (!values.question.fr) {
    errors.question.fr = 'Veuillez choisir une question';
  }
  if (!stringRegExp.test(values.question.fr)) {
    errors.question.fr = 'Veuillez entrer une question valide (pas d\'espaces de début ou de fin)';
  }
  if (/^\s|\s$/.test(values.question.dz)) {
    errors.question.dz = 'Veuillez entrer une question valide (pas d\'espaces de début ou de fin)';
  }

  if (
    !!values.music === true && values.musicUrl === null
  ) {
    errors.question = 'Veuillez choisir une musique pour cette question ou veuillez mettre à off "Question avec musique" ';
  }

  // Panel Form
  if (values.panel) {
    if (!values.panel.name) {
      errors.panel.name = 'Please enter a name ';
    }
    if (!stringRegExp.test(values.panel.name)) {
      errors.panel.name = 'Veuillez entrer un nom valide (pas d\'espaces de début ou de fin)';
    }
    if (!stringRegExp.test(values.panel.subtitle)) {
      errors.panel.subtitle = 'Veuillez entrer un sous-titre valide (pas d\'espaces de début ou de fin)';
    }
    if (!values.panel.startAt) {
      errors.panel.startAt = 'startAt ne peut pas être vide';
    }
    if (!values.panel.inAppStartAt) {
      errors.panel.inAppStartAt = 'inAppStartAt ne peut pas être vide';
    }
    if (!values.panel.inAppEndAt) {
      errors.panel.inAppEndAt = 'inAppEndAt ne peut pas être vide';
    }
    if (
      values.panel.nbQuestions <= 0 ||
      values.panel.nbQuestions > 100 ||
      !values.panel.nbQuestions
    ) {
      errors.panel.nbQuestions = 'Veuillez saisir un nombre compris entre 1 et 100';
    }
    if (
      values.panel.maxJokersCopycat < 0 ||
      (!values.panel.maxJokersCopycat && values.panel.maxJokersCopycat !== 0)
    ) {
      errors.panel.maxJokersCopycat = 'Please enter a number starting from 0';
    }
    if (
      values.panel.maxJokersNext < 0 ||
      (!values.panel.maxJokersNext && values.panel.maxJokersNext !== 0)
    ) {
      errors.panel.maxJokersNext = 'Veuillez saisir un nombre à partir de 0';
    }
    if (!values.panel.android) {
      errors.panel.android = 'Veuillez saisir une URL';
    }
    if (!values.panel.ios) {
      errors.panel.ios = 'Veuillez saisir une URL';
    }
    if (
      values.panel.delayHideAnswer < 0 ||
      (!values.panel.delayHideAnswer && values.panel.delayHideAnswer !== 0)
    ) {
      errors.panel.delayHideAnswer = 'Veuillez saisir un nombre à partir de 0';
    }
    if (
      values.panel.resyncDelay < 0 ||
      (!values.panel.resyncDelay && values.panel.resyncDelay !== 0)
    ) {
      errors.panel.resyncDelay = 'Veuillez saisir un nombre à partir de 0';
    }
    if (values.panel.prizepool < 0 || (!values.panel.prizepool && values.panel.prizepool !== 0)) {
      errors.panel.prizepool = 'Veuillez saisir un nombre à partir de 0';
    }
  }

  // Normal Questions
  console.log('NORMAL QUESTION:::');
  console.log(values.normalQuestions);
  if (values.normalQuestions) {
    if (!values.normalQuestions.A.fr) {
      errors.normalQuestions.A.fr = 'Veuillez entrer une réponse';
    }
    if (!stringRegExp.test(values.normalQuestions.A.fr)) {
      errors.normalQuestions.A.fr = 'Veuillez entrer une question valide (pas d\'espaces de début ou de fin)';
    }
    if (!values.normalQuestions.B.fr) {
      errors.normalQuestions.B.fr = 'Veuillez entrer une réponse';
    }
    if (!stringRegExp.test(values.normalQuestions.B.fr)) {
      errors.normalQuestions.B.fr = 'Veuillez entrer une question valide (pas d\'espaces de début ou de fin)';
    }
    if (!values.normalQuestions.C.fr) {
      errors.normalQuestions.C.fr = 'Veuillez entrer une réponse';
    }
    if (!stringRegExp.test(values.normalQuestions.C.fr)) {
      errors.normalQuestions.C.fr = 'Veuillez entrer une question valide (pas d\'espaces de début ou de fin)';
    }
    if (!values.normalQuestions.D.fr) {
      errors.normalQuestions.D.fr = 'Veuillez entrer une réponse';
    }
    if (!stringRegExp.test(values.normalQuestions.D.fr)) {
      errors.normalQuestions.D.fr = 'Veuillez entrer une question valide (pas d\'espaces de début ou de fin)';
    }

    if (/^\s|\s$/.test(values.normalQuestions.A.dz)){
      errors.normalQuestions.A.dz = 'Veuillez entrer une question valide (pas d\'espaces de début ou de fin)';
    }
    if (/^\s|\s$/.test(values.normalQuestions.B.dz)){
      errors.normalQuestions.B.dz = 'Veuillez entrer une question valide (pas d\'espaces de début ou de fin)';
    }
    if (/^\s|\s$/.test(values.normalQuestions.C.dz)){
      errors.normalQuestions.C.dz = 'Veuillez entrer une question valide (pas d\'espaces de début ou de fin)';
    }
    if (/^\s|\s$/.test(values.normalQuestions.D.dz)){
      errors.normalQuestions.D.dz = 'Veuillez entrer une question valide (pas d\'espaces de début ou de fin)';
    }

    if (!values.normalQuestions.A.dz) {
      errors.normalQuestions.A.dz = 'Veuillez entrer une réponse';
    }
    if (!values.normalQuestions.B.dz) {
      errors.normalQuestions.B.dz = 'Veuillez entrer une réponse';
    }
    if (!values.normalQuestions.C.dz) {
      errors.normalQuestions.C.dz = 'Veuillez entrer une réponse';
    }
    if (!values.normalQuestions.D.dz) {
      errors.normalQuestions.D.dz = 'Veuillez entrer une réponse';
    }
    if (
      !values.normalQuestions.A.Switch &&
      !values.normalQuestions.B.Switch &&
      !values.normalQuestions.C.Switch &&
      !values.normalQuestions.D.Switch &&
      values.header.questionType === 'mcq'
    ) {
      errors.question.dz = 'Vous devez cocher au moins une bonne réponse.';
    }
  }

  if (!values.duration || values.duration < 1) {
    errors.duration = 'Veuillez saisir une durée valide (> 0)';
  }

  // Free Questions
  if (values.freeQuestions) {
    if (!values.freeQuestions.freeAnswer) {
      errors.freeQuestions.freeAnswer = 'Please enter a Free answer';
    }
    if (!values.freeQuestions.freeResponse) {
      errors.freeQuestions.freeResponse = 'Please enter a Free Response';
    }
    if (!stringRegExp.test(values.freeQuestions.freeAnswer)) {
      errors.freeQuestions.freeAnswer =
        'Please Enter a valid response (No leading or trailing spaces)';
    }
    if (!stringRegExp.test(values.freeQuestions.freeResponse)) {
      errors.freeQuestions.freeResponse =
        'Please Enter a valid response (No leading or trailing spaces)';
    }
  }

  return errors;
};

export default validate;
