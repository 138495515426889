import axios from 'axios';
import { quizzesServer } from '../config';

class UsersAnswers {
  get = async (quizId, userId) => {
    return axios ({
      method: 'GET',
      url: `${quizzesServer}userAnswer/${quizId}/${userId}`,
    });
  }
}

const answers = new UsersAnswers();
export default answers;
