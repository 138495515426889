import {
  DISPLAY_SEASONS,
} from '../actions/seasons';

const initialState = {
  seasons: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_SEASONS:
      return {
        ...state,
        seasons: action.payload,
      };
    default:
      return state;
  }
};
