import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import allTheActions from '../actions';

const getColor = props => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const DropZoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  ${props => (props.height ? `height: ${props.height}px;` : '')};
  ${props => (props.width ? `width: ${props.width}px;` : '')};
  justify-content: center;
`;

const AcceptedFiles = styled.li`
  color: ${props => (props.status === 'PENDING' ? '#bdbdbd' : props.status === 'UPLOADED' ? 'green' : 'red')};
`;

class FileUpload extends Component {
  constructor() {
    super();
    this.state = {
      fileObj: {},
    };
  }

  static propTypes = {
    accept: PropTypes.string,
    uploadPath: PropTypes.string,
    multiple: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    refreshOnSuccess: PropTypes.bool,
  };

  onDrop = async acceptedFiles => {
    const { uploadPath, route, info } = this.props;
    this.setState(state => {
      state.fileObj = {};
      acceptedFiles.map(file => (state.fileObj[file.name] = 'PENDING'));
      return state;
    });
    const { actions } = this.props;
    if (acceptedFiles.length !== 0) {
      await acceptedFiles.map(async file => {
        const isUpload = await actions.postFile(file, uploadPath, info, route);
        this.setState(state => (state.fileObj[file.name] = isUpload ? 'UPLOADED' : 'ERROR'));
        if (this.props.refreshOnSuccess && isUpload) setTimeout(() => window.location.reload(), 5000);
      })
    }
  };

  render() {
    const { fileObj } = this.state;
    const { accept, multiple, width, height } = this.props;
    const files = Object.entries(fileObj).map(([filename, status]) => (
      <AcceptedFiles key={filename} status={status}>
        {filename}
      </AcceptedFiles>
    ));
    return (
      <Dropzone onDrop={this.onDrop} accept={accept} multiple={multiple}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept, rejectedFiles }) => (
          <DropZoneContainer
            {...getRootProps()}
            isDragAccept={isDragAccept}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
            height={height}
            width={width}
          >
            <input {...getInputProps()} />
            {!isDragActive && 'Click here or drop a file to upload!'}
            {isDragActive && !isDragReject && "Drop it like it's hot!"}
            {isDragReject && 'File type not accepted, sorry!'}
            <ul>
              {files}
              {rejectedFiles.length > 0 &&
                rejectedFiles.map(rejectedFiles => (
                  <li key={rejectedFiles.name} style={{ color: 'red' }}>
                    {rejectedFiles.name}
                  </li>
                ))}
            </ul>
          </DropZoneContainer>
        )}
      </Dropzone>
    );
  }
}

const mapStateToProps = state => ({
  fileProcessing: state.upload.fileProcessing,
  file: state.upload.file,
  fileProcessingError: state.upload.fileProcessingError,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    fileSelect: bindActionCreators(allTheActions.upload.fileSelect, dispatch),
    fileProcess: bindActionCreators(allTheActions.upload.fileProcess, dispatch),
    fileProcessError: bindActionCreators(allTheActions.upload.fileProcessError, dispatch),
    postFile: bindActionCreators(allTheActions.upload.postFile, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FileUpload);
