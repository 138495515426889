import { Link } from "react-router-dom";
import React from "react";
import styled from "styled-components";

const FooterQuestionsQuiz = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const PaginationItem = styled.div`
  align-items: center;
  background-color: ${props => props.theme.color.mainFormColor};
  box-shadow: ${props =>
    props.isSelected ? "0px 0px 5px 2px rgba(0,0,0,0.3)" : ""};
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 5px;
  width: 40px;
`;

const QuestionPicker = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 5;
  justify-content: center;
  max-width: 600px;
`;

const StyledButton = styled.button`
  background-color: ${props => props.theme.color.white};
  border: none;
  cursor: pointer;
  font-size: 16px;
  height: 30px;
  margin: 20px;
  width: 100%;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex: 1;
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.color.black};
  text-decoration: none;
`;

const mapFooter = (questions, id, versionType, page, nbQuestions, actions) => {
  return questions.map((question, index) => {
    if (index >= nbQuestions) return null;
    return (
      <StyledLink
        key={question.id}
        onClick={() => actions.quizzes.cannotChangeForm(true)}
        to={`/home/quizzes/edition/${id}/${index}`}
      >
        <PaginationItem isSelected={page === index}>{index + 1}</PaginationItem>
      </StyledLink>
    );
  });
};

const Footer = props => (
  <FooterQuestionsQuiz>
    <QuestionPicker>
      {mapFooter(
        props.questions,
        props.id,
        props.versionType,
        props.page,
        props.nbQuestions,
        props.actions
      )}
    </QuestionPicker>
    <StyledButtonContainer>
      <StyledButton type="submit" disabled={props.pristine || props.submitting}>
        SAUVEGARDER
      </StyledButton>
    </StyledButtonContainer>
  </FooterQuestionsQuiz>
);

export default Footer;
