const { key } = require('firebase-key');

const getNormalQuestions = data => {
  const value = {};
  if (data.answers.length >= 1) {
    value.A = data.answers[0].content;
    value.A.Switch = data.answers[0].isRight === true ? 'checked' : '';
  }
  if (data.answers.length >= 2) {
    value.B = data.answers[1].content;
    value.B.Switch = data.answers[1].isRight === true ? 'checked' : '';
  }
  if (data.answers.length >= 3) {
    value.C = data.answers[2].content;
    value.C.Switch = data.answers[2].isRight === true ? 'checked' : '';
  }
  if (data.answers.length >= 4) {
    value.D = data.answers[3].content;
    value.D.Switch = data.answers[3].isRight === true ? 'checked' : '';
  }
  return value;
};

// - Switch is that way because it does not accept boolean as value
// - initialize the form with fetched question
const createNormalQuestionObject = data => ({
  header: {
    questionType: data.type,
    nbAnswers: data.answers.length,
    disableQuestionSound: data.disableQuestionSound === true ? 'checked' : '', // fix the bug with material ui which for some reason ask a string in value for a switch
    isJokerDisabled: data.isJokerDisabled === true ? 'checked' : ''
  },
  music: data.musicUrl ? 'checked' : '',
  musicUrl: data.musicUrl ? data.musicUrl : null,
  question: data.question,
  duration: data.duration,
  hint: data.hint ? data.hint : null,
  rewardQuestion: data.reward ? 'checked' : '',
  prizePool: (data.reward && (data.reward.prizePool || data.reward.prizeQuantity)) ? data.reward.prizePool || data.reward.prizeQuantity : 0,
  prizeType: (data.reward && data.reward.prizeType) ? data.reward.prizeType : 'money',
  //script: data.script || '', // former data does not have script, this in order to prevent errors
  normalQuestions: getNormalQuestions(data)
});

const createNormalQuestion = (newValue, formerValue, nbAnswers) => {
  const question = {
    answers: [
      {
        content: {
          fr: newValue.normalQuestions.A.fr,
          dz: newValue.normalQuestions.A.dz,
        },
        isRight: !!newValue.normalQuestions.A.Switch,
        id: 'a'
      },
      {
        content: {
          fr: newValue.normalQuestions.B.fr,
          dz: newValue.normalQuestions.B.dz,
        },
        isRight: !!newValue.normalQuestions.B.Switch,
        id: 'b'
      },
      {
        content: {
          fr: newValue.normalQuestions.C.fr,
          dz: newValue.normalQuestions.C.dz,
        },
        isRight: !!newValue.normalQuestions.C.Switch,
        id: 'c'
      },
      {
        content: {
          fr: newValue.normalQuestions.D.fr,
          dz: newValue.normalQuestions.D.dz,
        },
        isRight: !!newValue.normalQuestions.D.Switch,
        id: 'd'
      }
    ].slice(0, nbAnswers || 4),
    duration: newValue.duration,
    id: formerValue.id,
    isJokerDisabled: !!newValue.header.isJokerDisabled,
    jokersCopycatCount: formerValue.jokersCopycatCount,
    jokersNextCount: formerValue.jokersNextCount,
    nbViewers: formerValue.nbViewers,
    order: newValue.order ? Number(newValue.order) : formerValue.order,
    question: newValue.question,
    rightAnswersCount: formerValue.rightAnswersCount,
    // script: newValue.script,
    disableQuestionSound: !!newValue.header.disableQuestionSound,
    timeoutAnswersCount: formerValue.timeoutAnswersCount,
    type: newValue.header.questionType,
    wrongAnswersCount: formerValue.wrongAnswersCount,
    hint: newValue.hint ? newValue.hint : null,
    musicUrl: newValue.musicUrl ? newValue.musicUrl : null,
  };
  if (newValue.rewardQuestion){
    question.reward = {
      prizeType: newValue.prizeType,
      condition: 'winners',
      isWinnerTakeAllReward: false,
      type: 'rewardQuestion',
      prizePool: Number(newValue.prizePool)
    }
  };
  return question;
};

const createPanelFormObject = (data, permissions) => ({
  panel: {
    addPlayers: data.addPlayers || 0,
    delayHideAnswer: data.delayHideAnswer,
    gainPerWinner: data.gainPerWinner || 0,
    id: data.id,
    inAppEndAt: new Date(data.inAppEndAt),
    inAppStartAt: new Date(data.inAppStartAt),
    isBroadcasting: data.isBroadcasting === true ? 'checked' : '',
    isEnded: data.isEnded === true ? 'checked' : '',
    isStarted: data.isStarted === true ? 'checked' : '',
    jokersCopycatCount: data.jokersCopycatCount || 0,
    jokersNextCount: data.jokersNextCount || 0,
    maxJokersCopycat: parseInt(`${data.maxJokersCopycat}` || '0', 10),
    maxJokersNext: parseInt(`${data.maxJokersNext}` || '0', 10),
    name: data.name,
    nbQuestions: data.nbQuestions,
    prizepool: data.prizepool,
    prizeType: data.prizeType,
    thumbnail: data.thumbnail,
    quizmaster: data.quizmaster,
    quizType: data.type,
    resyncDelay: data.resyncDelay || 4000,
    startAt: new Date(data.startAt),
    subtitle: data.subtitle,
    winnersCount: data.winnersCount || 0,
    programName: data.programName,
    partnerLogo: data.partnerLogo || 'https://firebasestorage.googleapis.com/v0/b/el-quiz-dev.appspot.com/o/Dashboard%20Carousel%2Fentv1_blanc.png?alt=media&token=7891d810-e562-4265-b821-d9866eb204a3',
    theme: data.theme,
    player: permissions.player,
    controller: permissions.controller,
    goLive: data.goLive === true ? 'checked' : '',
    loosersText: data.loosersText,
  }
});

const addQuestion = (questions, nbQuestions) => {
  if (nbQuestions > 100) {
    return questions;
  }
  for (let i = nbQuestions - questions.length; i !== 0; i--) {
    questions.push({
      answers: [
        { content: {
          fr: 'FR',
          dz: 'DZ',
        }, isRight: false, id: 'a' },
        { content: {
          fr: 'FR',
          dz: 'DZ'
        }, isRight: false, id: 'b' },
        { content: {
          fr: 'FR',
          dz: 'DZ'
        }, isRight: false, id: 'c' },
        { content: {
          fr: 'FR',
          dz: 'DZ'
        }, isRight: false, id: 'd' }
      ],
      duration: 12000,
      order: (questions.length + 1),
      question: {
        fr: 'FR',
        dz: 'DZ',
      },
      musicUrl: null,
      hint: null,
      type: 'mcq',
      id: key()
    });
  }
  return questions;
};

const editQuestions = (questions, nbQuestions) => {
  if (questions.length < nbQuestions)
    questions = addQuestion(questions, nbQuestions);
  return questions;
};

export {
  editQuestions,
  createPanelFormObject,
  createNormalQuestion,
  createNormalQuestionObject
};
