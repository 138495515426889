import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import adminDelete from '../../requests/delete';
import adminGetResetPasswordLink from '../../requests/getResetPasswordLink';
import {
  ButtonsContainer,
  ButtonText,
  LineContainer,
  Spacing,
  StyledButton,
  StyledText,
} from './styles';

export default function Line({ admin, getAllAdmin }) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  async function onClickDelete(email) {
    setIsLoading(true);
    if ((await adminDelete({ email }, enqueueSnackbar)).data) getAllAdmin();
  }
  async function onClickResetPassword(email) {
    setIsLoading(true);
    const { data } = await adminGetResetPasswordLink({ email }, enqueueSnackbar);
    alert(data);
    setIsLoading(false);
  }
  return (
    <LineContainer key={admin.email}>
      <StyledText>{admin.email}</StyledText>
      <ButtonsContainer>
        <StyledButton
          color={'secondary'}
          disabled={isLoading}
          onClick={() => {
            onClickResetPassword(admin.email);
          }}
          variant="contained"
        >
          <ButtonText>Réinitialiser le mot de passe</ButtonText>
        </StyledButton>
        <Spacing />
        <StyledButton
          color={'secondary'}
          disabled={isLoading}
          onClick={() => {
            onClickDelete(admin.email);
          }}
          variant="contained"
        >
          <ButtonText>Supprimer</ButtonText>
        </StyledButton>
      </ButtonsContainer>
    </LineContainer>
  );
}
