import { Field } from 'redux-form';
import React from 'react';
import styled from 'styled-components';
import { FormSection } from 'redux-form';

import { InputText, renderSwitch } from '../reduxFormsComponents';

const QuestionsQuiz = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuestionContainer = styled.div`
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 2px black;
  display: flex;
  justify-content: stretch;
  margin: 10px 0px;
  padding: 5px;
`;

const LetterContainer = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  margin-right: 15px;
  width: 65px;
`;

class NormalQuestionsField extends React.Component {
  state = {
    disabled: 'notSet',
    values: {},
  };

  constructor(props) {
    super(props);
    this.onValues = this.onValues.bind(this);
  }

  onValues(id, val) {
    if (this.state.values[id] !== val)
      this.setState({
        values: {
          ...this.state.values,
          [id]: val
        }
      })
  }

  noValuesSet() {
    let noSet = true;

    for (let valuekey in this.state.values) {
      if (this.state.values[valuekey] !== '')
        noSet = false;
    }
    return noSet;
  }

  disableAnswer(id) {
    if (this.state.disabled === 'notSet' && this.noValuesSet()){
      return false;
    }
    else if (this.state.disabled === 'notSet' && !this.noValuesSet() && this.state.values[id] !== 'checked') {
      return true;
    } else if (this.state.disabled !== 'notSet' && this.state.disabled !== '' && this.state.disabled !== id) {
      return true;
    }
    return false;
  }

renderAnswer(id) {
  const { quizzes, isSurvey } = this.props;
  console.log(this.props)

  return (
    <FormSection name={id}>
      <QuestionContainer key={id}>
        <LetterContainer>{id}</LetterContainer>
          <Field component={InputText} disabled={quizzes.isLoading} name={'fr'} placeholder="Insérez la réponse" type="text" />
          <Field component={InputText} disabled={quizzes.isLoading} name={'dz'} placeholder="أدخل الإجابة" type="text" dir='rtl' />
          {!isSurvey && <Field disabled={this.disableAnswer(id)} onChange={(val) => {
            this.setState({
              disabled: (val.target.value === "checked" || val.target.value === "true") ? '' : id,
            });
          }}  component={renderSwitch} name={`Switch`} value={id}
                                 onValues={(val) => this.onValues(id, val)}

          />}
        </QuestionContainer>
      </ FormSection>
    );
  }

  render() {
    const { nbAnswers } = this.props;
    const answersId = ['A', 'B', 'C', 'D'].slice(0, nbAnswers || 4);
    return <QuestionsQuiz>{answersId.map(answerId => this.renderAnswer(answerId))}</QuestionsQuiz>;
  }
}

export default NormalQuestionsField;
