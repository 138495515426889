import { ConnectedRouter } from 'connected-react-router';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { JssProvider } from 'react-jss';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { SnackbarProvider } from 'notistack'
import React from 'react';

import HomePage from './homePage';
import Login from './login';
import PrivateRoute from '../utils/privateRoute';
import { refreshUserToken } from '../utils/adminTokens';
import theme from '../config/theme';
import { history, store } from '../config/store';
import config from '../config';
import '../config/axios';
import '../index.css';
import 'moment/locale/fr';

const generateClassName = createGenerateClassName();
const jss = create(jssPreset());
// We define a custom insertion point that JSS will look for injecting the styles in the DOM.
jss.options.insertionPoint = 'jss-insertion-point';

export default class App extends React.Component {
  componentDidMount() {
    document.title = `${config.name}`;
    // admin.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    if (localStorage.getItem('refreshToken')) {
      refreshUserToken();
    }
    // Get a new token every 30 minutes
    this.interval = setInterval(() => refreshUserToken(), 15 * 60 * 1000);
  }

  render() {
    return (
      <JssProvider jss={jss} generateClassName={generateClassName}>
        {/* JSS provider allows us to put our style before the material ui one (in our case it is for styled components)*/}
        <ThemeProvider theme={theme}>
          {/* MuiTheme is nescessary to the legacy page implementation, delete when the app is fully migrated*/}
          <MuiThemeProvider>
            <Provider store={store}>
              <SnackbarProvider maxSnack={5} >
                <ConnectedRouter history={history}>
                  <Switch>
                    <Route exact path="/" render={() => <Login />} />
                    <PrivateRoute path="/home/" component={HomePage} />
                    <Redirect to="/home/users" />
                  </Switch>
                </ConnectedRouter>
              </SnackbarProvider>
            </Provider>
          </MuiThemeProvider>
        </ThemeProvider>
      </JssProvider>
    );
  }
}
