import React from 'react';
import styled from 'styled-components';

import { CommonText } from '../texts';

const CardInfo = styled.div`
  background-color: ${props => props.theme.color.infoCard};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  min-width: 150px;
  padding: 8px;
  margin-right: 5px;
  justify-content: center;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
`;

const CardContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export default class InfoCard extends React.Component {
  render() {
    const { content, contentFontSize = 26, title, titleFontSize = 16 } = this.props;
    return (
      <CardInfo className={this.props.className}>
        {title ? (
          <CardHeader>
            <CommonText color="white" fontSize={titleFontSize}>
              {title}
            </CommonText>
          </CardHeader>
        ) : null}
        {content ? (
          <CardContent>
            <CommonText color="white" fontSize={contentFontSize}>
              {content}
            </CommonText>
          </CardContent>
        ) : null}
      </CardInfo>
    );
  }
}
