import React from 'react';

import Line from "../Line";
import { TableContainer } from './styles';

export default function Table({ allAdmin, getAllAdmin }) {
  return (
    <TableContainer>
      {allAdmin.map(admin => (
        <Line
          admin={admin}
          getAllAdmin={getAllAdmin}
          key={admin.email}
        />
      ))}
    </TableContainer>
  );
}
