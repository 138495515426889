import React, { Component } from 'react';
import { Route, Switch } from 'react-router';

import MessagesHistoryDetails from './messagesHistoryDetails';

class MessagesHistory extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/home/messagesHistory"
            component={MessagesHistoryDetails}
          />
          <Route
            path="/home/messagesHistory/:quizId"
            component={MessagesHistoryDetails}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

export default MessagesHistory;
