import React from 'react';

import { StyledTypography } from "./styles";

export default function CardTitle(name) {
  return (
    <StyledTypography variant="h2">
      {name}
    </StyledTypography>
  )
}
