import styled from 'styled-components';

export const AdminContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 1em;
  padding-bottom: 1em;
  width: 100%;
`;
