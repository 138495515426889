import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import StopCell from './StopCell';

const LinkStyled = styled(Link)`
  text-decoration: none;
`;

const TableContent = ({ quizzes, onUpdate }) => {
  const getNbQuestions = questionIds => {
    const reducer = (accumulator, currentValue) =>
      currentValue ? accumulator + currentValue.length : accumulator;
    return questionIds.reduce(reducer, 0);
  };
  return quizzes.map(quiz => (
    <TableRow key={quiz._id}>
      <TableCell>{quiz.name}</TableCell>
      <TableCell>{quiz._id}</TableCell>
      <TableCell>{quiz.type}</TableCell>
      <TableCell>{moment(quiz.startAt).format('DD/MM/YYYY HH:mm')}</TableCell>
      <TableCell>{moment(quiz.endAt).format('DD/MM/YYYY HH:mm')}</TableCell>
      <TableCell>{quiz.prizepool}</TableCell>
      <TableCell>{getNbQuestions(quiz.questionIds)}</TableCell>
      <TableCell>
        <LinkStyled to={`/home/goQuizzes/${quiz._id}`}>
          <Button variant="contained">Visualiser</Button>
        </LinkStyled>
      </TableCell>
      <StopCell _id={quiz._id} onUpdate={onUpdate} />
    </TableRow>
  ));
};

export default TableContent;
