import axios from 'axios';
import { userServer } from '../config';

export const FILE_SELECTED = 'FILE_SELECTED';
export const FILE_PROCESS = 'FILE_PROCESS';
export const FILE_PROCESS_ERROR = 'FILE_PROCESS_ERROR';
export const FILE_UPLOADED = 'FILE_UPLOADED';
export const FILE_LINK_CLEAR = 'FILE_LINK_CLEAR';

export const fileLinkClear = () => ({
  type: FILE_LINK_CLEAR,
});

export const fileSelect = file => ({
  type: FILE_SELECTED,
  payload: file,
});

export const fileProcess = status => ({
  type: FILE_PROCESS,
  payload: status,
});

export const fileProcessError = stat => ({
  type: FILE_PROCESS_ERROR,
  payload: stat,
});

export const fileUploaded = link => ({
  type: FILE_UPLOADED,
  payload: link,
});

const fileToBase64 = file => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = event => resolve(event.target.result);
    reader.readAsDataURL(file);
  });
};

export const postFile = (file, path, info, route) => async dispatch => {
  dispatch(fileUploaded(null));
  try {
    const base64 = await fileToBase64(file);
    const { data } = await axios ({
      method: 'POST',
      url: `${userServer}postGoQuiz`,
      data: {
        path,
        name: file.path,
        file: base64,
        info,
      },
    });
    console.log(data);
    dispatch(fileUploaded(data));
    dispatch(fileProcess(false));
    dispatch(fileProcessError(false));
    return true;
  } catch (error) {
    console.error(error);
    dispatch(fileProcessError(true));
    dispatch(fileProcess(false));
    return false;
  }
};
