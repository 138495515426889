import * as controls from './controls';
import * as messages from './messages';
import * as presenter from './presenter';
import * as quizzes from './quizzes';
import * as surveys from './surveys';
import * as modal from './modal';
import * as users from './users';
import * as upload from './upload';
import * as winners from './winners';
import * as seasons from './seasons';
import * as quidolGo from './quidolGo';
import * as admob from './adMob';
import * as achievements from './achievements'
import * as cards from './cards'

export default { controls, messages, presenter, quizzes, surveys, modal, users, upload, winners, quidolGo, admob, achievements, cards, seasons };
