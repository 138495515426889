import { Route, Switch } from 'react-router';
import React from 'react';
import DashboardWording from "./DashboardWording";

class Replays extends React.PureComponent {

  render() {

    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/home/wording" component={DashboardWording} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default Replays;

