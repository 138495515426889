import { ViewQuilt, ViewList } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { SubTitle } from "../texts";

const RowContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  width: 100%;
`;

const RowMenuLabel = styled(SubTitle)`
  line-height: 48px;
`;

const StyledIconDashboard = styled(ViewQuilt)`
  color: #757575;
`;

const StyledIconMenu = styled(ViewList)`
  color: #757575;
`;

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 48px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${props => (props.disableIcon ? "center" : "flex-start")};
`;

const Icon = props => (
  <IconContainer>
    {props.principal ? <StyledIconDashboard /> : <StyledIconMenu />}
  </IconContainer>
);

const RowMenu = props => (
  <RowContainer className={props.className} onClick={props.onClick}>
    {props.disableIcon ? null : <Icon principal={props.principal} />}
    <LabelContainer disableIcon={props.disableIcon}>
      <RowMenuLabel color={props.color ? props.color : "white"}>
        {props.name}
      </RowMenuLabel>
    </LabelContainer>
  </RowContainer>
);

RowMenu.propTypes = {
  color: PropTypes.string,
  disableIcon: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func
};

export default RowMenu;
