import React from 'react';
import styled from 'styled-components';
import InfoCard from '../infoCard';
import { MODAL_TYPE_SURVEY_CREATE } from '../../constants/ModalTypes';
import { Button, Divider, Card, CardContent } from '@material-ui/core';

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 10px;
  max-width: 900px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
`;

const ButtonStyled = styled(Button)`
  display: flex;
  margin: 10px;
`;

class ControlsSurvey extends React.Component {
  showNotif = quizId => {
    const { actions } = this.props;
    actions.showModal(MODAL_TYPE_SURVEY_CREATE, {
      title: 'Survey Modal',
      quizId,
    });
  };

  hideModal = () => {
    const { actions } = this.props;
    actions.hideModal();
  };
  render() {
    const { actions, currentQuestion, quizId, previousQuestion, randomViewer } = this.props;
    if (!quizId) return null;
    return (
      <ControlsContainer>
        <Card>
          <CardContent>
            <ButtonContainer>
              <ButtonStyled color="primary" onClick={() => this.showNotif(quizId)} onClose={() => this.hideModal()} variant="contained">
                Démarrer un sondage
              </ButtonStyled>
              {/* REPECHE */}
              <ButtonStyled
                color="secondary"
                onClick={() => {
                  // TODO SEND THE GOOD QUESTION ID FOR NOW THE TREATMENT IS IN THE BACKEND
                  actions.controls.retrievePlayers(previousQuestion);
                }}
                variant="contained"
              >
                Repêcher les joueurs
              </ButtonStyled>
              <ButtonStyled
                color="primary"
                onClick={() => {
                  // TODO SEND THE GOOD QUESTION ID FOR NOW THE TREATMENT IS IN THE BACKEND
                  actions.controls.rewardPlayers(currentQuestion.id);
                }}
                variant="contained"
              >
                Récompenser les joueurs
              </ButtonStyled>
              {/* GET RANDOM */}
              <ButtonStyled
                color="secondary"
                onClick={() => {
                  // TODO SEND THE GOOD QUESTION ID FOR NOW THE TREATMENT IS IN THE BACKEND
                  actions.controls.getRandomViewer();
                }}
                variant="contained"
              >
                Sélectionner un utilisateur au hasard
              </ButtonStyled>
            </ButtonContainer>
          </CardContent>
        </Card>
        <Divider />
        { randomViewer && <InfoCard content={randomViewer ? `${randomViewer.nickname}(${randomViewer.userId})` : '-'} title={'Utilisateur Aléatoire'} />}
      </ControlsContainer>
    );
  }
}

export default ControlsSurvey;
