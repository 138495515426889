import { config } from "../config/firebase";

export default {
  color: {
    black: "#222",
    cardTimerColor: "#303030",
    error: "#f33f50",
    go: "#9ccc84",
    inactive: "#7F7877",
    infoCard: "#3f51b5",
    infoCardPresenter: "#adadad52",
    logoutButtonColor: "#f5f2f0",
    logoutButtonHoverColor: "#d5d5d5",
    mainColor: config.backgroundColor,
    mainFormColor: "#FFF",
    menuColor: "#303030",
    messageNickname: "#e3297d",
    messageOdd: "#d5d5d5",
    messagesParticipation: "#55c3e6",
    PresenterNbAnswers: "#79797952",
    questionOdd: "#f1f0f0",
    questionRight: "#9ccc84",
    questionWrong: "#d97164",
    secondaryColor: "#f33",
    secondaryFormColor: "#303030",
    separator: "#e4e4e4",
    tertiaryFormColor: "#026799",
    wait: "#d97164",
    white: "#fff"
  }
};
