import {
  DISPLAY_WINNERS,
  DISPLAY_WINNERS_LENGTH,
  PENDING_WINNERS,
  PENDING_DOWNLOAD_WINNERS,
  PENDING_DOWNLOAD_IBAN_WINNERS,
  PENDING_DOWNLOAD_PAYPAL_WINNERS,
  PENDING_REVERT_UNIQ_WINNER,
  PENDING_VALIDATE_IBAN,
  PENDING_VALIDATE_PAYPAL,
  PENDING_VALIDATE_UNIQ_IBAN,
  PENDING_VALIDATE_UNIQ_PAYPAL,
  WINNERS_CLEAR_LINK,
  WINNERS_DOWNLOAD_LINK
} from '../actions/winners';

const initialState = {
  isLoading: false,
  pendingUpdate: false,
  pendingDownload: false,
  pendingDownloadIban: false,
  pendingDownloadPaypal: false,
  pendingRevertUniqWinner: false,
  pendingValidateIban: false,
  pendingValidatePaypal: false,
  pendingValidateUniqIban: false,
  pendingValidateUniqPaypal: false,
  length: 0,
  link: '',
  winners: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_WINNERS:
      return {
        ...state,
        winners: action.payload
      };
    case DISPLAY_WINNERS_LENGTH:
      return {
        ...state,
        length: action.payload
      };
    case PENDING_WINNERS:
      return {
        ...state,
        isLoading: action.payload
      };
    case PENDING_DOWNLOAD_WINNERS:
      return {
        ...state,
        pendingDownload: action.payload
      };
    case PENDING_DOWNLOAD_IBAN_WINNERS:
      return {
        ...state,
        pendingDownloadIban: action.payload
      };
    case PENDING_DOWNLOAD_PAYPAL_WINNERS:
      return {
        ...state,
        pendingDownloadPaypal: action.payload
      };
    case PENDING_REVERT_UNIQ_WINNER:
      return {
        ...state,
        pendingRevertUniqWinner: action.payload.boolean,
        pendingRevertUniqWinnerId: action.payload.winnerId
      };
    case PENDING_VALIDATE_IBAN:
      return {
        ...state,
        pendingValidateIban: action.payload
      };
    case PENDING_VALIDATE_PAYPAL:
      return {
        ...state,
        pendingValidatePaypal: action.payload
      };
    case PENDING_VALIDATE_UNIQ_IBAN:
      return {
        ...state,
        pendingValidateUniqIban: action.payload.boolean,
        pendingValidateUniqIbanWinnerId: action.payload.winnerId
      };
    case PENDING_VALIDATE_UNIQ_PAYPAL:
      return {
        ...state,
        pendingValidateUniqPaypal: action.payload.boolean,
        pendingValidateUniqPaypalWinnerId: action.payload.winnerId
      };
    case WINNERS_CLEAR_LINK:
      return {
        ...state,
        link: ''
      };
    case WINNERS_DOWNLOAD_LINK:
      return {
        ...state,
        link: action.payload
      };
    default:
      return state;
  }
};
