import { FILE_SELECTED, FILE_PROCESS, FILE_PROCESS_ERROR, FILE_UPLOADED, FILE_LINK_CLEAR } from '../actions/upload';

const initialState = {
  file: null,
  fileProcessing: false,
  fileProcessingError: false,
  fileLink: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FILE_SELECTED:
      return {
        ...state,
        file: action.payload,
      };
    case FILE_PROCESS:
      return {
        ...state,
        fileProcessing: action.payload,
      };
    case FILE_PROCESS_ERROR:
      return {
        ...state,
        fileProcessingError: action.payload,
      };
    case FILE_UPLOADED:
      return {
        ...state,
        fileLink: action.payload,
      };
    case FILE_LINK_CLEAR:
      return {
        ...state,
        fileLink: undefined,
      };
    default:
      return state;
  }
};
