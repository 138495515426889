import quidolSocket from '../utils/socketio';
import { key } from 'firebase-key';

import axios from 'axios';
import { quizzesServer } from '../config';

export const DISPLAY_SURVEYS = 'DISPLAY_SURVEYS';
export const DISPLAY_ERROR_SURVEYS = 'DISPLAY_ERROR_SURVEYS';
export const SURVEY_STARTED = 'SURVEY_STARTED';
export const SURVEY_ENDED = 'SURVEY_ENDED';
export const SURVEY_FETCHING = 'SURVEY_FETCHING';
export const CURRENT_SURVEY = 'CURRENT_SURVEY';
export const PENDING = 'PENDING';
export const SET_SURVEY_LIST = 'SET_SURVEY_LIST';

quidolSocket.connect();

export const pending = bool => ({
  type: PENDING,
  payload: bool,
});

export const displaySurveysError = err => ({
  type: 'DISPLAY_ERROR_SURVEYS',
  payload: err,
});

export const currentSurvey = survey => ({
  type: CURRENT_SURVEY,
  payload: survey,
});

export const setSurveyList = surveys => ({
  type: SET_SURVEY_LIST,
  surveys,
});

export const getSurveyList = () => async dispatch => {
  dispatch(pending(true));
  const { data } = await axios ({
    method: 'GET',
    url: `${quizzesServer}getSurvey`,
  });
  dispatch(setSurveyList(data))
  dispatch(pending(false));
};

export const deleteSurvey = (_id) => async dispatch => {
  dispatch(pending(true));
  await axios ({
    method: 'POST',
    url: `${quizzesServer}deleteSurvey`,
    data: {
      _id,
    }
  });
  getSurveyList();
  dispatch(pending(false));
}

export const createSurvey = (question) => async dispatch => {
  dispatch(pending(true));
  await axios ({
    method: 'POST',
    url: `${quizzesServer}postSurvey`,
    data: {
      question,
    }
  });
  getSurveyList();
  dispatch(pending(false));
}

export const postSurvey = (values, actions, videoChunkTime) => async dispatch => {
  dispatch(pending(true));

  try {
    const survey = {
      id: key(),
      question: values.question,
      quiz: values.quizId,
      duration: values.duration,
      answers: values.answers,
      type: 'mcq',
      order: 0,
      label: 'Sondage',
      isViewerAllowed: true,
      isJokerDisabled: true,
    };
    if (values.prizeQuantity) {
      survey.label = 'Question bonus';
      survey.reward = {
        prizeType: 'jokers',
        prizeQuantity: values.prizeQuantity,
        condition: 'winners',
      };
    }
    if (videoChunkTime && videoChunkTime.chunkTime && videoChunkTime.chunkSeenAt) {
      survey.chunkTime = videoChunkTime.chunkTime;
      survey.chunkSeenAt = videoChunkTime.chunkSeenAt;
    } else console.error('no chunkTime', videoChunkTime);
    console.log('survey', survey);
    const res = await quidolSocket.sendQuestionSurvey(survey);
    if (res.code !== 200) throw new Error('not 200 result code');
    if (actions) actions.hideModal();
  } catch (e) {
    alert(e);
    console.error(e);
    if (actions) actions.hideModal();
    dispatch(displaySurveysError(e));
    dispatch(pending(false));
    return e;
  }
};
