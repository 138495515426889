import quidolSocket from '../utils/socketio';
export const PRESENTER_CURRENT_QUIZ = 'PRESENTER_CURRENT_QUIZ';
export const PRESENTER_CURRENT_QUESTION = 'PRESENTER_CURRENT_QUESTION';
export const PRESENTER_NB_PLAYERS = 'PRESENTER_NB_PLAYERS';
export const PRESENTER_NB_VIEWERS = 'PRESENTER_NB_VIEWERS';
export const UPDATE_QUESTION_STATES = 'UPDATE_QUESTION_STATES';

quidolSocket.connect();

export const nbViewers = int => ({
  type: PRESENTER_NB_VIEWERS,
  payload: int,
});

export const nbPlayers = int => ({
  type: PRESENTER_NB_PLAYERS,
  payload: int,
});

export const displayCurrentQuestion = question => ({
  type: PRESENTER_CURRENT_QUESTION,
  payload: question,
});

export const currentQuiz = quiz => ({
  type: PRESENTER_CURRENT_QUIZ,
  payload: quiz,
});
export const updateQuestionStates = (questionId, state) => ({
  type: UPDATE_QUESTION_STATES,
  questionId,
  state,
});

export const watchQuiz = () => async (dispatch) => {
  try {
    let quiz;
    const currentQuizzes = await quidolSocket.getCurrentQuizzes();
    const currentQuidolLive = currentQuizzes.data.filter((quiz) => (!quiz.versionType && quiz.type !== 'battle'))[0];
    if (currentQuidolLive)
      quiz = { ...currentQuidolLive, isBroadcasting: true, id: currentQuidolLive.id };
    if (quiz) {
      quidolSocket.joinQuiz(quiz.id).catch((err) => { console.error(`ERROR: Crash JoinQuiz, ${err.stack}`) });
      await dispatch(currentQuiz(quiz));
      await dispatch(playQuiz(quiz));
    }

    quidolSocket.onLiveStarted(async ({ code, data }) => {
      if (code === 200) {
        if (!data.versionType) {
          quidolSocket.joinQuiz(data.id).catch((err) => { console.error(`ERROR: Crash JoinQuiz, ${err.stack}`) });
          await dispatch(currentQuiz({ ...data, isBroadcasting: true, id: data.id }));
          await dispatch(playQuiz({ ...data, isBroadcasting: true, id: data.id }));
        }
      }
    });
  } catch (e) {
    console.error(e);
  }
};

export const playQuiz = quiz => async (dispatch, getState) => {
  try {
    quidolSocket.quizId = quiz.id;
    const nbPlayersViewers = await quidolSocket.getPlayersViewers();
    console.log('@@@nbPlayersViewers', nbPlayersViewers);
    dispatch(nbViewers(nbPlayersViewers.nbViewers || '0'));
    dispatch(nbPlayers(nbPlayersViewers.nbPlayers || '0'));
    quidolSocket.onPlayersViewers(({ code, data }) => {
      console.log('onPlayersViewers data:', data);
      if (code === 200) {
        dispatch(nbViewers(data.nbViewers || '0'));
        dispatch(nbPlayers(data.nbPlayers || '0'));
      }
    });

    await dispatch(getCurrentQuestion());
    quidolSocket.onShowQuestionExecuted(({ code, data }) => {
      console.log('onShowQuestionExecuted data:', code, data);
      if (code === 200) {
        dispatch(displayCurrentQuestion(data));
      }
    });
  } catch (e) {
    console.error(e);
  }
};

let _questionIntervalP;
export const getCurrentQuestion = () => async (dispatch, getState) => {
  let nextQuestion;
  try {
    const response = await quidolSocket.getCurrentQuestion({ skipIfAnswerIsDisplayed: false });
    if (response.code === 200 && response.data) {
      nextQuestion = response.data;
    }
    const { currentQuestion } = getState().presenter;

    const jsonDiff = require('jsondiffpatch').create();
    const delta = await jsonDiff.diff(nextQuestion, currentQuestion);
    if (!delta || !nextQuestion) return;

    if (nextQuestion.display) dispatch(updateQuestionStates(nextQuestion.id, 'Answering'));
    if (nextQuestion.isExpired) dispatch(updateQuestionStates(nextQuestion.id, 'Processed'));
    if (nextQuestion.isAnswerDisplayed) dispatch(updateQuestionStates(nextQuestion.id, 'Answers Displayed'));

    if (nextQuestion.display) dispatch(displayCurrentQuestion(nextQuestion));
    if (_questionIntervalP) clearInterval(_questionIntervalP);
    _questionIntervalP = setInterval(() => dispatch(getCurrentQuestion()), 2000);
  } catch (e) {
    console.error(e);
  }
};
