import {
  DISPLAY_QUIZZES_QUIDOL_GO,
  PENDING_QUIDOL_GO,
  DISPLAY_QUIZ_QUIDOL_GO,
  ERROR_FETCH_QUIZ_QUIDOL_GO,
  CANNOT_CHANGE_FORM_QUIDOL_GO,
} from '../actions/quidolGo';

const initialState = {
  cannotChangeForm: true,
  count: 0,
  data: [],
  error: '',
  isDownloading: false,
  isLoading: false,
  link: '',
  quiz: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_QUIZZES_QUIDOL_GO:
      return {
        ...state,
        data: action.payload.quizzes,
        count: action.payload.nbQuizzes,
        error: '',
      };
    case PENDING_QUIDOL_GO:
      return {
        ...state,
        isLoading: action.payload,
      };
    case DISPLAY_QUIZ_QUIDOL_GO:
      return {
        ...state,
        quiz: action.payload,
        error: '',
      };
    case ERROR_FETCH_QUIZ_QUIDOL_GO:
      return {
        ...state,
        error: action.payload.message,
      };
    case CANNOT_CHANGE_FORM_QUIDOL_GO:
      return {
        ...state,
        cannotChangeForm: action.payload,
      };
    default:
      return state;
  }
};
