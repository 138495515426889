import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Gavel from '@material-ui/icons/Gavel';
import PhonelinkOff from '@material-ui/icons/PhonelinkOff';
import VolumeOff from '@material-ui/icons/VolumeOff';

import {
  Button,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';

import CardTitle from '../cardTitle';

import config from '../../config';

const LinkStyled = styled(Link)`
  text-decoration: none;
`;

const StyledCard = styled.div`
  width: 100%;
`;

const StyledButton = styled(Button)``;

const ButtonText = styled.span``;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px 0;
`;

const CenteredTableCell = styled(TableCell)`
  text-align: center;
`;

const StyledTableCell = styled(TableCell)``;

const StyledCenteredTableCell = styled(StyledTableCell)`
  text-align: center;
`;

const StatusIcon = styled.span`
  margin 0 5px;
`;

export class UsersTable extends Component {
  state = {
    searchText: '',
    onlyFlooder: false,
    FlooderUserList: [],
  };

  handleSubmit(event, searchText) {
    const { handleChangeSearchTerm } = this.props;
    handleChangeSearchTerm(searchText);
    event.preventDefault();
  }

  render() {
    const {
      downloadUsers,
      handleChangeLimit,
      handleChangePage,
      isLoading,
      length,
      limit,
      page,
      pendingDownload,
      searchTerm,
      users
    } = this.props;

    const { searchText } = this.state;
    const { enableUserDetails } = config;

    let index = 0;
    return (
      <StyledCard>
        {CardTitle('Utilisateurs')}
        {enableUserDetails ? (
          <form onSubmit={event => this.handleSubmit(event, searchText)}>
            <TextField
              id="userId"
              label="ID / Pseudo / Numéro de téléphone"
              name={searchTerm}
              onChange={event =>
                this.setState({ searchText: event.target.value })
              }
              style={{ marginLeft: 40, marginTop: 20, display: 'block' }}
            />
            <ButtonsContainer>
              <StyledButton
                disabled={isLoading || pendingDownload}
                color="primary"
                variant="contained"
                style={{ marginLeft: 40, marginTop: 20 }}
                type="submit"
              >
                <ButtonText>Rechercher</ButtonText>
              </StyledButton>
              <StyledButton
                disabled={isLoading || pendingDownload}
                variant="contained"
                style={{ marginLeft: 20, marginTop: 20 }}
                onClick={() => downloadUsers(searchText)}
              >
                {pendingDownload ? (
                  <CircularProgress size={24} />
                ) : (
                  <ButtonText>Télécharger</ButtonText>
                )}
              </StyledButton>
            </ButtonsContainer>
          </form>
        ) : null}
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Pseudo</TableCell>
                <TableCell>ID</TableCell>
                <CenteredTableCell>Numéro de téléphone</CenteredTableCell>
                <CenteredTableCell>Jokers actuels</CenteredTableCell>
                <CenteredTableCell>Statut</CenteredTableCell>
                <CenteredTableCell>Flooder</CenteredTableCell>
                {enableUserDetails ? (
                  <CenteredTableCell>
                    Information supplémentaire
                  </CenteredTableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && users ? (
                users.map(user => (
                  <TableRow key={index++}>
                    <StyledTableCell>{user.nickname}</StyledTableCell>
                    <StyledTableCell>{user.id}</StyledTableCell>
                    <StyledCenteredTableCell>
                      {user.phoneNumber}
                    </StyledCenteredTableCell>
                    <StyledCenteredTableCell>
                      {user.jokers}
                    </StyledCenteredTableCell>
                    <StyledCenteredTableCell>
                      {user.disabled ? (
                        <StatusIcon>
                          <Tooltip title="Disabled">
                            <PhonelinkOff />
                          </Tooltip>
                        </StatusIcon>
                      ) : null}
                      {user.isChatRestricted ? (
                        <StatusIcon>
                          <Tooltip title="Muted">
                            <VolumeOff />
                          </Tooltip>
                        </StatusIcon>
                      ) : null}
                      {user.isJailed ? (
                        <StatusIcon>
                          <Tooltip title="Jailed">
                            <Gavel />
                          </Tooltip>
                        </StatusIcon>
                      ) : null}
                    </StyledCenteredTableCell>
                    <CenteredTableCell>
                      {user.isFlooder ? (
                        <CheckIcon/>
                      ) : null }
                    </CenteredTableCell>
                    {enableUserDetails ? (
                      <CenteredTableCell>
                        <Button>
                          <LinkStyled
                            style={{
                              color: 'rgba(0, 0, 0, 0.87)'
                            }}
                            to={`/home/users/${user.id}`}
                          >
                            DETAILS
                          </LinkStyled>
                        </Button>
                      </CenteredTableCell>
                    ) : null}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={10}>
                    <SpinnerContainer>
                      <CircularProgress size={24} />
                    </SpinnerContainer>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!isLoading ? (
            <TablePagination
              colSpan={1}
              count={length}
              onChangePage={(e, value) => handleChangePage(value)}
              onChangeRowsPerPage={({ target: { value } }) =>
                handleChangeLimit(value)
              }
              page={page}
              rowsPerPage={limit < length ? limit : length}
              rowsPerPageOptions={
                length > 50 ? [10, 50, 100] : length > 10 ? [10, 50] : []
              }
            />
          ) : null}
        </CardContent>
      </StyledCard>
    );
  }
}
