import axios from 'axios';
import { history } from '../config/store';
import { config } from '../config/firebase';
import { connect } from '../utils/handleQuestionAnswerTV';
import firebase from 'firebase';

export const getToken = async (email, password) => {
  firebase.auth().signInWithEmailAndPassword(email, password).catch(function(error) {
    var errorCode = error.code;
    var errorMessage = error.message;

    console.log(errorCode, errorMessage);
  });
  return axios({
    method: 'POST',
    url: `${config.cloudHttps}/httpsAdminLogin`,
    data: {
      email,
      password,
    },
  })
    .then(res => {
      if (res.data.error) {
        return alert(`Une erreur est survenue: ${res.data.error.message}`);
      }
      return res.data;
    })
    .catch(err => alert(`Une erreur est survenue: ${err}`));
};

export const refreshUserToken = () => {
  const redirectToLogin = () => {
    localStorage.removeItem('firebaseToken');
    localStorage.removeItem('refreshToken');
    return history.push('/');
  };
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) return redirectToLogin();
  return axios({
    method: 'POST',
    url: `${config.cloudHttps}/httpsAdminRefreshToken`,
    data: {
      refreshToken,
    },
  })
    .then(res => {
      if (res.data && res.data.error) {
        alert(`Une erreur est survenue: ${res.data.error.message}`);
        return redirectToLogin();
      }
      localStorage.setItem('firebaseToken', res.data.id_token);
      connect(res.data.id_token);
      return localStorage.setItem('refreshToken', res.data.refresh_token);
    })
    .catch(err => {
      alert(`Une erreur est survenue: ${err}`);
      return redirectToLogin();
    });
};
