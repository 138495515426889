import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import allTheActions from '../actions';
import { InputText } from '../components/reduxFormsComponents';
import { config } from '../config/firebase';
import styled from 'styled-components';

const StyledForm = styled.form`
  background-color: ${props => props.theme.color.mainFormColor};
  padding: 20px;
  width: 640px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin: 20px 10px;
`;

const ButtonText = styled.p`
  margin: 0;
`;

const InfoHeader = styled.span``;

class UserDetails extends React.PureComponent {
  state = {
    jokersChangeReasonRequired: false,
  };

  componentDidMount() {
    const userId = this.props.match.params.id;
    const { actions } = this.props;
    actions.users.getUser(userId);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { initialValues } = this.props;
    if (nextProps.initialValues !== initialValues) {
      this.props.initialize(nextProps.initialValues);
      return true;
    }
    return false;
  }

  submit = values => {
    const userConfirmedUpdate = window.confirm('Confirm les modifications?');
    if (userConfirmedUpdate) {
      const { actions } = this.props;
      values.id = this.props.match.params.id;
      values.jokers = Number(values.jokers);
      actions.users.putUser(values);
    }
  };

  setJokersChangeReason = event => {
    if (Number(this.props.initialValues.jokers) !== Number(event.target.value)) {
      this.setState({ jokersChangeReasonRequired: true });
    } else {
      this.setState({ jokersChangeReasonRequired: false });
    }
  };

  deleteUser = action => {
    const userConfirmedDelete = window.confirm('Confirmer cette action');
    if (userConfirmedDelete) {
      const {
        actions,
        match: {
          params: { id },
        },
      } = this.props;
      actions.users.deleteUser(action, id);
    }
  };

  render() {
    const {
      actions: { users },
      deleteSuccess,
      disableSuccess,
      isLoading,
      pendingUpdate,
      handleSubmit,
      initialValues,
    } = this.props;
    const { jokersChangeReasonRequired } = this.state;
    const currentUser = initialValues;

    if (disableSuccess) {
      window.location.reload();
    }

    if (deleteSuccess) {
      users.deleteSuccess(false);
      return <Redirect to="/home/users" />;
    }

    return (
      <StyledForm onSubmit={handleSubmit(value => this.submit(value))}>
        <Typography
          variant="headline"
          component="h1"
          style={{
            color: 'red',
            marginTop: 0,
          }}
        >
          {currentUser.disabled ? <InfoHeader>Désactivé</InfoHeader> : null}
          {currentUser.isChatRestricted ? <InfoHeader>Muted</InfoHeader> : null}
          {currentUser.isJailed ? <InfoHeader>Jailed</InfoHeader> : null}
          {currentUser.isForbiddenToPlay ? <InfoHeader>{currentUser.forbiddenToPlayReason || 'Bloqué'}</InfoHeader> : null}
        </Typography>
        <Field component={InputText} disabled={true} helperText="createdAt" name="createdAt" type="text" />
        <Field component={InputText} disabled={true} helperText="id" name="id" type="text" />
        <Field component={InputText} disabled={isLoading} helperText="Nickname" name="nickname" type="text" />
        <Field
          component={InputText}
          disabled={isLoading}
          helperText="Nombre de jokers"
          name="jokers"
          type="number"
          min="0"
          onChange={event => this.setJokersChangeReason(event)}
        />
        {jokersChangeReasonRequired && (
          <Field
            component={InputText}
            disabled={isLoading}
            helperText="Raison de changement de jokers"
            name="jokersChangeReason"
            type="text"
          />
        )}
        <Field component={InputText} disabled={isLoading} helperText="Numéro de mobile" name="infos.phoneNumber" type="text" />
        <Field component={InputText} disabled={true} helperText="CCP" name="postal.code" type="text" />
        <Field component={InputText} disabled={true} helperText="Email" name="email" type="text" />
        <Field component={InputText} disabled={true} helperText="Gain Total" name="totalEarned" type="text" />
        <Field component={InputText} disabled={true} helperText="Referral" name="referral" type="text" />
        <ButtonContainer>
          <StyledButton disabled={isLoading || pendingUpdate} type="submit" color="primary" variant="contained">
            {pendingUpdate ? <CircularProgress size={24} /> : <ButtonText>Sauvegarder</ButtonText>}
          </StyledButton>
          {currentUser.isChatRestricted && config.enableMessages ? (
            <StyledButton variant="contained" onClick={() => this.deleteUser('unmute')} disabled={isLoading || pendingUpdate}>
              <ButtonText>Unmute l'utilisateur</ButtonText>
            </StyledButton>
          ) : null}
          {!currentUser.isChatRestricted && config.enableMessages ? (
            <StyledButton variant="contained" onClick={() => this.deleteUser('mute')} disabled={isLoading || pendingUpdate}>
              <ButtonText>Mute l'utilisateur</ButtonText>
            </StyledButton>
          ) : null}
          {currentUser.isJailed ? (
            <StyledButton variant="contained" onClick={() => this.deleteUser('unjail')} disabled={isLoading || pendingUpdate}>
              <ButtonText>Unjail l'utilisateur</ButtonText>
            </StyledButton>
          ) : null}
          {!currentUser.isJailed ? (
            <StyledButton variant="contained" onClick={() => this.deleteUser('jail')} disabled={isLoading || pendingUpdate}>
              <ButtonText>Jail l'utilisateur</ButtonText>
            </StyledButton>
          ) : null}
          {currentUser.disabled ? (
            <StyledButton variant="contained" onClick={() => this.deleteUser('enable')} disabled={isLoading || pendingUpdate}>
              <ButtonText>Activer l'utilisateur</ButtonText>
            </StyledButton>
          ) : null}
          {!currentUser.disabled ? (
            <StyledButton variant="contained" onClick={() => this.deleteUser('disable')} disabled={isLoading || pendingUpdate}>
              <ButtonText>Désactiver l'utilisateur</ButtonText>
            </StyledButton>
          ) : null}
          {config.enableUserDelete ? (
            <StyledButton
              color="secondary"
              variant="contained"
              onClick={() => this.deleteUser('delete')}
              disabled={isLoading || pendingUpdate}
            >
              <ButtonText>Supprimer l'utilisateur</ButtonText>
            </StyledButton>
          ) : null}
        </ButtonContainer>
      </StyledForm>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.users.isLoading,
    pendingUpdate: state.users.pendingUpdate,
    deleteSuccess: state.users.deleteSuccess,
    disableSuccess: state.users.disableSuccess,
    initialValues: state.users.user,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    users: bindActionCreators(allTheActions.users, dispatch),
  },
});

UserDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetails);

UserDetails = reduxForm({
  form: 'UserDetails',
  validate: values => {
    const { jokersChangeReason } = values;
    if (!jokersChangeReason) {
      return {
        jokersChangeReason: 'Une raison est nécessaire pour changer le nombre de jokers',
      };
    }
    return false;
  },
})(UserDetails);

export default UserDetails;
