import styled from 'styled-components';

export const AllContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledForm = styled.form`
  background-color: ${props => props.theme.color.mainFormColor};
  padding: 20px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const NumberFields = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`;

export const DurationField = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NbAnswersField = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NumberFieldsLabel = styled.div`
  display: flex;
  flex-direction: row;
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 8px;
  min-height: 1em;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1em;
`;

export const NumberFieldsControls = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledText = styled.div`
  outline: none;
  padding: 20px;
  font-size: 16px;
  width: 75px;
  background-color: #f7f7f7;
  text-align: center;
  align-self: center;
  user-select: none;
`;
export const OptionStyled = styled.option``;

export const ChangeDuration = styled.div`
  font-size: 16px;
  padding: 20px;
  cursor: pointer;
  background-color: #f7f7f7;
  user-select: none;
  &:hover {
    background-color: lightgrey;
  }
`;

export const ChangeNbAnswers = styled.div`
  font-size: 16px;
  padding: 20px;
  cursor: pointer;
  background-color: #f7f7f7;
  user-select: none;
  &:hover {
    background-color: lightgrey;
  }
`;

export const FormTopContainer = styled.div`
  min-height: 418px;
`;

export const FooterSection = styled.div`
  min-height: 86px;
`;

export const Line = styled.div`
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 2px black;
  display: flex;
  justify-content: stretch;
  margin: 10px 0px;
  padding: 5px;
`;
