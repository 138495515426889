import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import stopQuiz from './requests/stopQuiz';

export default function StopCell({ _id, onUpdate }) {
  const { enqueueSnackbar } = useSnackbar();
  const [waitingConfirmation, setWaitingConfirmation] = useState(false);
  if (!waitingConfirmation) {
    return (
      <TableCell>
        <Button onClick={() => {
          setWaitingConfirmation(true);
        }} variant="contained">Arrêter</Button>
      </TableCell>
    );
  }
  return (
    <TableCell>
      <Button color="secondary" onClick={async () => {
        await stopQuiz(enqueueSnackbar, { _id, endAt: 'now' });
        onUpdate();
      }} variant="contained">Arrêter</Button>
    </TableCell>
  );
}
