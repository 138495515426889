import React from 'react';
import styled from 'styled-components';

const Footercontainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const PaginationQuestion = styled.div`
  align-items: center;
  background-color: ${props => props.theme.color.mainFormColor};
  box-shadow: ${props => (props.isSelected ? '0px 0px 5px 2px rgba(0,0,0,0.3)' : '')};
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 5px;
  width: 40px;
`;

const createFooter = (nbQuestion, currentQuestion, actions) => {
  var pages = [];
  for (let i = 1; i <= nbQuestion; i++) {
    pages.push(
      <PaginationQuestion
        isSelected={i - 1 === currentQuestion}
        key={i}
        //onClick={() => actions.controls.currentQuestion(i - 1)}
      >
        {i}
      </PaginationQuestion>
    );
  }
  return pages;
};

export default class ControlsFooter extends React.Component {
  render() {
    const { actions, currentPage, page } = this.props;
    return <Footercontainer>{createFooter(page, currentPage, actions)}</Footercontainer>;
  }
}
