import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';
import FileUpload from './fileUpload';
import { storageServer, templateQuizzesCsv } from '../config';

import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';

import { CommonText } from '../components/texts';
import { TableHeader, TableContent } from '../components/quizTable';
import CardTitle from '../components/cardTitle';
import LoadingBar from '../components/loadingBar';
// import CSVQuizButton from '../components/csvButtons/CSVQuizButton';

import allTheActions from '../actions';

const StyledButton = styled(Button)`
  margin: 20px 0 0 40px;
`;

const ButtonText = styled.span``;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
`;

const SpinnerContainer = styled.div`
  padding: 20px 0;
  text-align: center;
  width: 100%;
`;

const StyledCard = styled.div`
  width: 100%;
`;

const QuizTableContainer = styled.div`
  padding: 0 24px;
`;

const LinkStyled = styled(Link)`
  text-decoration: none;
`;

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 100%;
`;

const StyledTableCell = styled(TableCell)`
  width: 100%;
`;

const FileDropContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const FileDrop = styled.div`
  width: 100%;
`;

class QuizTable extends React.PureComponent {
  state = {
    enableDownloadButton: true,
    limit: 50,
    page: 0,
    searchText: '',
    uploadModal: false,
  };

  downloadURI = (uri, name) => {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.quizzes.getQuizzes();
  }

  handleChangePage = page => {
    const { limit, searchText } = this.state;
    const { actions } = this.props;
    this.setState({ page }, () => actions.quizzes.getQuizzes(searchText, limit, page));
  };

  // we fetch again when we change number of items displayed on a page
  handleChangeLimit = value => {
    const { searchText } = this.state;
    const { actions } = this.props;
    this.setState({ limit: value, page: 0 }, () => actions.quizzes.getQuizzes(searchText, value, 0));
  };

  handleSubmit = async (event, searchText) => {
    const { actions } = this.props;
    this.setState({ page: 0, searchText }, () => actions.quizzes.getQuizzes(searchText));
    this.setState({ enableDownloadButton: true });
    event.preventDefault();
  };

  handleChangeInput = event => {
    this.setState({
      enableDownloadButton: false,
      searchText: event.target.value,
    });
  };

  render() {
    const { limit, page, searchText } = this.state;
    const { isLoading, quizzes, searchTerm } = this.props;
    return (
      <StyledCard>
        {quizzes.error ? (
          <ErrorContainer>
            <CommonText color="error">{quizzes.error}</CommonText>
          </ErrorContainer>
        ) : null}
        <LoadingBar isLoading={isLoading} />
        {this.state.uploadModal &&
          <FileDropContainer>
            <Button
            disabled={isLoading}
            variant="contained"
            onClick={() => this.downloadURI(templateQuizzesCsv, "templateQuizzesCsv.csv")}>
              <span>Télécharger un exemple</span>
            </Button>
          <FileDrop>
            <FileUpload route={`${storageServer}quizzesCsv/`}/>
          </FileDrop>
        </FileDropContainer>}
        <form onSubmit={event => this.handleSubmit(event, searchText)}>
          {CardTitle('Quiz')}
          <LinkStyled style={{marginLeft: '40px'}} to="/home/quizzes/creation">
            <StyledButton disabled={isLoading} variant="contained">
              <ButtonText>CRÉER</ButtonText>
            </StyledButton>
          </LinkStyled>
          {/*<StyledButton
            disabled={isLoading}
            variant="contained"
            onClick={() => this.setState({uploadModal: !this.state.uploadModal})}
          >
            <span>Créer depuis un CSV</span>
          </StyledButton>*/}
          <ButtonsContainer>
            <TextField
              id="searchTerm"
              label="Nom du quiz"
              name={searchTerm}
              onChange={event => this.handleChangeInput(event)}
              style={{ marginLeft: 40, display: 'block' }}
            />
            <Button
              disabled={isLoading}
              color="primary"
              variant="contained"
              onClick={event => this.handleSubmit(event, searchText)}
            >
              Rechercher
            </Button>
          </ButtonsContainer>
        </form>
        {/*
        <CSVQuizButton
          disabled={quizzes.isLoading}
          downloadQuizzes={actions.quizzes.downloadQuizzes}
        />
        */}
        <QuizTableContainer>
          <Table>
            <TableHeader />
            <TableBody>
              {!isLoading && quizzes && quizzes.data ? (
                <TableContent quizzes={quizzes.data} />
              ) : (
                <TableRow>
                  <StyledTableCell colSpan={11}>
                    <SpinnerContainer>
                      <CircularProgress size={24} />
                    </SpinnerContainer>
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!isLoading ? (
            <TablePagination
              colSpan={1}
              count={quizzes.count}
              onChangePage={(e, value) => this.handleChangePage(value)}
              onChangeRowsPerPage={({ target: { value } }) => this.handleChangeLimit(value)}
              page={page}
              rowsPerPage={limit < quizzes.count ? limit : quizzes.count}
              rowsPerPageOptions={quizzes.count > 50 ? [10, 50, 100] : quizzes.count > 10 ? [10, 50] : []}
            />
          ) : null}
        </QuizTableContainer>
      </StyledCard>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: {
    quizzes: bindActionCreators(allTheActions.quizzes, dispatch),
  },
});

const mapStateToProps = state => {
  return {
    isLoading: state.quizzes.isLoading,
    quizzes: state.quizzes,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuizTable);
