import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const TableContent = props => {
  return props.data.map((answer, index) => (
    <TableRow key={answer.id}>
      <TableCell>{`Question ${answer.order}`}</TableCell>
      <TableCell>{answer.questionFr}</TableCell>
      <TableCell>{answer.questionDz}</TableCell>
      <TableCell>{answer.answerId.toUpperCase() || "N'a pas répondu"}</TableCell>
      <TableCell>{answer.answerFr}</TableCell>
      <TableCell>{answer.answerDz}</TableCell>
      <TableCell>{answer.isRight && '✓'}</TableCell>
      <TableCell>{answer.jokerType && '✓'}</TableCell>
    </TableRow>
  ));
};

export default TableContent;
