import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import { config } from './firebase';
import reducers from '../reducers';

export const history = createBrowserHistory();

let middleware = [];
middleware.push(thunk);
const i = 0;
if (config.dev && i > 1) {
  middleware.push(logger);
}

export const store = createStore(
  connectRouter(history)(reducers),
  applyMiddleware(
    routerMiddleware(history), // for dispatching history actions
    ...middleware
  )
);
