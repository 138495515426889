import {
  CANNOT_CHANGE_FORM,
  DISPLAY_QUIZ,
  DISPLAY_QUIZZES,
  ERROR_FETCH_QUIZZ,
  PENDING,
  PENDING_DOWNLOAD,
  QUIZZES_DOWNLOAD_LINK,
  QUIZZES_CLEAR_LINK,
  QUIZ_GUESS_ANSWER,
} from "../actions/quizzes";

import { quizCreateInitialState } from "./reduxFormInitialStates";

const initialState = {
  cannotChangeForm: true,
  count: 0,
  data: [],
  error: "",
  isDownloading: false,
  isLoading: false,
  link: '',
  quiz: [],
  quizCreateInitialState: quizCreateInitialState,
  permissions: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_QUIZZES:
      return {
        ...state,
        data: action.payload.quizzes,
        count: action.payload.count,
        error: ""
      };
    case QUIZ_GUESS_ANSWER:
      state.quiz[0].answers[action.payload.currentPage] = action.payload;
      return {
        ...state
      };
    case DISPLAY_QUIZ:
      return {
        ...state,
        quiz: action.payload,
        permissions: action.permissions,
        error: ""
      };
    case ERROR_FETCH_QUIZZ:
      return {
        ...state,
        error: action.payload.message
      };
    case CANNOT_CHANGE_FORM:
      return {
        ...state,
        cannotChangeForm: action.payload
      };
    case PENDING:
      return {
        ...state,
        isLoading: action.payload
      };
    case PENDING_DOWNLOAD:
      return {
        ...state,
        isDownloading: action.payload
      };
    case QUIZZES_DOWNLOAD_LINK:
      console.log(action.payload);
      return {
        ...state,
        link: action.payload
      };
    case QUIZZES_CLEAR_LINK:
      return {
        ...state,
        link: ''
      };
    default:
      return state;
  }
};
