import Button from '@material-ui/core/Button';
import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ReactAudioPlayer from 'react-audio-player';
import styled from 'styled-components';
import moment from 'moment';
import { cloneDeep } from 'lodash';

const StyledButtonMusique = styled(Button)`
  background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%);
  border-radius: 10px;
  border: 0;
  color: black;
  height: 48px;
  padding: 0 30px;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);
`;

class TableContent extends React.Component {
  state = {
    error: [],
  };

  render(){
    return this.props.data.map((el, index) => {
      return (
        <TableRow key={el._id} style={{backgroundColor: (this.state.error.includes(el._id)) ? 'red' : ''}}>
          <TableCell>{el.name}</TableCell>
          <TableCell>
            <StyledButtonMusique onClick={() => this.props.handleChange(el.playlistUrl)}>Choisir cette musique</StyledButtonMusique>
          </TableCell>
          <TableCell>
            <ReactAudioPlayer
              src={el.originUrl}
              onError={() => {
                const error = cloneDeep(this.state.error);
                error.push(el._id);
                this.setState({error});
              }}
              controls
            />
          </TableCell>
          <TableCell>
            <ReactAudioPlayer
              src={el.playlistUrl}
              onError={() => {
                const error = cloneDeep(this.state.error);
                error.push(el._id);
                this.setState({error});
              }}
              controls
            />
          </TableCell>
          <TableCell>{moment(el.createdAt).format('D MMMM YYYY à HH:mm')}</TableCell>
          <TableCell>
              <StyledButtonMusique style={{height: 5, width: 2}} onClick={() => this.props.deleteFile(el.playlistUrl, el.name)}>✖</StyledButtonMusique>
          </TableCell>
        </TableRow>
      );
    });
  }
};

export default TableContent;
