import React, {Component, useCallback, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import allTheActions from '../../actions';
import LoadingBar from '../../components/loadingBar';
import { Container, FormControl, InputLabel, Input, Button, Grid } from "@material-ui/core";
import axios from "axios";
import {storageServer} from "../../config";


const FormControlReplay = styled(FormControl)`
  width: 100%;
`;

const ReplaySaveButton = styled(Button)`
  padding: 20px;
  width: 50%;
`;

class ReplayControler {
  static postReplay(primary, link, title, subtitle, id) {
    return axios ({
      method: 'POST',
      url: `${storageServer}replays`,
      data: {
        primary,
        link,
        title,
        subtitle,
      },
    });
  }

  static editReplay(primary, link, title, subtitle, id) {
    return axios ({
      method: 'PUT',
      url: `${storageServer}replays`,
      data: {
        primary,
        link,
        title,
        subtitle,
        id,
      },
    });
  }

  static getReplays() {
    return axios ({
      method: 'GET',
      url: `${storageServer}getReplays`,
    });
  }
}

function ReplayForm(props) {
  const {adder, title, key, primary = false, id = '', defaultData = ''} = props;
  const [youtubeLink, setYoutubeLink] = useState(defaultData !== '' ? defaultData.link : '');
  const [videoTitle, setVideoTitle] = useState(defaultData !== '' ? defaultData.title : '');
  const [videoSubtitle, setVideoSubtitle] = useState(defaultData !== '' ? defaultData.subtitle : '');
  const [error, setError] = useState('');
  const [successPut, setSuccessPut] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const regYtb = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm;

  useEffect(() => {
    if (defaultData) setEditMode(true);
  }, [defaultData]);

  let verifyInfos = useCallback(() => {
    let errOcu = true;

    if (!regYtb.test(youtubeLink)) {
      setError('Veuillez specifier un lien youtube valide')
      setSuccessPut(false);
    } else if (videoTitle === '') {
      setError('Veuillez specifier un titre de video');
      setSuccessPut(false);
    } else if (videoSubtitle === '') {
      setError('Veuillez specifier un sous-titre');
      setSuccessPut(false);
    } else {
      setError('');
      errOcu = false;
    }
    return errOcu;
  }, [regYtb, videoSubtitle, videoTitle, youtubeLink]);

  let submitInfos = useCallback(() => {
    if (verifyInfos())
      return;
    if (editMode) {
      ReplayControler.editReplay(primary, youtubeLink, videoTitle, videoSubtitle, id)
        .then(() => {
          setSuccessPut(true);
          window.location.reload();
          return true;
        })
        .catch((e) => setError(e.message));
    } else {
      ReplayControler.postReplay(primary, youtubeLink, videoTitle, videoSubtitle, id)
        .then(() => {
          setSuccessPut(true);
          if (!adder)
            setEditMode(true);
          else {
            window.location.reload();
          }
          return true;
        })
        .catch((e) => setError(e.message));
    }

    console.log('All fine');
  }, [editMode, adder, id, primary, youtubeLink, videoSubtitle, videoTitle, verifyInfos]);

  let mainTitle = (adder || primary) ? <h3 style={{padding: 0, margin: 0, color: "#33AFFF"}}>{title}</h3> : <h5 style={{padding: 0, margin: 0}}>{title}</h5>;
  return <Grid container spacing={3} style={{paddingTop: 30}}>
    <Grid item xs={12} >
      {mainTitle}
    </Grid>
    <Grid item xs={6} sm={3}>
      <FormControlReplay>
        <InputLabel htmlFor="youtubeLink">Lien youtube</InputLabel>
        <Input defaultValue={youtubeLink} onChange={(event) => setYoutubeLink(event.target.value)} id={`youtubeLink${key}`} />
      </FormControlReplay>
    </Grid>
    <Grid item xs={6} sm={3}>
      <FormControlReplay>
        <InputLabel htmlFor="videoTitle">Titre de la video</InputLabel>
        <Input defaultValue={videoTitle} onChange={(event) => setVideoTitle(event.target.value)} id={`videoTitle${key}`} />
      </FormControlReplay>
    </Grid>
    <Grid item xs={6} sm={3}>
      <FormControlReplay>
        <InputLabel htmlFor="videoSubtitle">Sous-titre de la video</InputLabel>
        <Input defaultValue={videoSubtitle} onChange={(event) => setVideoSubtitle(event.target.value)} id={`videoSubtitle${key}`} />
      </FormControlReplay>
    </Grid>
    <Grid item xs={6} sm={3} style={{paddingTop: 25}}>
      <ReplaySaveButton onClick={submitInfos} variant="contained" color="primary">
        {defaultData !== '' || editMode ? 'Modifier' : 'Valider'}
      </ReplaySaveButton>
    </Grid>
    <Grid item xs={12} >
      <p style={{color: 'red', padding: 0, margin: 0}}>{error} <span style={{color: 'green'}}>{successPut ? ' - Replay pris en compte !' : ''}</span></p>
    </Grid>
  </Grid>;
}


class DashboardReplay extends Component {
  state = {
    replays: [],
    loaded: false,
    primary: '',
  };

  static propTypes = {
  };

  componentDidMount() {
    ReplayControler.getReplays().then((data) => {
      let replays = data.data;

      console.log('[REPLAYS] data: ', replays);
      replays.forEach((val) => {
        if (val.primary) {
          this.setState({primary: val});
          replays.splice(replays.indexOf(val), 1);
        }
      })
      this.setState({replays: replays});
      this.setState({loaded: true});
      return true;
    }).catch(e => console.log(e));
  }

  render() {
    const { replays } = this.state;

    return (
      <Container maxWidth="lg">
        <LoadingBar isLoading={!this.state.loaded} />
        {this.state.loaded &&
        <div>
          <ReplayForm title='Video primaire ( en premiere position dans la page )' primary={true} defaultData={this.state.primary}/>
          <ReplayForm title='Ajouter un replay' adder={true}/>
          <hr style={{marginTop: 50}}/>
          {replays.map((replay) => (<ReplayForm key={replay.id} id={replay.id} title={replay.title} defaultData={replay} />))}
        </div>
        }
      </Container>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  cardsAction: bindActionCreators(allTheActions.cards, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardReplay);

