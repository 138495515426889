import { Field } from 'redux-form';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import allTheActions from '../../actions';
import FileUpload from '../../screens/fileUpload';
import { InputText, renderSwitch } from '../reduxFormsComponents';

const QuestionsQuiz = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuestionContainer = styled.div`
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 2px black;
  display: flex;
  justify-content: stretch;
  margin: 10px 0px;
  padding: 5px;
`;

const LetterContainer = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  margin-right: 15px;
  width: 65px;
`;

const uploadParams = {
  height: 10,
  accept: 'image/png',
  uploadPath: 'backOfficeUploads/questionImage',
  multiple: false,
};

class ImageQuestionsField extends React.Component {
  state = {
    selectedUpload: '',
  }

  componentDidUpdate = () => {
    const { fileLink, actions } = this.props;
    const { selectedUpload } = this.state;
    if (fileLink && selectedUpload !== '') {
      this.props.change(`normalQuestions.${selectedUpload}`, fileLink);
      setTimeout(() => {
        this.setState({selectedUpload: ''})
        actions.fileLinkClear();
      }, 300);
    }
  }

  renderAnswer(id) {
    const { quizzes, isSurvey, answers} = this.props;
    return (
      <QuestionContainer key={id}>
        <LetterContainer>{id}</LetterContainer>
        {answers && !quizzes.isLoading && <LetterContainer>{answers[id]}%</LetterContainer>}
        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
          <Field component={InputText} disabled={quizzes.isLoading} name={id} placeholder="Insérez le lien" type="text"/>
          <div onDrop={() => this.setState({selectedUpload: id})} onClick={() => this.setState({selectedUpload: id})}><FileUpload {...uploadParams}/></div>
        </div>
        {!isSurvey && <Field component={renderSwitch} name={`${id}Switch`} value={id} />}
      </QuestionContainer>
    );
  }

  render() {
    const { nbAnswers } = this.props;
    const answersId = ['A', 'B', 'C', 'D'].slice(0, nbAnswers || 4);
    return <QuestionsQuiz>{answersId.map(answerId => this.renderAnswer(answerId))}</QuestionsQuiz>;
  }
}

const mapStateToProps = state => {
  return {
    fileLink: state.upload.fileLink,
  };
}

const mapDispatchToProps = dispatch => ({
  actions: {
    fileLinkClear: bindActionCreators(allTheActions.upload.fileLinkClear, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImageQuestionsField);
