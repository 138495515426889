import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { defaultQuestionDuration } from '../../config';

import allTheActions from '../../actions';
import { InputText, renderSwitch } from '../../components/reduxFormsComponents';

import TableHeader from './components/TableHeader';

import {
  StyledForm,
  StyledText,
  FooterSection,
  FormTopContainer,
  NbAnswersField,
  NumberFields,
  NumberFieldsControls,
  NumberFieldsLabel,
  OptionStyled,
  DurationField,
  ChangeDuration,
  ChangeNbAnswers,
  AllContainer,
  Line,
} from './styles';

class CreateSurvey extends React.Component {
  state = {
    newSurvey: false,
    editSurvey: '',
    Confirm: '',
    nbAnswers: 4,
    durationInSeconds: defaultQuestionDuration / 1000,
    answers: [
      { content: { dz: '', fr: ''}, id: 'a' },
      { content: { dz: '', fr: ''}, id: 'b' },
      { content: { dz: '', fr: ''}, id: 'c' },
      { content: { dz: '', fr: ''}, id: 'd' },
    ],
    prizeType: 'survey',
    question: { dz: '', fr: '' },
  };

  componentDidMount = () => {
    const { actions } = this.props;
    actions.survey.getSurveyList();
  };

  _handleTypeChange = value => {
    this.setState({ prizeType: value.currentTarget.value });
  };

  editSurvey = (question) => {
    this.props.initialize(question)
    this.setState({
      newSurvey: true,
      editSurvey: question._id,
      prizeType: question.prizeType || 'survey',
      durationInSeconds: question.duration / 1000,
      nbAnswers: question.answers.length,
      prizeQuantity: question.prizeQuantity,
      answers: question.answers,
    });
  }

  TableContent = () => {
    const { Confirm } = this.state;
    const { actions, videoChunkTime, quizId, surveysredu } = this.props;
    
    if (!surveysredu.surveyList) return;
    return surveysredu.surveyList.map(questions => {
      return (
        <TableRow key={questions._id}>
          <TableCell>{(questions.prizeType) ? 'Bonus' : 'Sondage'}</TableCell>
          <TableCell>{(questions.prizeQuantity) ? questions.prizeQuantity : ''}</TableCell>
          <TableCell>{(questions.prizeType) ? questions.prizeType : ''}</TableCell>
          <TableCell/>
          <TableCell>
            <TableRow>{questions.question.fr}</TableRow>
            <TableRow>{questions.question.dz}</TableRow>
          </TableCell>
          {questions.answers.map((ans) =>
            <TableCell key={ans.id} >
              <TableRow style={{color: (ans.isRight) ? 'green' : 'black'}}>{ans.content.fr}</TableRow>
              <TableRow style={{color: (ans.isRight) ? 'green' : 'black'}}>{ans.content.dz}</TableRow>
            </TableCell>
          )}
          <TableCell>
            {!!quizId && (Confirm !== questions._id ? 
            <Button
              color="primary"
              variant="contained"
              style={{width: '100px'}}
              onClick={() => this.setState({Confirm: questions._id})}
            >
              PUBLIER 
            </Button> 
            :
            <Button
              color="secondary"
              variant="contained"
              style={{width: '100px'}}
              onClick={() => {
                questions.quizId = quizId;
                actions.survey.postSurvey(questions, actions, videoChunkTime);
                actions.survey.deleteSurvey(questions._id);
                this.setState({Confirm: ''});
                actions.hideModal();
              }}
            >
              CONFIRMER 
            </Button>)}
            {!quizId && 
            <Button
              color="secondary"
              variant="contained"
              style={{width: '100px'}}
              onClick={() => {
                actions.survey.deleteSurvey(questions._id)
                  .then(() => actions.survey.getSurveyList())
                  .catch((e) => console.log(e.message));
              }}
            >
              SUPPRIMER
            </Button>}
            <Button
              color="primary"
              variant="contained"
              style={{width: '100px'}}
              onClick={() => this.editSurvey(questions)}
            >
              MODIFIER
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  };

  _renderSurveyList = () => {
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <tbody>
          <TableHeader />
          {this.TableContent()}
        </tbody>
        <Button
          color="primary"
          onClick={() => {
            this.props.initialize({});
            this.setState({ 
              newSurvey: true,
              prizeType: 'survey',
              nbAnswers: 4,
              durationInSeconds: 10,
              prizeQuantity: 1
            })
          }}
        >
          CREER UN NOUVEAU SONDAGE
        </Button>
      </div>
    );
  };

  submit = (values) => {
    let { question = {}, answers = [] } = values
    console.log(values);
    const { actions } = this.props;
    const { durationInSeconds, nbAnswers, prizeQuantity, prizeType } = this.state;
    const survey = {};
    try {
      if (!question) throw new Error('Pas de contenu dans la question');
      if (!question.dz) throw new Error('Pas de contenu DZ dans la question');
      if (!question.fr) throw new Error('Pas de contenu FR dans la question');
      survey.question = question;
      survey.answers = answers.slice(0, nbAnswers).map((answer, index) => {
        const id = 'abcd'[index];
        if (!answer || !answer.content) throw new Error(`Pas de contenu dans la réponse ${id}`);
        if (!answer.content.dz) throw new Error(`Pas de contenu DZ dans la réponse ${id}`);
        if (!answer.content.fr) throw new Error(`Pas de contenu FR dans la réponse ${id}`);
        answer.id = id;
        answer.isRight = prizeType === 'survey' || !!answer.isRight;
        answer.nbAnswers = 0;
        return answer;
      });
      if (!answers.some(answer => answer.isRight)) throw new Error('Aucune bonne réponse');
      survey.duration = durationInSeconds * 1000;
      console.log(survey);
      if (prizeType === 'jokers') {
        survey.prizeQuantity = prizeQuantity;
        survey.prizeType = 'jokers';
      }
      actions.survey.createSurvey(survey);
      setTimeout(() => {
        actions.survey.getSurveyList();
        this.setState({newSurvey: false, editSurvey: ''});
      }, 1000);
    } catch (error) {
      alert(error.message);
    }
  };

  _changePrizeQuantity = value => {
    const { prizeQuantity = 1 } = this.state;
    let newPrizeQuantity = prizeQuantity + value;

    if (newPrizeQuantity < 1) newPrizeQuantity = 1;
    this.setState({ prizeQuantity: newPrizeQuantity });
  };

  _changeDuration = value => {
    const { durationInSeconds } = this.state;
    if ((value === 1 && durationInSeconds < 100) || (value === -1 && durationInSeconds > 1)) {
      this.setState({ durationInSeconds: durationInSeconds + value });
    }
  };

  _changeNbAnswers = value => {
    const { nbAnswers, answers, allAnswers } = this.state;
    let answersArray = JSON.parse(JSON.stringify(answers));
    if (value === 1 && nbAnswers < 4) {
      answersArray.push(allAnswers[nbAnswers]);
    } else if (value === -1 && nbAnswers > 1) {
      answersArray.pop();
    }
    if ((value === 1 && nbAnswers < 4) || (value === -1 && nbAnswers > 1)) {
      this.setState({ nbAnswers: nbAnswers + value, answers: answersArray });
    }
  };

  _renderAnswers() {
    const { answers, prizeType } = this.state;
    return answers.map((answer, index) => (
      <Line key={answer.id}>
        <Field
          component={InputText}
          name={`answers.${index}.content.fr`}
          placeholder="Insérez la réponse"
          type="text"
        />
        <Field
          component={InputText}
          dir='rtl'
          name={`answers.${index}.content.dz`}
          placeholder="أدخل الإجابة"
          type="text"
        />
        {prizeType !== 'survey' &&
          <Field
            component={renderSwitch}
            name={`answers.${index}.isRight`}
        />}
      </Line>
    ));
  }

  _renderNewSurveyCreation = () => {
    const { handleSubmit, pristine, submitting, actions } = this.props;
    const {
      durationInSeconds,
      editSurvey,
      nbAnswers,
      prizeQuantity = 1,
      prizeType,
    } = this.state;

    return (
      <StyledForm onSubmit={handleSubmit(value => this.submit(value))}>
        <Button
          color="primary"
          onClick={() => {
            actions.survey.getSurveyList();
            this.setState({ newSurvey: false })}
          }
        >
          RETOUR
        </Button>
        <FormTopContainer>
          <Line>
            <Field
              component={InputText}
              name="question.fr"
              placeholder="Insérez la question"
              type="text"
            />
            <Field
              component={InputText}
              dir='rtl'
              name="question.dz"
              placeholder="السؤال"
              type="text"
            />
          </Line>
          <NumberFields>
            <Field
              component="select"
              name="prizeType"
              onChange={this._handleTypeChange}
              style={{height:'28px'}}
              value="mcq"
            >
              <OptionStyled value="survey">Sondage</OptionStyled>
              <OptionStyled value="jokers">Question bonus</OptionStyled>
            </Field>
            {prizeType === 'jokers' && (
              <DurationField>
                <NumberFieldsControls>
                  <ChangeDuration onClick={() => this._changePrizeQuantity(-1)}>-</ChangeDuration>
                  <StyledText>{prizeQuantity}</StyledText>
                  <ChangeDuration onClick={() => this._changePrizeQuantity(1)}>+</ChangeDuration>
                </NumberFieldsControls>
                <NumberFieldsLabel>Nombre de jokers</NumberFieldsLabel>
              </DurationField>
            )}
          </NumberFields>
          <NumberFields>
            <DurationField>
              <NumberFieldsControls>
                <ChangeDuration onClick={() => this._changeDuration(-1)}>-</ChangeDuration>
                <StyledText>{durationInSeconds}</StyledText>
                <ChangeDuration onClick={() => this._changeDuration(1)}>+</ChangeDuration>
              </NumberFieldsControls>
              <NumberFieldsLabel>Durée en secondes</NumberFieldsLabel>
            </DurationField>
            <NbAnswersField>
              <NumberFieldsControls>
                <ChangeNbAnswers onClick={() => this._changeNbAnswers(-1)}>-</ChangeNbAnswers>
                <StyledText>{nbAnswers}</StyledText>
                <ChangeNbAnswers onClick={() => this._changeNbAnswers(1)}>+</ChangeNbAnswers>
              </NumberFieldsControls>
              <NumberFieldsLabel>Nombre de réponses</NumberFieldsLabel>
            </NbAnswersField>
          </NumberFields>
          {this._renderAnswers()}
        </FormTopContainer>
        <FooterSection>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            disabled={(editSurvey) ? false : (pristine || submitting)}
          >
            {(!editSurvey) ? 'CRÉER' : 'MODIFIER'}
          </Button>
        </FooterSection>
      </StyledForm>
    );
  }

  render() {
    const { newSurvey } = this.state;
    
    return (
      <AllContainer>
        {!newSurvey ? this._renderSurveyList() : this._renderNewSurveyCreation()}
      </AllContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    surveysredu: state.surveys,
    videoChunkTime: state.controls.videoChunkTime,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    hideModal: bindActionCreators(allTheActions.modal.hideModal, dispatch),
    showModal: bindActionCreators(allTheActions.modal.showModal, dispatch),
    survey: bindActionCreators(allTheActions.surveys, dispatch),
    quizzes: bindActionCreators(allTheActions.quizzes, dispatch),
  },
});

CreateSurvey.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  quizId: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
};

export default reduxForm({
  form: 'CreateSurvey'
})(connect(mapStateToProps, mapDispatchToProps)(CreateSurvey));
