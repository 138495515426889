import React from 'react';
import styled from 'styled-components';
import LeftPad from 'just-left-pad';
import { animateScroll as scroll } from 'react-scroll';
import { PlayArrow, Pause } from '@material-ui/icons';
import { List, AutoSizer } from 'react-virtualized';
import * as workerTimers from 'worker-timers';
import { Button, CircularProgress, Grid } from '@material-ui/core';

import { CommonText } from '../texts';
import LoadingScreen from '../loadingScreen';

const Message = styled.span`
  font-weight: bold;
  word-break: break-word;
  font-size: ${props => `${props.fontSize}px`};
`;

const Nickname = styled.span`
  font-weight: lighter;
  color: ${props => props.theme.color.messageNickname};
  font-size: ${props => props.fontSize || 14}px;
`;

const RowNumber = styled.span`
  font-size: ${props => props.fontSize || 14}px;
`;

const CreatedAtTime = styled.span`
  font-size: ${props => props.fontSize || 14}px;
`;

const IncDecButton = styled.button`
  border-radius: ${props => props.size + 10 || 25}px;
  height: ${props => props.size + 10 || 25}px;
  width: ${props => props.size + 10 || 25}px;
  margin: 0px 10px;
  cursor: Pointer;
  outline: none;
`;

const MessageContainer = styled.div`
  min-width: 960px;
`;

const Row = styled.div`
  flex-grow: 1;
  background-color: ${props => props.backgroundColor || '#fff'};
`;

const RowHead = styled.div`
  display: flex;
  align-items: center;
  background-color: #6ec2e1;
  color: #fff;
  border-radius: 5px;
`;

const BtnIcon = styled.div`
  cursor: pointer;
`;

const CenteredGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

const StyledCenteredGrid = styled(CenteredGrid)`
  justify-content: flex-end;
`;

const StyledGrid = styled(Grid)`
  padding: 8px;
`;

const StyledButtonGrid = styled(StyledGrid)`
  display: flex;
  justify-content: flex-end;
`;

const ButtonText = styled.span``;

class MessagesDisplayer extends React.PureComponent {
  state = {
    messageId: ''
  };

  _convertMessageTime = time => {
    const d = new Date(time);
    const hours = LeftPad(`${d.getHours()}`, 2, '0');
    const minutes = LeftPad(`${d.getMinutes()}`, 2, '0');
    const seconds = LeftPad(`${d.getSeconds()}`, 2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  _scrollPrompter = () => {
    const { scrollSpeed, pause } = this.props;
    if (scrollSpeed && !pause) {
      scroll.scrollMore(100, {
        containerId: 'scrollContainer',
        duration: scrollSpeed * 100
      });
    }
    if (this._scrollInterval) workerTimers.clearTimeout(this._scrollInterval);
    this._scrollInterval = workerTimers.setTimeout(
      () => this._scrollPrompter(),
      scrollSpeed * 100
    );
  };

  componentWillUnmount() {
    if (this._scrollInterval) workerTimers.clearTimeout(this._scrollInterval);
  }

  componentDidMount() {
    this._scrollPrompter();
  }

  componentDidUpdate(PrevProps, PrevStates) {
    if (PrevProps.scrollSpeed !== this.props.scrollSpeed) {
      this._scrollPrompter();
    }
  }

  renderTable = messages => (
    <AutoSizer>
      {({ width }) => (
        <List
          width={width}
          height={600}
          rowHeight={50}
          rowRenderer={this.renderRow}
          rowCount={messages.length}
          id="scrollContainer"
          onLoad={() => this.refs.item.focus()}
          ref="item"
        />
      )}
    </AutoSizer>
  );

  onMute = (userId, messageId) => {
    const { muteUser } = this.props;
    this.setState({ messageId });
    muteUser(userId);
  };

  renderRow = ({ index, key, style }) => {
    const { fontSize, isMuting, messages = [{}] } = this.props;
    const { messageId } = this.state;
    const message = messages[index];
    return (
      <Row
        style={style}
        odd={index}
        key={message._id}
        backgroundColor={index % 2 ? '#ebebeb' : '#fff'}
      >
        <Grid container spacing={0}>
          <CenteredGrid item xs={1}>
            <StyledGrid>
              <RowNumber fontSize={fontSize}>{index}</RowNumber>
            </StyledGrid>
          </CenteredGrid>
          <CenteredGrid item xs={1}>
            <StyledGrid>
              <CreatedAtTime fontSize={fontSize}>{this._convertMessageTime(message.createdAt)}</CreatedAtTime>
            </StyledGrid>
          </CenteredGrid>
          <CenteredGrid item xs={2}>
            <StyledGrid>
              {
                <Nickname fontSize={fontSize}>{`${message.nickname ||
                '-'}`}</Nickname>
              }
            </StyledGrid>
          </CenteredGrid>
          <CenteredGrid item xs={7}>
            <StyledGrid>
              <Message fontSize={fontSize}>{`${message.message}`}</Message>
            </StyledGrid>
          </CenteredGrid>
          <StyledCenteredGrid item xs={1}>
            <StyledButtonGrid>
              <Button
                disabled={isMuting}
                variant="contained"
                onClick={() => this.onMute(message.userId, message._id)}
              >
                {isMuting && message._id === messageId ? (
                  <CircularProgress size={24} />
                ) : (
                  <ButtonText>MUTE</ButtonText>
                )}
              </Button>
            </StyledButtonGrid>
          </StyledCenteredGrid>
        </Grid>
      </Row>
    );
  };

  render() {
    const {
      fontSize,
      scrollSpeed,
      changeScrollSpeed,
      changeFontSize,
      messages,
      pauseMessages,
      pause,
      mode,
      isLoading
    } = this.props;
    return (
      <MessageContainer>
        <RowHead noHightLight>
          <Grid container spacing={0}>
            <CenteredGrid item xs={1}>
              <StyledGrid fontSize={fontSize}>ID</StyledGrid>
            </CenteredGrid>
            <CenteredGrid item xs={1}>
              <StyledGrid fontSize={fontSize}>Heure</StyledGrid>
            </CenteredGrid>
            <CenteredGrid item xs={2}>
              <StyledGrid fontSize={fontSize}>Pseudo</StyledGrid>
            </CenteredGrid>
            <CenteredGrid item xs={1}>
              <StyledGrid fontSize={fontSize}>Messages</StyledGrid>
            </CenteredGrid>
            <CenteredGrid item xs={2}>
              <StyledGrid fontSize={fontSize}>
                <IncDecButton
                  onClick={() => changeFontSize(false)}
                  size={fontSize}
                >
                  <CommonText>-</CommonText>
                </IncDecButton>
                {fontSize} px
                <IncDecButton
                  onClick={() => changeFontSize(true)}
                  size={fontSize}
                >
                  <CommonText>+</CommonText>
                </IncDecButton>
              </StyledGrid>
            </CenteredGrid>
            {mode !== 'readonly' ? (
              <CenteredGrid item xs={3}>
                <StyledGrid fontSize={fontSize}>
                  Vitesse
                  <IncDecButton
                    onClick={() => changeScrollSpeed(false)}
                    size={fontSize}
                  >
                    <CommonText>-</CommonText>
                  </IncDecButton>
                  {30 - scrollSpeed}
                  <IncDecButton
                    onClick={() => changeScrollSpeed(true)}
                    size={fontSize}
                  >
                    <CommonText>+</CommonText>
                  </IncDecButton>
                </StyledGrid>
              </CenteredGrid>
            ) : null}
            {mode !== 'readonly' ? (
              <CenteredGrid item xs={1}>
                <StyledGrid fontSize={fontSize}>
                  <BtnIcon>
                    {!pause ? (
                      <Pause onClick={() => pauseMessages()} />
                    ) : (
                      <PlayArrow onClick={() => pauseMessages()} />
                    )}
                  </BtnIcon>
                </StyledGrid>
              </CenteredGrid>
            ) : null}
            {mode !== 'readonly' ? (
              <CenteredGrid item xs={1}>
                <StyledGrid fontSize={fontSize}>Mute</StyledGrid>
              </CenteredGrid>
            ) : null}
          </Grid>
        </RowHead>
        {isLoading ? (
          <LoadingScreen content="EN ATTENTE DE RÉPONSES" viewHeight={50} />
        ) : (
          this.renderTable(messages)
        )}
      </MessageContainer>
    );
  }
}

export default MessagesDisplayer;
