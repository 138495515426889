import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class TableHeader extends React.PureComponent {
  render() {
    return (
      <TableHead>
        <TableRow>
          <TableCell>Nom</TableCell>
          <TableCell>ID</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Démarré à</TableCell>
          <TableCell>Terminé à</TableCell>
          <TableCell>Somme à gagner</TableCell>
          <TableCell>Nombre de questions</TableCell>
          <TableCell>Visualiser</TableCell>
          <TableCell>Arrêter</TableCell>
        </TableRow>
      </TableHead>
    );
  }
}
export default TableHeader;
