import axios from 'axios';

import { config } from '../config/firebase';
import { history } from '../config/store';
import createQuestions from '../utils/createQuestions';

export const CANNOT_CHANGE_FORM = 'CANNOT_CHANGE_FORM';
export const DISPLAY_QUIZ = 'DISPLAY_QUIZ';
export const DISPLAY_QUIZZES = 'DISPLAY_QUIZZES';
export const ERROR_FETCH_QUIZZ = 'ERROR_FETCH_QUIZZ';
export const PENDING = 'PENDING';
export const PENDING_DOWNLOAD = 'PENDING_DOWNLOAD';
export const QUIZZES_DOWNLOAD_LINK = 'QUIZZES_DOWNLOAD_LINK';
export const QUIZZES_CLEAR_LINK = 'QUIZZES_CLEAR_LINK';
export const QUIZ_GUESS_ANSWER = 'QUIZ_GUESS_ANSWER';

export const displayQuizzes = quiz => ({
  type: 'DISPLAY_QUIZZES',
  payload: quiz,
});

export const pending = bool => ({
  type: PENDING,
  payload: bool,
});

export const pendingDownload = bool => ({
  type: PENDING_DOWNLOAD,
  payload: bool,
});

export const displayQuiz = (quizzes, permissions) => ({
  type: 'DISPLAY_QUIZ',
  payload: quizzes,
  permissions,
});

export const cannotChangeForm = bool => ({
  type: 'CANNOT_CHANGE_FORM',
  payload: bool,
});

export const displayQuizError = err => ({
  type: 'ERROR_FETCH_QUIZZ',
  payload: err,
});

export const quizzesDownloadLink = link => ({
  type: 'QUIZZES_DOWNLOAD_LINK',
  payload: link,
});

export const quizzesClearLink = link => ({
  type: 'QUIZZES_CLEAR_LINK',
  payload: link,
});

export const quizGuessAnswer = answers => ({
  type: 'QUIZ_GUESS_ANSWER',
  payload: answers,
});

export const getQuizzes = (searchTerm, limit = 50, page = 0) => dispatch => {
  dispatch(pending(true));
  axios({
    method: 'get',
    url: `${config.cloudHttps}/httpsAdminQuizzesList`,
    params: {
      limit,
      searchTerm,
      skip: page * limit,
    },
  })
    .then(res => {
      dispatch(displayQuizzes(res.data));
      dispatch(pending(false));
      return;
    })
    .catch(err => {
      console.log(err);
      dispatch(displayQuizError(err));
      dispatch(pending(false));
    });
};

export const postQuiz = values => dispatch => {
  dispatch(pending(true));
  console.log(JSON.stringify(values, null, 2));
  const {
    controller,
    loosersText,
    maxJokersNext,
    name,
    nbQuestions,
    player,
    prizepool,
    prizeType,
    programName,
    startAt,
    theme,
  } = values;
  const permissions = {
    player: player.split(',').map(value => value.trim()),
    controller: controller.split(',').map(value => value.trim()),
  };
  const questions = createQuestions(nbQuestions);
  axios({
    method: 'POST',
    url: `${config.cloudHttps}/httpsQuizzesAdminPost`,
    data: {
      addPlayers: 0,
      delayHideAnswer: 6000,
      goLive: false,
      inAppEndAt: startAt,
      inAppStartAt: new Date(new Date(startAt).getTime() - 300000),
      isBroadcasting: false,
      isEnded: false,
      isStarted: false,
      loosersText,
      liveVideoUri: {
        android: config.defaultLiveVideoUri,
        ios: config.defaultLiveVideoUri,
      },
      maxJokersCopycat: 0,
      maxJokersNext,
      name,
      nbQuestions: Number(nbQuestions),
      permissions,
      prizepool,
      prizeType,
      programName,
      questions,
      startAt,
      subtitle: "",
      theme,
      type: "live",
    },
  })
    .then(res => {
      dispatch(pending(false));
      history.push(`/home/quizzes/edition/${res.data.id}/0`);
      return;
    })
    .catch(err => {
      // TODO: handle error
      console.error(err)
      dispatch(pending(false));
      return err;
    });
};

export const getQuiz = id => dispatch => {
  dispatch(pending(true));
  axios({
    method: 'GET',
    url: `${config.cloudHttps}/httpsQuizzesAdminGet`,
    params: {
      id,
    },
  })
    .then(res => {
      dispatch(pending(false));
      dispatch(displayQuiz(res.data.quizzes, res.data.permissions));
      return;
    })
    .catch(err => {
      dispatch(pending(false));
      return err;
    });
};

export const putQuiz = values => dispatch => {
  dispatch(pending(true));
  const {
    addPlayers,
    delayHideAnswer,
    id,
    isBroadcasting,
    isEnded,
    isStarted,
    maxJokersCopycat,
    maxJokersNext,
    name,
    nbQuestions,
    prizepool,
    prizeType,
    questions,
    quizmaster,
    resyncDelay,
    startAt,
    subtitle,
    thumbnail,
    programName,
    partnerLogo,
    theme,
    quizType,
    controller,
    goLive,
    loosersText,
    player,
  } = values;
  const permissions = {
    player: player.split(',').map(value => value.trim()),
    controller: controller.split(',').map(value => value.trim()),
  };
  axios({
    method: 'PUT',
    url: `${config.cloudHttps}/httpsQuizzesAdminPut`,
    params: {
      id,
    },
    data: {
      addPlayers,
      delayHideAnswer,
      isBroadcasting,
      isEnded,
      isStarted,
      liveVideoUri: {
        android: config.defaultLiveVideoUri,
        ios: config.defaultLiveVideoUri,
      },
      maxJokersCopycat,
      maxJokersNext,
      name,
      nbQuestions: Number(nbQuestions),
      prizepool,
      prizeType,
      questions,
      quizmaster,
      resyncDelay,
      startAt,
      subtitle,
      thumbnail,
      programName,
      partnerLogo,
      theme,
      type: quizType,
      permissions,
      goLive,
      loosersText,
    },
  })
    .then(res => {
      if (res.status !== 200) {
        alert('Erreur: Impossible de modifier le quiz');
      }
      dispatch(pending(false));
      dispatch(cannotChangeForm(false));
      return;
    })
    .catch(err => {
      // TODO handle error
      dispatch(pending(false));
      return err;
    });
};

export const guessAnswer = (question, answers, currentPage) => dispatch => {
  // axios({
  //   method: 'POST',
  //   url: `${config.cloudHttps}httpsAdminGuessAnswer`,
  //   data: {
  //     question,
  //     answers,
  //   },
  //   headers: {
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //     idToken: localStorage.getItem('firebaseToken'),
  //   },
  // })
  //   .then(res => {
  //     dispatch(quizGuessAnswer({ ...res.data, currentPage }));
  //     return;
  //   })
  //   .catch(err => {
  //     console.log(err);
  //     return;
  //   });
};

export const downloadQuizzes = quizId => dispatch => {
  dispatch(pendingDownload(true));
  axios({
    method: 'GET',
    url: `${config.cloudHttps}httpsAdminQuizzesDownload`,
    params: {
      quizId,
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      idToken: localStorage.getItem('firebaseToken'),
    },
  })
    .then(res => {
      dispatch(quizzesDownloadLink(res.data));
      dispatch(pendingDownload(false));
      return;
    })
    .catch(err => {
      alert(err);
      console.log(err);
      dispatch(pendingDownload(false));
      return;
    });
};
