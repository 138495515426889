const createQuestion = (duration, order, isJokerDisabled) => ({
  answers: [
    { content: { fr: "FR", dz: "DZ" }, isRight: false, id: "a" },
    { content: { fr: "FR", dz: "DZ" }, isRight: false, id: "b" },
    { content: { fr: "FR", dz: "DZ" }, isRight: false, id: "c" },
    { content: { fr: "FR", dz: "DZ" }, isRight: false, id: "d" }
  ],
  duration,
  isJokerDisabled,
  order,
  question: { fr: "FR", dz: "DZ" },
  hint: null,
  reward: null,
  musicUrl: null,
  type: "mcq"
});

const createQuestions = (length) => {
  const duration = 12000;
  const questions = [];
  for (let i = 0; i < length - 2; i++)
    questions.push(createQuestion(duration, i + 1));
  if (length > 1) questions.push(createQuestion(duration, length - 1, true));
  if (length > 0) questions.push(createQuestion(duration, length, true));
  return questions;
};

export default createQuestions;
