import DatePickerDialog from 'material-ui/DatePicker/DatePickerDialog';
import DateTimePicker from 'material-ui-datetimepicker';
import React from 'react';
import styled from 'styled-components';
import TimePickerDialog from 'material-ui/TimePicker/TimePickerDialog';

const InputAndErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledErrorWarning = styled.span`
  color: red;
  padding: 5px 0px 5px 0px;
`;

const renderDateTimePicker = ({ input, label, meta: { touched, error, warning }, ...rest }) => (
  <InputAndErrorContainer>
    <DateTimePicker
      {...input}
      {...rest}
      autoComplete="off"
      DatePicker={DatePickerDialog}
      floatingLabelFixed
      floatingLabelText={label}
      TimePicker={TimePickerDialog}
      timeFormat={'24hr'}
    />
    {touched &&
      ((error && <StyledErrorWarning>{error}</StyledErrorWarning>) ||
        (warning && <StyledErrorWarning>{warning}</StyledErrorWarning>))}
  </InputAndErrorContainer>
);

export default renderDateTimePicker;
