import {
  SET_ACHIEVEMENT_LIST,
  SET_SINGLE_ACHIEVEMENT,
  ERROR_SET_SINGLE_ACHIEVEMENT,
  ERROR_FETCH_ACHIEVEMENTS,
  PENDING,
  SET_REWARD_LIST,
  SET_TRIGGER_LIST,
} from '../actions/achievements';

const initialState = {
  error: '',
  isLoading: false,
  achievementList: [],
  countAchievements: 0,
  achievement: {},
  rewardList: [],
  triggerList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACHIEVEMENT_LIST:
      return {
        ...state,
        achievementList: action.payload.achievements,
        countAchievements: action.payload.nbAchievements,
        error: '',
      };
    case SET_SINGLE_ACHIEVEMENT:
      return {
        ...state,
        achievement: action.payload,
        error: '',
      };
    case ERROR_SET_SINGLE_ACHIEVEMENT:
      return {
        ...state,
        error: action.payload,
      };
    case ERROR_FETCH_ACHIEVEMENTS:
      return {
        ...state,
        error: action.payload.message,
      };
    case PENDING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_REWARD_LIST:
      return {
        ...state,
        rewardList: action.payload,
      };
    case SET_TRIGGER_LIST:
      return {
        ...state,
        triggerList: action.payload,
      };
    default:
      return state;
  }
};
