import axios from 'axios';

import { achievementsServer } from '../config';

export const SET_ACHIEVEMENT_LIST = 'SET_ACHIEVEMENT_LIST';
export const SET_SINGLE_ACHIEVEMENT = 'SET_SINGLE_ACHIEVEMENT';
export const ERROR_SET_SINGLE_ACHIEVEMENT = 'ERROR_SET_SINGLE_ACHIEVEMENT';
export const PENDING = 'PENDING';
export const ERROR_FETCH_ACHIEVEMENTS = 'ERROR_FETCH_ACHIEVEMENTS';
export const SET_REWARD_LIST = 'SET_REWARD_LIST';
export const SET_TRIGGER_LIST = 'SET_TRIGGER_LIST';

export const setAchievementList = achievementList => ({
  type: SET_ACHIEVEMENT_LIST,
  payload: achievementList,
});

export const setSingleAchievement = achievement => ({
  type: SET_SINGLE_ACHIEVEMENT,
  payload: achievement,
});

export const setSingleAchievementError = err => ({
  type: ERROR_SET_SINGLE_ACHIEVEMENT,
  payload: err,
});

export const pending = payload => ({
  type: PENDING,
  payload,
});

export const fetchAchievementError = err => ({
  type: ERROR_FETCH_ACHIEVEMENTS,
  payload: err,
});

export const setRewardList = rewardList => ({
  type: SET_REWARD_LIST,
  payload: rewardList,
});

export const setTriggerList = triggerList => ({
  type: SET_TRIGGER_LIST,
  payload: triggerList,
});

export const getAchievementsList = (searchTerm, limit = 10, page = 0) => async dispatch => {
  dispatch(pending(true));
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${achievementsServer}achievements`,
      params: {
        limit,
        searchTerm,
        skip: page * limit,
      },
    });
    dispatch(setAchievementList(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchAchievementError(e));
  } finally {
    dispatch(pending(false));
  }
};

export const getSingleAchievement = id => async dispatch => {
  dispatch(pending(true));
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${achievementsServer}achievements/${id}`,
    });
    console.log(`Achievement: ${JSON.stringify(data)}`);
    dispatch(setSingleAchievement(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchAchievementError(e));
  } finally {
    dispatch(pending(false));
  }
};

export const getRewardList = () => async dispatch => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${achievementsServer}rewards`,
    });
    dispatch(setRewardList(data));
  } catch (e) {
    console.log(e);
  }
};

export const getTriggerList = () => async dispatch => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${achievementsServer}triggers`,
    });
    dispatch(setTriggerList(data));
  } catch (e) {
    console.log(e);
  }
};

export const updateAchievement = (achievement, id) => async dispatch => {
  dispatch(pending(true));
  try {
    const { data } = await axios({
      method: 'PUT',
      url: `${achievementsServer}achievements/${id}`,
      id,
      data: achievement,
    });
    dispatch(setSingleAchievement(data));
  } catch (e) {
    if (e.response) {
      dispatch(setSingleAchievementError(e.response.data));
    } else {
      dispatch(setSingleAchievementError(e.message));
    }
    console.log(e);
  } finally {
    dispatch(pending(false));
  }
};

export const createAchievement = newAchievement => async dispatch => {
  dispatch(pending(true));
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${achievementsServer}achievements`,
      data: newAchievement,
    });
    dispatch(setSingleAchievement(data));
  } catch (e) {
    if (e.response) {
      dispatch(setSingleAchievementError(e.response.data));
    } else {
      dispatch(setSingleAchievementError(e.message));
    }
    console.log(e);
  } finally {
    dispatch(pending(false));
  }
};
